import Item from 'components/checkout/splitReview/Item';

export const ReviewGroup = props => {
  const { groupDetails, isForEGC, isReviewStep, lineItemIds = [], productsByLineItem, purchaseType, showItemLevelErrors, isLoading = false } = props;

  if (isForEGC) {
    return (
      <Item
        groupDetails={groupDetails}
        lineItem={productsByLineItem[lineItemIds[0]]}
        purchaseType={purchaseType}
        showItemLevelErrors={showItemLevelErrors}
        type="digital"
        isLoading={isLoading}
      />
    );
  }

  return (
    <>
      {lineItemIds.map(lineItemId => (
        <Item
          groupDetails={groupDetails}
          isReviewStep={isReviewStep}
          key={lineItemId}
          lineItem={productsByLineItem[lineItemId]}
          purchaseType={purchaseType}
          showItemLevelErrors={showItemLevelErrors}
          type="retail"
          isLoading={isLoading}
        />
      ))}
    </>
  );
};

export default ReviewGroup;
