// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { MediumIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeMinusMediumIcon = (props: MediumIconProps) => (
  <Icon {...props} viewBox="0 0 32 32">
    <path d="M4.54834 15.6666H28.1186" stroke="currentcolor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);

export default UtilityStrokeMinusMediumIcon;
