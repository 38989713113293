import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { cn } from 'helpers/classnames';
import type { CheckoutPayment } from 'types/checkout';
import { isCreditCardExpired } from 'helpers/MyAccountUtils';
import useMartyContext from 'hooks/useMartyContext';
import { getMelodyPaymentTypeIcon } from 'components/checkout/CheckoutUtils';
import Tag from 'tailwind/components/Tag/Tag';
import UtilityStrokeInfoFilledSmallIcon from 'tailwind/components/Icons/UtilityStrokeInfoFilledSmallIcon';
import { SELECT_BILLING_ADDRESS_STEP } from 'constants/checkoutFlow';
import { selectCheckoutLinks } from 'selectors/checkout';

import css from 'styles/components/checkout/payment/multiLinePayment.scss';

interface MultiLinePaymentProps {
  payment: CheckoutPayment;
  isLoading?: boolean;
  isSelected?: boolean;
  hideDefault?: boolean;
  onEdit?: React.MouseEventHandler<HTMLAnchorElement>;
  onDelete?: React.MouseEventHandler<HTMLButtonElement>;
}

const MultiLinePayment = ({ payment, onEdit, onDelete, hideDefault = false, isLoading = false, isSelected = false }: MultiLinePaymentProps) => {
  const {
    marketplace: { dataMaskPiiElements },
    testId
  } = useMartyContext();
  const {
    ccIssuer,
    paymentType,
    primaryPaymentMethod,
    paymentInstrumentId,
    name,
    fullName,
    expirationMonth,
    expirationYear,
    addCreditCardNumber,
    tail
  } = payment;

  const links = useSelector(selectCheckoutLinks);

  const isExpired = isCreditCardExpired(payment);
  const creditCardName = name || fullName;
  const creditCardending = addCreditCardNumber || tail;
  const creditCardFlag = ccIssuer || paymentType;
  const creditCardFlagNormalized = creditCardFlag?.replace(/([a-z](?=[A-Z]))/g, '$1 ');

  return (
    <div id="payment-method" className={css.wrapper} data-cs-mask={dataMaskPiiElements} data-test-id={testId('multiLinePayment')}>
      <div className={css.paymentBanner}>{getMelodyPaymentTypeIcon(creditCardFlag, true)}</div>
      <div className={css.paymentDetails}>
        <div className={css.paymentHeader}>
          <span className={css.paymentFlag}>{creditCardFlagNormalized}</span>
          <span className={css.paymentTags}>
            {isExpired && (
              <Tag data-test-id={testId('expirationInfo')} variant="alert" size="small">
                <span className="flex items-center gap-x-2 font-normal">
                  <UtilityStrokeInfoFilledSmallIcon size={16} />
                  Expired
                </span>
              </Tag>
            )}
            {!hideDefault && primaryPaymentMethod && (
              <Tag data-test-id={testId('defaultInfo')} size="small" variant="blue 100">
                Default
              </Tag>
            )}
          </span>
        </div>
        <span>{creditCardName}</span>
        <div className={css.paymentEndingIn}>
          <span data-test-id={testId('cardEndingIn')}>ending in {creditCardending}</span>
          <span data-test-id={testId('expirationDate')}>exp {`${expirationMonth}/${expirationYear}`}</span>
        </div>
        <div className={cn(css.paymentActions, { invisible: !isSelected })}>
          {onDelete && (
            <button
              type="button"
              className={css.paymentAction}
              aria-label="Delete Payment"
              aria-disabled={isLoading}
              data-test-id={testId('deletePayment')}
              data-payment-instrument-id={paymentInstrumentId}
              onClick={onDelete}
            >
              Delete
            </button>
          )}
          {onEdit && (
            <Link
              aria-label="Edit Payment"
              aria-disabled={isLoading}
              className={css.paymentAction}
              data-test-id={testId('changeBillingLink')}
              onClick={onEdit}
              to={links[SELECT_BILLING_ADDRESS_STEP]}
            >
              Edit
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiLinePayment;
