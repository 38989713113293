// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { MediumIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokePlusMediumIcon = (props: MediumIconProps) => (
  <Icon {...props} viewBox="0 0 32 32">
    <path d="M16 28V4.42981M27.7851 16.2149H4.21484" stroke="currentcolor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);

export default UtilityStrokePlusMediumIcon;
