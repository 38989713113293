import ab from 'react-redux-hydra';

import { getMafiaAndCredentials } from 'store/ducks/readFromStore';
import { MAFIA_INFERIOR_SUPPRESSION_818260, MAFIA_SPLIT_SHIPMENTS_PLUGIN_377648 } from 'constants/weblabs';
import { HYDRA_MAFIA_INFERIOR_SUPPRESSION, HYDRA_SPLIT_SHIPMENTS, HYDRA_VIP_ENROLLMENT_AT_CHECKOUT } from 'constants/hydraTests';
import { processHeadersMiddleware } from 'middleware/processHeadersMiddlewareFactory';
import { fetchNon200Middleware } from 'middleware/fetchErrorMiddleware';
import { setSessionCookies } from 'actions/session';
import { weblabTrigger } from 'apis/mafia';
import marketplace from 'cfg/marketplace.json';
import { trackError } from 'helpers/ErrorUtils';
const { cookieDomain } = marketplace;

const weblabExperimentToHydraAssignment = assignment => {
  switch (assignment) {
    case 'C':
      return 0;
    case 'T1':
      return 1;
    case 'T2':
      return 2;
    case 'T3':
      return 3;
    default:
      return 0;
  }
};

export const forceAssignSplitShipments = () => dispatch => {
  dispatch(
    workRequestTriggerWeblabAssignment({
      weblab: MAFIA_SPLIT_SHIPMENTS_PLUGIN_377648,
      hydraTest: HYDRA_SPLIT_SHIPMENTS
    })
  );
};

export function workRequestTriggerWeblabAssignment({ hydraTest, weblab }, fetcher = weblabTrigger, forceAssignment = ab.actions.forceAssignment) {
  return (dispatch, getState) => {
    const state = getState();
    const { mafiaConfig, credentials } = getMafiaAndCredentials(state);

    return fetcher(mafiaConfig, weblab, credentials)
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchNon200Middleware)
      .then(resp => {
        if (resp) {
          if (hydraTest) {
            const assignment = weblabExperimentToHydraAssignment(resp);
            dispatch(forceAssignment({ domain: cookieDomain }, hydraTest, assignment));
          }
          return resp;
        }
      })
      .catch(error => {
        trackError('ERROR', 'Failed to fetch weblab data', error);
      });
  };
}

export function forceAssignVipEnrollmentAtCheckout(forceAssignment = ab.actions.forceAssignment) {
  return (dispatch, getState) => {
    const {
      shipOption: { isVipEnrollmentFeatureEnabled }
    } = getState();
    const assignment = isVipEnrollmentFeatureEnabled ? 1 : 0;
    dispatch(forceAssignment({ domain: cookieDomain }, HYDRA_VIP_ENROLLMENT_AT_CHECKOUT, assignment));
  };
}

export const forceAssignMafiaInferiorSuppression = () => dispatch => {
  dispatch(
    workRequestTriggerWeblabAssignment({
      weblab: MAFIA_INFERIOR_SUPPRESSION_818260,
      hydraTest: HYDRA_MAFIA_INFERIOR_SUPPRESSION
    })
  );
};
