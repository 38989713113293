import Payment from 'components/checkout/payment/Payment';

import css from 'styles/components/checkout/payment/paymentAsList/creditCardReview.scss';

export const CreditCardReview = params => {
  const { hasPurchaseGiftCard, hasPurchasePromoCode, isDiscountFullBalance, purchaseCreditCard, chargeSummary } = params;

  return (
    <div className={css.paymentSection} id="credit-card-content" role="tabpanel" aria-labelledby="credit-card-tab">
      <Payment
        hasPurchaseGiftCard={hasPurchaseGiftCard}
        hasPurchasePromoCode={hasPurchasePromoCode}
        isDiscountFullBalance={isDiscountFullBalance}
        isPaymentAsListCheckout={true}
        payment={purchaseCreditCard}
        suppressBillingAddress={false}
        chargeSummary={chargeSummary}
      />
    </div>
  );
};

export default CreditCardReview;
