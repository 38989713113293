import type { AppState } from 'types/app';

export const selectCheckoutData = (state: AppState) => state.checkoutData;

export const selectPurchase = (state: AppState) => selectCheckoutData(state).purchase;

export const selectCheckoutLinks = (state: AppState) => selectCheckoutData(state).links;

export const selectChargeSummary = (state: AppState) => selectPurchase(state).chargeSummary;

export const selectAfterTaxDiscounts = (state: AppState) => selectChargeSummary(state)?.afterTaxDiscounts;

export const selectGiftCardBalance = (state: AppState) =>
  selectAfterTaxDiscounts(state)?.find(discount => discount.claimCode === 'Gift Card')?.amountUsed;
