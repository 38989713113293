import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import css from 'styles/components/checkout/sectionDivider.scss';

const SectionDivider = forwardRef((props, ref) => <div className={css.divider} ref={ref} />);

SectionDivider.contextTypes = {
  testId: PropTypes.func
};

export default SectionDivider;
