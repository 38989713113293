import MultiLineAddress from 'components/checkout/address/MultiLineAddress';
import { DIGITAL_DELIVERY_ONLY_MESSAGE } from 'constants/siteMessages';
import useMartyContext from 'hooks/useMartyContext';
import PaypalLogo from 'images/paypal-badge.svg';

// eslint-disable-next-line css-modules/no-unused-class
import css from 'styles/components/checkout/address/amazonPayAddressReview.scss';

export const PayPalAddressReview = params => {
  const { testId } = useMartyContext();

  const { formattedPurchaseAddress, hideCountry, isDigitalDeliveryOnly } = params;

  if (isDigitalDeliveryOnly) {
    return <p className={css.addressSection}>{DIGITAL_DELIVERY_ONLY_MESSAGE}</p>;
  }

  return (
    <div className={css.addressSection} data-test-id={testId('payPalAddressReview')}>
      <img className={css.logo} src={PaypalLogo} role="presentation" alt="This is a PayPal order" aria-label="PayPal Credit" />
      <MultiLineAddress address={formattedPurchaseAddress} hideCountry={hideCountry} hidePhone={true} />
    </div>
  );
};

export default PayPalAddressReview;
