import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { cn } from 'helpers/classnames';
import usePrevious from 'hooks/usePrevious';
import useMartyContext from 'hooks/useMartyContext';
import { fetchGiftOptions, onGiftOptionsImpression, onGiftOptionsNotEligible } from 'store/ducks/giftoptions/actions';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import UtilityStrokePlusSmallIcon from 'tailwind/components/Icons/UtilityStrokePlusSmallIcon';
import UtilityStrokeMinusSmallIcon from 'tailwind/components/Icons/UtilityStrokeMinusSmallIcon';

import css from 'styles/components/checkout/giftOptions.scss';

export const GiftOptions = props => {
  const {
    testId,
    marketplace: { dataMaskPiiElements }
  } = useMartyContext();

  const {
    fetchGiftOptions,
    giftOptions: {
      hasInvalidGiftOptions,
      hasOnlyGiftMessagableItems,
      isLoaded,
      isLoading,
      giftOptions,
      giftOptionSuccessMessage,
      giftMessage: purchaseGiftMessage = ''
    },
    openByDefault,
    onGiftOptionsImpression,
    onGiftOptionsNotEligible,
    onToggleBox,
    onRemovingGiftOptions,
    onSavingGiftOptions,
    purchaseDataIsLoading
  } = props;

  const [isOpen, setOpen] = useState(openByDefault || giftOptionSuccessMessage);
  const [giftMessage, setGiftMessage] = useState(purchaseGiftMessage);
  const [lineMessage, setLineMessage] = useState('10 lines');
  const [charMessage, setCharMessage] = useState('240 characters');
  const [charMessageWarning, setCharMessageWarning] = useState(false);
  const [lineMessageWarning, setLineMessageWarning] = useState(false);
  const previous = usePrevious({ purchaseGiftMessage, isLoaded }) || {};

  let maxLength = 0;
  Object.values(giftOptions).forEach(({ giftMessage: { maxLines, maxTotalLength } }) => {
    if (maxLines > 0 && (maxTotalLength <= maxLength || maxLength === 0)) {
      maxLength = maxTotalLength;
    }
  });

  const validateMessage = useCallback(
    enteredGiftMessage => {
      let maxLines = 0,
        maxTotalLength = 0;
      Object.values(giftOptions).forEach(({ giftMessage: { maxLines: itemMaxLines, maxTotalLength: itemMaxTotalLength } }) => {
        if (itemMaxLines > 0) {
          maxLines = itemMaxLines;
          maxTotalLength = itemMaxTotalLength;
        }
      });

      // Count the number of lines
      const numLines = enteredGiftMessage.split('\n').length;
      const size = enteredGiftMessage.length;

      // Determine the differences
      const charDelta = maxTotalLength - size;
      let lineDelta = maxLines - numLines;

      // Adjusting the line count according to entered text (24 characters = 1 line)
      const tempMsg = enteredGiftMessage.split('\n');
      tempMsg.forEach(eachLine => {
        if (eachLine.length > 24) {
          if (eachLine.length % 24 === 0) {
            lineDelta -= Math.floor(eachLine.length / 24) - 1;
          } else {
            lineDelta -= Math.floor(eachLine.length / 24);
          }
        }
      });

      if (charDelta < -1) {
        setCharMessage(`${Math.abs(charDelta)} characters over`);
        setCharMessageWarning(true);
      } else if (charDelta < 0) {
        setCharMessage('1 character over');
        setCharMessageWarning(true);
      } else if (charDelta === 1) {
        setCharMessage('1 character');
        setCharMessageWarning(false);
      } else {
        setCharMessage(`${charDelta} characters`);
        setCharMessageWarning(false);
      }

      if (lineDelta < -1) {
        setLineMessage(`${Math.abs(lineDelta)} lines over`);
        setLineMessageWarning(true);
      } else if (lineDelta < 0) {
        setLineMessage('1 line over');
        setLineMessageWarning(true);
      } else if (lineDelta === 1) {
        setLineMessage('1 line');
        setLineMessageWarning(false);
      } else {
        setLineMessage(`${lineDelta} lines`);
        setLineMessageWarning(false);
      }

      setGiftMessage(enteredGiftMessage);
    },
    [giftOptions]
  );

  const onGiftMessageChange = e => {
    if (!isLoaded || isLoading) {
      return;
    }
    const {
      target: { value: enteredGiftMessage }
    } = e;
    validateMessage(enteredGiftMessage);
  };

  const toggleIsOpen = e => {
    e.preventDefault();
    onToggleBox();
    setOpen(isOpen => !isOpen);
  };

  const onSubmitGiftMessage = e => {
    e.preventDefault();
    onSavingGiftOptions(giftMessage);
  };

  const removeGiftOption = e => {
    e.preventDefault();
    validateMessage('');
    onRemovingGiftOptions();
  };

  useEffect(() => {
    fetchGiftOptions();
  }, [fetchGiftOptions]);

  useEffect(() => {
    const { isLoaded: prevIsLoaded } = previous;
    if (isLoaded && !prevIsLoaded) {
      validateMessage(purchaseGiftMessage);
      onGiftOptionsImpression();
      if (!hasOnlyGiftMessagableItems) {
        onGiftOptionsNotEligible();
        setOpen(true);
      }
    }
  }, [hasOnlyGiftMessagableItems, isLoaded, onGiftOptionsImpression, onGiftOptionsNotEligible, previous, purchaseGiftMessage, validateMessage]);

  const charWrapperClass = cn(css.characterCount, { [css.warning]: charMessageWarning });
  const lineWrapperClass = cn(css.characterCount, { [css.warning]: lineMessageWarning });

  const makeForm = () => (
    <>
      {isOpen && (
        <form
          data-test-id={testId('addCouponForm')}
          onSubmit={onSubmitGiftMessage}
          method="POST"
          action="/marty/checkout"
          className={css.giftOptionsForm}
          data-cs-mask={dataMaskPiiElements}
        >
          <textarea
            aria-describedby="characterHint"
            aria-label="gift message"
            data-test-id={testId('giftMessage')}
            disabled={purchaseDataIsLoading}
            id="giftMessage"
            name="giftMessage"
            onChange={onGiftMessageChange}
            maxLength={maxLength}
            value={giftMessage || ''}
            rows="4"
            placeholder="Type your free gift message here..."
          >
            {giftMessage}
          </textarea>

          <label htmlFor="giftMessage" className={css.forGiftMessage}>
            <output aria-live="polite" className={css.messageLineText} htmlFor="giftMessage" id="characterHint">
              <span className={charWrapperClass}>{charMessage}</span> &#8226; <span className={lineWrapperClass}>{lineMessage}</span>
            </output>
          </label>

          {hasInvalidGiftOptions && (
            <div className={css.statusMessage}>
              <i className={css.errorIcon} />
              <span className={css.error}>Gift message is not valid</span>
            </div>
          )}
          <div className={css.ctaButtons}>
            <button
              type="button"
              className={css.removeBtn}
              disabled={purchaseDataIsLoading || !giftMessage.length || charMessageWarning || lineMessageWarning}
              onClick={removeGiftOption}
              data-test-id={testId('giftMessageCancel')}
            >
              {purchaseGiftMessage ? 'Delete' : 'Cancel'}
            </button>
            <button
              type="submit"
              className={css.saveBtn}
              disabled={purchaseDataIsLoading || !giftMessage.length || charMessageWarning || lineMessageWarning}
              data-test-id={testId('giftMessageSave')}
            >
              {purchaseGiftMessage ? 'Update' : 'Save'}
            </button>
          </div>
        </form>
      )}
    </>
  );

  const makeOptionsNotAvailable = () => <>{isOpen && <p className={css.disabled}>Unfortunately this shipment cannot have a gift message.</p>}</>;

  return (
    <>
      {isLoaded && (
        <div className={css.wrapper}>
          <button
            disabled={!hasOnlyGiftMessagableItems ? 'disabled' : null}
            type="button"
            aria-expanded={isOpen}
            className={css.toggleBtn}
            onClick={toggleIsOpen}
            data-test-id={testId('addGiftMessageButton')}
          >
            <div className={cn(css.sectionTitleWrapper, { [css.disabled]: !hasOnlyGiftMessagableItems })}>
              <h3 className={cn(css.sectionTitle, { [css.disabled]: !hasOnlyGiftMessagableItems })}>Add a Free Gift Message</h3>
              {isOpen ? (
                <UtilityStrokeMinusSmallIcon size="16" className={cn({ [css.disabled]: !hasOnlyGiftMessagableItems })} />
              ) : (
                <UtilityStrokePlusSmallIcon size="16" />
              )}
            </div>
          </button>
          {hasOnlyGiftMessagableItems ? makeForm() : makeOptionsNotAvailable()}
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ giftOptions }) => ({ giftOptions });

const GiftOptionsConnected = connect(mapStateToProps, {
  fetchGiftOptions,
  onGiftOptionsImpression,
  onGiftOptionsNotEligible
})(GiftOptions);

const GiftOptionsConnectedWithErrorBoundary = withErrorBoundary('GiftOptions', GiftOptionsConnected);

export default GiftOptionsConnectedWithErrorBoundary;
