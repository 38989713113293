import { useState } from 'react';

import { cn } from 'helpers/classnames';
import { PAYMENT_FIELDS } from 'constants/formFields';
import CCIVRPayment from 'components/checkout/payment/CCIVRPayment';
import useMartyContext from 'hooks/useMartyContext';
import { getMaskedCreditCardNumber } from 'components/checkout/CheckoutUtils';
import { inIframe } from 'helpers/InIframe';

import css from 'styles/components/checkout/payment/reEnterCardForm.scss';
// eslint-disable-next-line css-modules/no-unused-class
import paymentFormCSS from 'styles/components/checkout/payment/paymentForm.scss';

export const ReEnterCardForm = props => {
  const { testId } = useMartyContext();

  const {
    formItem = {},
    hasVerifyCreditCardError,
    isChecked,
    isExpired,
    isLoading = false,
    selectedPaymentNeedsConfirmation,
    onVerifyCardClick,
    paymentInstrumentId
  } = props;

  const showCCIVRPayment = inIframe();

  const [cc, setCc] = useState('');
  const [showCCIVRModal, setShowCCIVRModal] = useState(false);

  const onChangeCreditCard = e => {
    const { value } = e.target;
    const formattedCC = getMaskedCreditCardNumber(value);
    setCc(formattedCC);
  };

  const onConfirmCardClick = () => {
    onVerifyCardClick({ number: cc, paymentInstrumentId });
    return false;
  };

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const onKeyUp = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onConfirmCardClick();
    }
  };

  const onHideCCIVRModal = () => {
    setShowCCIVRModal(false);
  };

  const onShowCCIVRModalClick = () => {
    setShowCCIVRModal(true);
  };

  const { formErrors = {} } = formItem;

  if (selectedPaymentNeedsConfirmation && isChecked && !isExpired && showCCIVRModal) {
    return <CCIVRPayment endpoint="validateCardForm" isModal={true} title="Confirm Card" onCloseModalOverlayClick={onHideCCIVRModal} />;
  }

  if (selectedPaymentNeedsConfirmation && isChecked && !isExpired && showCCIVRPayment) {
    return (
      <button disabled={isLoading} className={css.confirmCardBtn} type="button" onClick={onShowCCIVRModalClick}>
        Confirm Customer Card
      </button>
    );
  }

  if (selectedPaymentNeedsConfirmation && isChecked && !isExpired) {
    return (
      <div>
        <div className={paymentFormCSS.fieldWrapper}>
          <div
            className={cn(paymentFormCSS.formField, {
              [paymentFormCSS.fieldError]: hasVerifyCreditCardError || formErrors.hasOwnProperty(PAYMENT_FIELDS.CC.fieldName)
            })}
          >
            <label className={css.label} htmlFor={PAYMENT_FIELDS.CC.fieldName} data-test-id={testId('reEnterCCNumLabel')}>
              Card Number
            </label>
            <input
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              maxLength={PAYMENT_FIELDS.CC.maxLength}
              onChange={onChangeCreditCard}
              value={cc || ''}
              id={PAYMENT_FIELDS.CC.fieldName}
              name={PAYMENT_FIELDS.CC.fieldName}
              data-test-id={testId('cardNumber')}
              required={true}
              placeholder="Card number"
              spellCheck="false"
            />
            {hasVerifyCreditCardError && <div>Credit card number doesn't match. Please try entering it again.</div>}
            {formErrors.hasOwnProperty(PAYMENT_FIELDS.CC.fieldName) && <div>{formErrors[PAYMENT_FIELDS.CC.fieldName]}</div>}
          </div>
        </div>

        <button
          type="button"
          disabled={isLoading}
          className={css.confirmCardBtn}
          data-test-id={testId('updateExpirationBtn')}
          onClick={onConfirmCardClick}
        >
          {isLoading ? 'Submitting...' : 'Confirm Card'}
        </button>
      </div>
    );
  }

  return null;
};

export default ReEnterCardForm;
