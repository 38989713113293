import ModalAddressList from 'components/checkout/address/ModalAddressList';
import PaymentAsList from 'components/checkout/payment/PaymentAsList';
import { inIframe } from 'helpers/InIframe';

import css from 'styles/components/checkout/payment/paymentAsList/creditCardUpdate.scss';

export const CreditCardUpdate = params => {
  const {
    dataIsLoading,
    doesPurchaseRequireCC,
    errorMessageFromCv,
    hasSavedPayments,
    hasVerifyCreditCardError,
    isBillingStep,
    isDiscountFullBalance,
    onAddNewCardLinkTo,
    onAddNewPaymentClick,
    onAutoUseCard,
    onDeletePaymentInstrumentClick,
    onSubmitPayment,
    onUpdateExpirationSubmit,
    onUseCreditCardClick,
    onVerifyCardClick,
    paymentDataIsLoaded,
    paymentDataIsLoading,
    purchaseCreditCard,
    sharedPayment,
    shippingAddressId,
    showAddCreditCardForm,
    showNeedShipAddressMsg,
    showPaymentModal,
    onChangeBillingAddressClick,
    needsBillingAddress,
    savePaymentRef
  } = params;

  const isLoading = !paymentDataIsLoaded || paymentDataIsLoading;

  return (
    <div id="credit-card-content" role="tabpanel" aria-labelledby="credit-card-tab">
      {!!errorMessageFromCv && <p className={css.cvError}>{errorMessageFromCv}</p>}

      <PaymentAsList
        isLoading={isLoading}
        hasSavedPayments={hasSavedPayments}
        doesPurchaseRequireCC={doesPurchaseRequireCC}
        hasInactiveInstrument={!!errorMessageFromCv}
        hasShippingAddress={!!shippingAddressId}
        hasVerifyCreditCardError={hasVerifyCreditCardError}
        onAddNewCardLinkTo={onAddNewCardLinkTo}
        onAddNewPaymentClick={onAddNewPaymentClick}
        onAutoUseCard={onAutoUseCard}
        onDeletePaymentInstrumentClick={onDeletePaymentInstrumentClick}
        onSubmitPayment={onSubmitPayment}
        onUpdateExpirationSubmit={onUpdateExpirationSubmit}
        onUseCreditCardClick={onUseCreditCardClick}
        onVerifyCardClick={onVerifyCardClick}
        purchaseCreditCard={purchaseCreditCard}
        purchaseDataIsLoading={dataIsLoading}
        sharedPayment={sharedPayment}
        showCCIVRPayment={inIframe()}
        onChangeBillingAddressClick={onChangeBillingAddressClick}
        showNeedShipAddressMsg={showNeedShipAddressMsg}
        showAddCreditCardForm={showAddCreditCardForm || (showPaymentModal && (isDiscountFullBalance || hasSavedPayments))}
        savePaymentRef={savePaymentRef}
      />

      <ModalAddressList
        isBilling
        isOpen={isBillingStep}
        invalidAddressError={needsBillingAddress && 'Please select or add a new billing address.'}
      />
    </div>
  );
};

export default CreditCardUpdate;
