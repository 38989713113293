/* eslint css-modules/no-unused-class: [2, { markAsUsed: ['footerAutoCompleteMiniForm', 'addressToggleBtn', 'modalOverlay', 'modalContent', 'close', 'wrapper', 'fullName'] }] */
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { deepEqual } from 'fast-equals';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

import { cn } from 'helpers/classnames';
import { AriaLiveTee } from 'components/common/AriaLive';
import {
  onHasLoadedAacSuggestions,
  onHasSelectedAacSuggestion,
  onSawAddressFormWithAac,
  onToggleIsAlsoBilling,
  setIsDefaultShippingAddress,
  storeEditOfInactiveAddressError
} from 'store/ducks/address/actions';
import { ADDRESS_FIELDS } from 'constants/formFields';
import { CountryList } from 'components/common/CountryList';
import MelodyModal from 'components/common/MelodyModal';
import MultiLineAddress from 'components/checkout/address/MultiLineAddress';
import { formatPhoneNumber } from 'helpers/CheckoutUtils';
import { validateRequiredAddressFields } from 'helpers/CheckoutAddressUtils';
import { toFormatted } from 'store/ducks/address/utils';
import UtilityStrokeInfoOutlineSmallIcon from 'tailwind/components/Icons/UtilityStrokeInfoOutlineSmallIcon';
import Tag from 'tailwind/components/Tag/Tag';
import { inIframe } from 'helpers/InIframe';

import css from 'styles/containers/address/addressFormWithAutoComplete.scss';
import modalCSS from 'styles/components/common/modal.scss';
// eslint-disable-next-line css-modules/no-unused-class
import theme from 'styles/containers/address/addressFormWithAutoCompleteTheme.scss';

const initialState = {
  addressId: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  countryCode: 'US',
  fullName: '',
  postalCode: '',
  primaryVoiceNumber: '',
  stateOrRegion: '',
  isPrimary: true,
  formErrors: {},
  selectedAddressId: 0,
  hasLoadedSuggestions: false,
  wasAddressLoaded: false
};

function renderSuggestion({ number, street, city, state, countryCode }) {
  let address = '';
  if (number && street) {
    address += `${number} ${street}, `;
  }

  if (city) {
    address += `${city} `;
  }
  address += `${state}, ${countryCode}`;
  return address;
}

export class AddressFormWithAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.firstNameRef = createRef();
  }

  state = initialState;

  componentDidMount() {
    const {
      isInline,
      address: { isAlsoBilling, savedAddresses },
      onSawAddressFormWithAac,
      isOpen,
      onFetchLatLong,
      geoLocation
    } = this.props;
    if (!savedAddresses.length && isInline && !isAlsoBilling) {
      this.toggleIsAlsoBilling();
    }

    onFetchLatLong(geoLocation);

    if (isOpen) {
      onSawAddressFormWithAac();
    }

    // Set focus to name on load
    this.firstNameRef?.current?.focus();
  }

  componentDidUpdate(prevProps) {
    const {
      address: { formItem: prevFormItem },
      isOpen: prevIsOpen
    } = prevProps;
    const {
      address: { formItem: nextFormItem },
      isOpen,
      onSawAddressFormWithAac
    } = this.props;
    const { formErrors: prevFormErrors = {} } = prevFormItem;
    const { formErrors: nextFormErrors = {} } = nextFormItem;
    const { wasAddressLoaded } = this.state;

    if (isOpen && isOpen !== prevIsOpen) {
      onSawAddressFormWithAac();
    }

    if (!deepEqual(prevFormErrors, nextFormErrors)) {
      this.setState({ formErrors: nextFormErrors });
    }

    if (!wasAddressLoaded && nextFormItem.hasOwnProperty('mailingAddress')) {
      const {
        addressId,
        mailingAddress,
        name: { fullName },
        phone: {
          voice: {
            primary: { number }
          }
        }
      } = nextFormItem;

      this.setState({
        wasAddressLoaded: true,
        addressId,
        ...mailingAddress,
        fullName,
        primaryVoiceNumber: number
      });
    }

    if (!deepEqual(prevFormItem, nextFormItem) && deepEqual(nextFormItem, {})) {
      this.setState(initialState);
    }
  }

  toggleIsAlsoBilling = () => {
    const {
      address: { isAlsoBilling },
      onToggleIsAlsoBilling
    } = this.props;
    onToggleIsAlsoBilling(!isAlsoBilling);
  };

  onSetSelected = (selectedAddressId, rowIndex) => {
    const { onSuggestedAddressSelected } = this.props;
    this.setState({ selectedAddressId });
    onSuggestedAddressSelected &&
      (selectedAddressId === 'original'
        ? onSuggestedAddressSelected(selectedAddressId, rowIndex)
        : onSuggestedAddressSelected('suggested', rowIndex));
  };

  onDeleteAddress = () => {
    const { isBilling = false, onDeleteAddressClick, storeEditOfInactiveAddressError } = this.props;
    const { addressId } = this.state;
    storeEditOfInactiveAddressError(false);
    onDeleteAddressClick({ addressId, isBilling });
  };

  // Duplicated in AddressForm.jsx
  onFieldChange = e => {
    const {
      target: { name, value }
    } = e;
    const formErrors = { ...this.state.formErrors };
    delete formErrors[name]; // Error entry must be missing, not set to null or ''

    let fieldValue = value;
    if (name === ADDRESS_FIELDS.PHONE_NUMBER.fieldName) {
      fieldValue = formatPhoneNumber(value);
    }

    this.setState({ [name]: fieldValue, formErrors });
  };

  onSavingSuggestedAddress = e => {
    e.preventDefault();
    const { addressId, selectedAddressId } = this.state;
    const {
      isBilling,
      address: {
        formItem: { suggestedAddresses }
      },
      onSaveSuggestedAddress,
      onUseSuggestedAddressClick
    } = this.props;

    let address = {};

    if (selectedAddressId === 'original') {
      address = this.state;
    } else {
      const { mailingAddress } = suggestedAddresses[selectedAddressId];
      const { fullName, isPrimary, primaryVoiceNumber } = this.state;
      address = {
        addressId,
        ...mailingAddress,
        fullName,
        isPrimary,
        primaryVoiceNumber
      };
    }
    onUseSuggestedAddressClick && (isBilling ? onUseSuggestedAddressClick(1, selectedAddressId) : onUseSuggestedAddressClick(2, selectedAddressId)); // event tracking
    onSaveSuggestedAddress(address);
  };

  // Duplicated in AddressForm.jsx
  onSavingAddress = e => {
    const { onScrollToShipment } = this.props;
    const { addressLine1, city, countryCode, formErrors, fullName, postalCode, primaryVoiceNumber, stateOrRegion } = this.state;
    const formErrorsWithMissingErrors = validateRequiredAddressFields(formErrors, {
      addressLine1,
      city,
      countryCode,
      fullName,
      postalCode,
      primaryVoiceNumber,
      stateOrRegion
    });

    e.preventDefault();
    this.setState({ formErrors: formErrorsWithMissingErrors });

    if (Object.keys(formErrorsWithMissingErrors).length === 0) {
      this.props.onSubmitAddress(this.state);
    } else {
      onScrollToShipment(false);
    }
  };

  toggleIsPrimary = () => {
    const { isPrimary } = this.state;
    const { setIsDefaultShippingAddress } = this.props;
    this.setState({ isPrimary: !isPrimary });
    setIsDefaultShippingAddress(!isPrimary);
  };

  onHideModal = () => {
    const { onCancelAddressForm, storeEditOfInactiveAddressError } = this.props;
    this.setState(initialState);
    onCancelAddressForm();
    storeEditOfInactiveAddressError(false);
  };

  isInvalidField = field => {
    const {
      address: {
        formItem: { invalidFields }
      }
    } = this.props;
    return !!(invalidFields || []).find(row => row.fieldName?.search(field) > 0);
  };

  isErrorField = field => {
    const { formErrors } = this.state;
    return formErrors.hasOwnProperty(field);
  };

  hasError(field) {
    return this.isErrorField(field) || this.isInvalidField(field);
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.loadSuggestions(value);
  };

  loadSuggestions(query) {
    const { hasLoadedSuggestions } = this.state;
    const {
      address: { latLong },
      onHasLoadedAacSuggestions,
      onLoadAddressAutocompleteSuggestions
    } = this.props;
    const countryCode = 'US';

    if (!hasLoadedSuggestions) {
      onHasLoadedAacSuggestions();
      this.setState({ hasLoadedSuggestions: true });
    }

    if (query.length > 2) {
      onLoadAddressAutocompleteSuggestions({
        query,
        near: latLong,
        countryCode
      });
    }
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (_event, { suggestion }) => {
    const { onHasSelectedAacSuggestion } = this.props;

    // Parses the suggested address
    const { number, street, stateCode } = suggestion;
    const suggestedAddress = { ...suggestion, addressLine1: `${number} ${street}`, stateOrRegion: stateCode };

    // Sets the suggested address as the form fields
    const formErrors = { ...this.state.formErrors };
    for (const fieldName in suggestedAddress) {
      delete formErrors[fieldName];
      this.setState({ [fieldName]: suggestedAddress[fieldName] });
    }

    // Reset the form errors
    this.setState({ formErrors });

    // Select suggested event
    onHasSelectedAacSuggestion();
  };

  getSuggestionValue = ({ number, street }) => `${number} ${street}`;

  makeModalSuggested() {
    const { isLoading, isOpen, onCancelSelectSuggested } = this.props;
    const { testId } = this.context;

    return (
      <MelodyModal
        buttonTestId="closeModal"
        className={cn(css.modalContent, { [css.fade]: true })}
        isOpen={isOpen}
        onRequestClose={this.onHideModal}
        contentLabel="Verify Your Address"
      >
        <div data-test-id={testId('addressModal')}>
          <div className={css.formWrapperSuggested}>{this.makeSuggestedForm()}</div>

          <form className={css.suggestedFooter} onSubmit={onCancelSelectSuggested} action="/address" method="post">
            <button
              className={css.useAddressBtn}
              disabled={isLoading}
              onClick={this.onSavingSuggestedAddress}
              type="submit"
              data-test-id={testId('useAddressBtn')}
            >
              {isLoading ? 'Submitting…' : 'Use Selected Address'}
            </button>
            <button type="button" onClick={onCancelSelectSuggested} className={css.cancelBtn} data-test-id={testId('cancelBtn')}>
              Cancel
            </button>
          </form>
        </div>
      </MelodyModal>
    );
  }

  makeSuggestedForm = () => {
    const {
      address: {
        formItem: { suggestedAddresses }
      },
      isBilling,
      isLoading,
      onVerifyAddressPageView
    } = this.props;
    const { countryCode, fullName, primaryVoiceNumber, selectedAddressId } = this.state;
    const originalAddress = {
      ...toFormatted(this.state),
      selectedAddressId: 'original'
    };
    const addressType = isBilling ? 'Billing' : 'Shipping';

    onVerifyAddressPageView();

    return (
      <form action="/address" method="POST" onSubmit={this.onSavingAddress}>
        <p className={css.headerSuggested}>Verify Your {addressType} Address</p>
        <p className={css.subHeaderSuggested}>To ensure accurate delivery, consider the changes suggested below.</p>
        <div>
          {
            <div
              className={cn(css.item, {
                [css.selected]: selectedAddressId === 'original'
              })}
            >
              <div className={css.row}>
                <div className={css.selectionBlock}>
                  <input
                    checked={selectedAddressId === 'original'}
                    disabled={isLoading}
                    onChange={() => this.onSetSelected('original', 0)}
                    type="radio"
                    id="original"
                    name="verifyAddress"
                  />
                  <label htmlFor="original" className="flex">
                    <div className={css.addressOptionsWrapper}>
                      <Tag size="small" variant="charcoal">
                        Original
                      </Tag>
                      <MultiLineAddress hidePhone address={originalAddress} hideCountry={countryCode === 'US'} />
                    </div>
                  </label>
                </div>
              </div>
            </div>
          }
        </div>
        <div>
          {suggestedAddresses.map((address, rowIndex) => {
            const {
              mailingAddress: { countryCode }
            } = address;
            const addressId = rowIndex;
            const decoratedAddress = {
              ...address,
              selectedAddressId: addressId,
              name: { fullName },
              phone: { voice: { primary: { number: primaryVoiceNumber } } }
            };
            const itemStyles = cn(css.item, {
              [css.selected]: selectedAddressId === addressId
            });
            return (
              <div key={addressId} className={itemStyles}>
                <div className={css.row}>
                  <div className={css.selectionBlock}>
                    <input
                      checked={selectedAddressId === addressId}
                      disabled={isLoading}
                      onChange={() => this.onSetSelected(addressId, rowIndex + 1)}
                      type="radio"
                      id={`address-${addressId}`}
                      name="verifyAddress"
                    />
                    <label htmlFor={`address-${addressId}`} className="flex">
                      <div className={css.addressOptionsWrapper}>
                        <Tag size="small" variant="info">
                          Suggested
                        </Tag>
                        <MultiLineAddress hidePhone address={decoratedAddress} baseAddress={originalAddress} hideCountry={countryCode === 'US'} />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </form>
    );
  };

  makeForm = () => {
    const {
      address: {
        isAlsoBilling,
        isEditOfInactiveAddressError,
        formItem: { invalidFields },
        savedAddresses,
        autoCompleteSuggestions = []
      },
      isBilling = false,
      isLoading
    } = this.props;
    const {
      marketplace: { dataMaskPiiElements },
      testId
    } = this.context;
    const { addressLine1, addressLine2, city, countryCode, fullName, postalCode, primaryVoiceNumber, stateOrRegion, isPrimary, formErrors } =
      this.state;
    const disableAutoComplete = inIframe();

    const inputProps = {
      'disabled': isLoading,
      'value': addressLine1 || '',
      'maxLength': ADDRESS_FIELDS.ADDRESS_LINE_1.maxLength,
      'onChange': this.onFieldChange,
      'id': ADDRESS_FIELDS.ADDRESS_LINE_1.fieldName,
      'name': ADDRESS_FIELDS.ADDRESS_LINE_1.fieldName,
      'placeholder': 'Enter Address',
      'data-test-id': testId('addressLine1'),
      'required': true
    };

    return (
      <form action="/address" method="POST" onSubmit={this.onSavingAddress}>
        <AriaLiveTee role="alert">
          {!!invalidFields?.length && (
            <p className={css.cautionBox} data-test-id={testId('addressInvalidError')}>
              It seems we are unable to determine if the address is valid or not. If you are sure of your entry please try submitting again.
            </p>
          )}
          {isEditOfInactiveAddressError && <p className={css.cautionBox}>This address is not active and cannot be edited.</p>}
        </AriaLiveTee>

        <div className={css.fieldWrapper}>
          <div
            className={cn(css.formField, {
              [css.fieldError]: this.hasError(ADDRESS_FIELDS.FULL_NAME.fieldName)
            })}
          >
            <label htmlFor={ADDRESS_FIELDS.FULL_NAME.fieldName}>Full Name</label>
            <input
              autoComplete={disableAutoComplete ? 'new-password' : ADDRESS_FIELDS.FULL_NAME.autoComplete}
              autoCorrect="off"
              maxLength={ADDRESS_FIELDS.FULL_NAME.maxLength}
              onChange={this.onFieldChange}
              value={fullName || ''}
              disabled={isLoading}
              id={ADDRESS_FIELDS.FULL_NAME.fieldName}
              name={ADDRESS_FIELDS.FULL_NAME.fieldName}
              data-test-id={testId('addressName')}
              required={true}
              placeholder="Enter Full Name"
              ref={this.firstNameRef}
            />
            {this.hasError(ADDRESS_FIELDS.FULL_NAME.fieldName) && (
              <div className={css.fieldErrorMessage}>{formErrors[ADDRESS_FIELDS.FULL_NAME.fieldName]}</div>
            )}
          </div>
        </div>

        <div className={css.fieldWrapper}>
          <div
            className={cn(css.formField, {
              [css.fieldError]: this.hasError(ADDRESS_FIELDS.ADDRESS_LINE_1.fieldName)
            })}
          >
            <label htmlFor={ADDRESS_FIELDS.ADDRESS_LINE_1.fieldName}>Address</label>
            <div data-cs-mask={dataMaskPiiElements}>
              <Autosuggest
                theme={theme}
                suggestions={autoCompleteSuggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                onSuggestionSelected={this.onSuggestionSelected}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
              />
            </div>
            {this.hasError(ADDRESS_FIELDS.ADDRESS_LINE_1.fieldName) && (
              <div className={css.fieldErrorMessage}>{formErrors[ADDRESS_FIELDS.ADDRESS_LINE_1.fieldName]}</div>
            )}
          </div>
        </div>

        <div className={css.fieldWrapper}>
          <div
            className={cn(css.formField, {
              [css.fieldError]: this.hasError(ADDRESS_FIELDS.ADDRESS_LINE_2.fieldName)
            })}
          >
            <label htmlFor={ADDRESS_FIELDS.ADDRESS_LINE_2.fieldName}>
              Apt, Suite, or Other{` `}
              <small className={css.checkoutInfoLabel}>(optional)</small>
            </label>
            <input
              autoComplete={disableAutoComplete ? 'new-password' : ADDRESS_FIELDS.ADDRESS_LINE_2.autoComplete}
              autoCorrect="off"
              disabled={isLoading}
              maxLength={ADDRESS_FIELDS.ADDRESS_LINE_2.maxLength}
              onChange={this.onFieldChange}
              value={addressLine2 || ''}
              id={ADDRESS_FIELDS.ADDRESS_LINE_2.fieldName}
              name={ADDRESS_FIELDS.ADDRESS_LINE_2.fieldName}
              data-test-id={testId('addressLine2')}
              required={false}
              placeholder="Apt, Suite, or Other"
            />
            {this.hasError(ADDRESS_FIELDS.ADDRESS_LINE_2.fieldName) && (
              <div className={css.fieldErrorMessage}>{formErrors[ADDRESS_FIELDS.ADDRESS_LINE_2.fieldName]}</div>
            )}
          </div>
        </div>

        <div className={css.fieldWrapper}>
          <div
            className={cn(css.formField, {
              [css.fieldError]: this.hasError(ADDRESS_FIELDS.POSTAL_CODE.fieldName)
            })}
          >
            <label htmlFor={ADDRESS_FIELDS.POSTAL_CODE.fieldName}>ZIP Code</label>
            <input
              autoComplete={disableAutoComplete ? 'new-password' : ADDRESS_FIELDS.POSTAL_CODE.autoComplete}
              autoCorrect="off"
              maxLength={ADDRESS_FIELDS.POSTAL_CODE.maxLength}
              onChange={this.onFieldChange}
              disabled={isLoading}
              value={postalCode || ''}
              id={ADDRESS_FIELDS.POSTAL_CODE.fieldName}
              name={ADDRESS_FIELDS.POSTAL_CODE.fieldName}
              data-test-id={testId('addressPostalCode')}
              required={true}
              placeholder="Enter Zip Code"
            />
            {this.hasError(ADDRESS_FIELDS.POSTAL_CODE.fieldName) && (
              <div data-test-id={testId('postalCodeError')} className={css.fieldErrorMessage}>
                {formErrors[ADDRESS_FIELDS.POSTAL_CODE.fieldName]}
              </div>
            )}
          </div>

          <div
            className={cn(css.formField, {
              [css.fieldError]: this.hasError(ADDRESS_FIELDS.CITY.fieldName)
            })}
          >
            <label htmlFor={ADDRESS_FIELDS.CITY.fieldName}>City</label>
            <input
              autoComplete={disableAutoComplete ? 'new-password' : ADDRESS_FIELDS.CITY.autoComplete}
              autoCorrect="off"
              maxLength={ADDRESS_FIELDS.CITY.maxLength}
              onChange={this.onFieldChange}
              disabled={isLoading}
              value={city || ''}
              id={ADDRESS_FIELDS.CITY.fieldName}
              name={ADDRESS_FIELDS.CITY.fieldName}
              data-test-id={testId('addressCity')}
              required={true}
              placeholder="Enter City"
            />
            {this.hasError(ADDRESS_FIELDS.CITY.fieldName) && (
              <div className={css.fieldErrorMessage}>{formErrors[ADDRESS_FIELDS.CITY.fieldName]}</div>
            )}
          </div>
        </div>

        <div className={css.fieldWrapper}>
          <div
            className={cn(css.formField, {
              [css.fieldError]: this.hasError(ADDRESS_FIELDS.STATE_OR_REGION.fieldName)
            })}
          >
            <label htmlFor={ADDRESS_FIELDS.STATE_OR_REGION.fieldName}>State</label>
            <input
              autoComplete={disableAutoComplete ? 'new-password' : ADDRESS_FIELDS.STATE_OR_REGION.autoComplete}
              autoCorrect="off"
              maxLength={ADDRESS_FIELDS.STATE_OR_REGION.maxLength}
              onChange={this.onFieldChange}
              disabled={isLoading}
              value={stateOrRegion || ''}
              id={ADDRESS_FIELDS.STATE_OR_REGION.fieldName}
              name={ADDRESS_FIELDS.STATE_OR_REGION.fieldName}
              data-test-id={testId('addressState')}
              required={true}
              placeholder="Enter State"
            />
            {this.hasError(ADDRESS_FIELDS.STATE_OR_REGION.fieldName) && (
              <div className={css.fieldErrorMessage}>{formErrors[ADDRESS_FIELDS.STATE_OR_REGION.fieldName]}</div>
            )}
          </div>

          <div
            className={cn(css.formField, {
              [css.fieldError]: this.hasError(ADDRESS_FIELDS.COUNTRY_CODE.fieldName)
            })}
          >
            <label htmlFor={ADDRESS_FIELDS.COUNTRY_CODE.fieldName}>Country</label>
            <CountryList
              defaultValue={countryCode || 'US'}
              autoComplete={disableAutoComplete ? 'new-password' : ADDRESS_FIELDS.COUNTRY_CODE.autoComplete}
              isBilling={isBilling}
              disabled={isLoading}
              onChange={this.onFieldChange}
              maxLength={ADDRESS_FIELDS.COUNTRY_CODE.maxLength}
              id={ADDRESS_FIELDS.COUNTRY_CODE.fieldName}
              name={ADDRESS_FIELDS.COUNTRY_CODE.fieldName}
            />
            {this.hasError(ADDRESS_FIELDS.COUNTRY_CODE.fieldName) && (
              <div className={css.fieldErrorMessage}>{formErrors[ADDRESS_FIELDS.COUNTRY_CODE.fieldName]}</div>
            )}
          </div>
        </div>

        <div className={css.fieldWrapper}>
          <div
            className={cn(css.formField, {
              [css.fieldError]: this.hasError(ADDRESS_FIELDS.PHONE_NUMBER.fieldName)
            })}
          >
            <label htmlFor={ADDRESS_FIELDS.PHONE_NUMBER.fieldName} data-test-id={testId('phoneNumberLabel')}>
              Phone Number
            </label>
            <input
              required
              autoComplete={disableAutoComplete ? 'new-password' : ADDRESS_FIELDS.PHONE_NUMBER.autoComplete}
              type="tel"
              inputMode="tel"
              autoCorrect="off"
              disabled={isLoading}
              maxLength={ADDRESS_FIELDS.PHONE_NUMBER.maxLength}
              onChange={this.onFieldChange}
              value={primaryVoiceNumber || ''}
              id={ADDRESS_FIELDS.PHONE_NUMBER.fieldName}
              name={ADDRESS_FIELDS.PHONE_NUMBER.fieldName}
              data-test-id={testId('addressPhoneNumber')}
              placeholder="(___) ___-____"
            />
            <small className={css.checkoutInfoLabel}>
              <UtilityStrokeInfoOutlineSmallIcon className={css.checkoutInfoIcon} size={16} />
              Don’t worry we’ll only call if there is an issue with your order.
            </small>
            {this.hasError(ADDRESS_FIELDS.PHONE_NUMBER.fieldName) && (
              <div data-test-id={testId('phoneNumberError')} className={css.fieldErrorMessage}>
                {formErrors[ADDRESS_FIELDS.PHONE_NUMBER.fieldName]}
              </div>
            )}
          </div>
        </div>

        {!savedAddresses.length && (
          <div className={css.fieldWrapper}>
            <div className={css.formField}>
              <input
                type="checkbox"
                name="isAlsoBilling"
                disabled={isLoading}
                data-test-id={testId('isAlsoBilling')}
                id="isAlsoBilling"
                defaultChecked={typeof isAlsoBilling === 'undefined' ? true : isAlsoBilling}
                onChange={this.toggleIsAlsoBilling}
              />
              <label htmlFor="isAlsoBilling" data-test-id={testId('isAlsoBillingLabel')}>
                Use address for billing
              </label>
            </div>
          </div>
        )}

        {!!savedAddresses.length && !isBilling && (
          <div className={css.fieldWrapper}>
            <div className={css.formField}>
              <input
                type="checkbox"
                name="isPrimary"
                data-test-id={testId('saveAsDefault')}
                id="isPrimary"
                defaultChecked={isPrimary}
                onChange={this.toggleIsPrimary}
              />
              <label htmlFor="isPrimary">Save as default shipping address</label>
            </div>
          </div>
        )}
        <button type="submit" className={css.hiddenBtn}>
          Submit
        </button>
      </form>
    );
  };

  makeInlineForm = () => {
    const { isBilling = false, showHeader = true, isLoading, sectionCancel, saveShippingRef } = this.props;
    const { testId } = this.context;
    const addressType = isBilling ? 'billing' : 'shipping';
    const saveLabel = 'Save & Continue';
    const useAddressMsg = isBilling ? 'Bill to this address' : saveLabel;

    return (
      <div className={css.inline} data-test-id={testId('addressFormInline')}>
        {showHeader && (
          <p className={css.header}>
            <span>Add a </span>new {addressType} address
          </p>
        )}

        <div className={css.formWrapper}>{this.makeForm()}</div>

        <form ref={saveShippingRef} onSubmit={this.onSavingAddress} action="/address" method="post" className={css.formActions}>
          {!isBilling && sectionCancel}
          <button type="submit" className={css.addAddressBtn} disabled={isLoading} data-test-id={testId('shipToNewAddress')}>
            {isLoading ? 'Saving…' : useAddressMsg}
          </button>
        </form>
      </div>
    );
  };

  makeModalForm = () => {
    const { isBilling = false, isEdit, isLoading, showDeleteBtn } = this.props;
    const { testId } = this.context;
    const addressType = isBilling ? 'billing' : 'shipping';
    const useAddressMsg = isBilling ? 'Bill to this address' : 'Ship to this address';

    return (
      <div className={css.modal} data-test-id={testId('addressFormModal')}>
        <div className={modalCSS.header}>
          <p className={css.header}>
            {isEdit ? (
              `Edit ${addressType} address`
            ) : (
              <>
                <span>Add a </span>new {addressType} address
              </>
            )}
          </p>
        </div>

        <div className={css.formWrapper}>{this.makeForm()}</div>

        <div className={css.footer}>
          <form onSubmit={this.onSavingAddress} action="/address" method="post">
            <button type="button" disabled={isLoading} onClick={this.onHideModal} className={css.cancelBtn} data-test-id={testId('cancelBtn')}>
              Cancel
            </button>

            {isEdit && showDeleteBtn && (
              <button
                type="button"
                disabled={isLoading}
                onClick={this.onDeleteAddress}
                className={css.deleteBtn}
                data-test-id={testId('deleteAddressBtn')}
              >
                Delete This Address
              </button>
            )}

            <button type="submit" className={css.addAddressBtn} disabled={isLoading} data-test-id={testId('shipToNewAddress')}>
              {isLoading ? 'Saving…' : useAddressMsg}
            </button>
          </form>
        </div>
      </div>
    );
  };

  render() {
    const {
      address: {
        formItem: { suggestedAddresses }
      },
      isOpen,
      isInline
    } = this.props;

    if (isInline) {
      // Inline form has the suggested options as modal
      return (
        <>
          {this.makeInlineForm()}
          {suggestedAddresses?.length ? this.makeModalSuggested() : null}
        </>
      );
    }

    return (
      <MelodyModal
        buttonTestId="closeModal"
        className={cn(css.modalContent, { [css.fade]: true })}
        isOpen={isOpen}
        onRequestClose={this.onHideModal}
        contentLabel="Enter Your Address"
      >
        {suggestedAddresses?.length ? this.makeSuggestedForm() : this.makeModalForm()}
      </MelodyModal>
    );
  }
}

const getGeoLocation = (geoCookie = '') => {
  const geoParts = geoCookie.split('/');

  if (!geoParts.length || geoParts.length === 1) {
    return 'Lebanon, KS'; // geographic center of the US
  }

  if (geoParts.length === 4) {
    return `${geoParts[3]}, ${geoParts[1]}`;
  }

  return geoParts[1];
};

AddressFormWithAutoComplete.contextTypes = {
  testId: PropTypes.func,
  marketplace: PropTypes.object
};

const mapStateToProps = state => {
  const { address, cookies: { geo } = {} } = state;
  const geoLocation = getGeoLocation(geo);

  return { address, geoLocation };
};

export default connect(mapStateToProps, {
  onHasLoadedAacSuggestions,
  onHasSelectedAacSuggestion,
  onSawAddressFormWithAac,
  onToggleIsAlsoBilling,
  setIsDefaultShippingAddress,
  storeEditOfInactiveAddressError
})(AddressFormWithAutoComplete);
