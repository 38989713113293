import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';

import { CHECKOUT_STEP_MAP, EDIT_ADDRESS_STEP, LIST_ADDRESS_STEP, NEW_ADDRESS_STEP, REVIEW_STEP } from 'constants/checkoutFlow';
import { DIGITAL_GC_ONLY_CART } from 'constants/cartTypes';
import { DIGITAL_DELIVERY_ONLY_MESSAGE } from 'constants/siteMessages';
import {
  configurePurchase,
  onChangeAmazonPayAddressClick,
  onChangeShippingAddressClick,
  onCloseSelectShippingAddressListClick,
  onCloseSuggestedAddressModal,
  onDeleteShipAddressClick,
  onEditAddressClick,
  onFetchLatLong,
  onHideNewShippingAddressModalClick,
  onLoadAddressAutocompleteSuggestions,
  onSelectedShippingAddress,
  onSelectedSuggestedShippingAddress,
  onSendToNewAddressWhenNoSavedAddresses,
  onShowAddNewShippingAddressModalClick,
  onUseShippingAddressClick,
  onUseSuggestedAddressClick,
  onVerifyAddressPageView,
  requestAddresses,
  saveShippingAddress
} from 'store/ducks/checkout/actions';
import { clearAddressErrors, clearAddressFormItem, setAddressFormItem, storeTempFailureMsg, storeTempSuccessMsg } from 'store/ducks/address/actions';
import { toFormatted } from 'store/ducks/address/utils';
import { SmallLoader } from 'components/Loader';
import AmazonPayAddressReview from 'components/checkout/address/AmazonPayAddressReview';
import PayPalAddressReview from 'components/checkout/address/PayPalAddressReview';
import MultiLineAddress from 'components/checkout/address/MultiLineAddress';
import AddressList from 'components/checkout/address/AddressList';
import SectionChangeInline from 'components/checkout/SectionChangeInline';
import SectionCancelInline from 'components/checkout/SectionCancelInline';
import SectionTitle from 'components/checkout/SectionTitle';
import AddressForm from 'containers/address/AddressForm';
import AddressFormWithAutoComplete from 'containers/address/AddressFormWithAutoComplete';
import {
  isGeneralAddressContstraintPresent,
  isMissingShippingDestination,
  isShippableAddress,
  needsValidShippingAddress
} from 'helpers/CheckoutUtils';
import { getCleanPath } from 'helpers/CheckoutFlowControl';
import { isDesktop } from 'helpers/ClientUtils';
import { AFTER_PAY, AMAZON_PAY, PAYPAL } from 'constants/paymentMethodTypes';
import { triggerAssignment } from 'actions/ab';
import { inIframe } from 'helpers/InIframe';

import css from 'styles/containers/checkout/shippingAddress.scss';

export class ShippingAddress extends Component {
  state = {
    showAddressModal: false,
    isMobileDevice: false
  };

  componentDidMount = () => {
    const {
      requestAddresses,
      setAddressFormItem,
      location: { pathname, search },
      address
    } = this.props;
    const { isLoaded, savedAddresses } = address;
    const isNewAddressStep = this.isNewAddressStep(pathname);
    const isEditAddressStep = this.isEditAddressStep(pathname);
    const isAddressStep = this.isAddressStep(pathname);
    const { addressId } = queryString.parse(search);
    const isDesktopView = isDesktop();

    this.setState({ isMobileDevice: !isDesktopView });

    if (isAddressStep && !isLoaded) {
      requestAddresses();
    }

    if (isNewAddressStep || isEditAddressStep) {
      this.setState({ showAddressModal: true });
    }

    if (isEditAddressStep) {
      const address = (savedAddresses || []).find(item => item.addressId === addressId);

      if (address) {
        setAddressFormItem(address);
      }
    }
  };

  componentDidUpdate(prevProps) {
    const {
      requestAddresses,
      location: { pathname: prevPathName },
      address: { isLoaded: prevIsLoaded, tmpFailureMsg: prevTmpFailureMsg, tmpSuccessMsg: prevTmpSuccessMsg }
    } = prevProps;
    const {
      onSendToNewAddressWhenNoSavedAddresses,
      clearAddressErrors,
      clearAddressFormItem,
      setAddressFormItem,
      location: { pathname: nextPathName, search: nextSearch },
      address: { isLoaded, savedAddresses, tmpFailureMsg, tmpSuccessMsg },
      storeTempSuccessMsg,
      storeTempFailureMsg
    } = this.props;
    const { addressId: nextAddressId } = queryString.parse(nextSearch);
    const isNewPath = prevPathName !== nextPathName;
    const isJustLoaded = isLoaded !== prevIsLoaded && isLoaded;
    const isListAddressStep = this.isListAddressStep(nextPathName);
    const isEditAddressStep = this.isEditAddressStep(nextPathName);
    const isNewAddressStep = this.isNewAddressStep(nextPathName);
    const { showAddressModal } = this.state;

    if (tmpSuccessMsg && tmpSuccessMsg !== prevTmpSuccessMsg) {
      setTimeout(storeTempSuccessMsg, 5000);
    }

    if (tmpFailureMsg && tmpFailureMsg !== prevTmpFailureMsg) {
      setTimeout(storeTempFailureMsg, 5000);
    }

    if (isListAddressStep && showAddressModal) {
      this.setState({ showAddressModal: false });
      clearAddressErrors();
      clearAddressFormItem();
    }

    if (isListAddressStep && isJustLoaded && !savedAddresses?.length) {
      onSendToNewAddressWhenNoSavedAddresses();
    }

    if (isEditAddressStep && (isJustLoaded || isNewPath)) {
      const address = (savedAddresses || []).find(item => item.addressId === nextAddressId);

      // issue right now as we do not redirect to edit form on invalid address,
      // will tweak once related mafia ticket is live/I get around to adding suggested address support
      if (address) {
        setAddressFormItem(address);
      }

      this.setState({ showAddressModal: true });
    }

    if (isNewAddressStep && (isJustLoaded || isNewPath)) {
      this.setState({ showAddressModal: true });
    }

    if (isNewPath && (isListAddressStep || isNewAddressStep || isEditAddressStep) && !isLoaded) {
      requestAddresses();
    }
  }

  showAddNewAddressModal = () => {
    this.props.onShowAddNewShippingAddressModalClick();
  };

  hideAddressModal = () => {
    this.props.onHideNewShippingAddressModalClick();
  };

  onUseAddressClick = e => {
    e.preventDefault();
    const { addressId } = e.currentTarget?.dataset;
    const {
      address: { savedAddresses },
      configurePurchase,
      onUseShippingAddressClick,
      checkoutData: { selectedAddressId }
    } = this.props;
    const addressIdToUse = addressId || selectedAddressId;
    const rowIndex = Array.from(savedAddresses).findIndex(listItem => listItem.addressId === addressIdToUse);
    onUseShippingAddressClick(addressIdToUse, rowIndex);
    configurePurchase({ addressId: addressIdToUse, advanceOnSuccess: true });
  };

  onEditAddressClick = ({
    currentTarget: {
      dataset: { editAddressId, editAddressIndex }
    }
  }) => {
    this.props.onEditAddressClick(editAddressId, editAddressIndex);
  };

  onAddressSelected = ({
    currentTarget: {
      dataset: { addressId }
    }
  }) => {
    this.props.onSelectedShippingAddress(addressId);
  };

  onCancelSelectSuggested = e => {
    e.preventDefault();
    this.props.clearAddressErrors();
  };

  onSubmitAddress = formAddress => {
    const {
      address: { formItem },
      saveShippingAddress,
      setAddressFormItem
    } = this.props;
    const forceOriginal = !!formItem.invalidFields?.length;
    const item = toFormatted(formAddress);
    setAddressFormItem({ ...item, isBilling: false, forceOriginal });
    saveShippingAddress();
  };

  onSaveSuggestedAddress = formAddress => {
    const { saveShippingAddress, setAddressFormItem } = this.props;
    const item = toFormatted(formAddress);
    setAddressFormItem({ ...item, isBilling: false, forceOriginal: true });
    saveShippingAddress();
  };

  onUseSuggestedAddress = (addressType, addressId) => {
    this.props.onUseSuggestedAddressClick(addressType, addressId);
  };

  onSuggestedAddressSelected = (addressId, addressIndex) => {
    this.props.onSelectedSuggestedShippingAddress(addressId, addressIndex);
  };

  onVerifyAddressPageViewEvent = () => {
    this.props.onVerifyAddressPageView();
  };

  onDeleteAddressClick = e => {
    e.preventDefault();
    const { deleteAddressId } = e.currentTarget.dataset;
    const doDelete = confirm('Are you sure you wish to delete this address?');
    if (doDelete && deleteAddressId) {
      this.props.onDeleteShipAddressClick(deleteAddressId);
    }
  };

  onLoadAddressAutocompleteSuggestions = ({ near, query, countryCode }) => {
    this.props.onLoadAddressAutocompleteSuggestions({
      near,
      query,
      countryCode
    });
  };

  onChangeAmazonAddressClick = e => {
    e.preventDefault();
    this.props.onChangeAmazonPayAddressClick();
  };

  onScrollCtaIntoView = e => {
    e.currentTarget?.scrollIntoView(true);
  };

  // Address workflow step helpers
  isListAddressStep = pathname => getCleanPath(pathname) === CHECKOUT_STEP_MAP[LIST_ADDRESS_STEP];
  isEditAddressStep = pathname => getCleanPath(pathname) === CHECKOUT_STEP_MAP[EDIT_ADDRESS_STEP];
  isNewAddressStep = pathname => getCleanPath(pathname) === CHECKOUT_STEP_MAP[NEW_ADDRESS_STEP];
  isAddressStep = pathname => this.isListAddressStep(pathname) || this.isEditAddressStep(pathname) || this.isNewAddressStep(pathname);
  hasSavedAddresses = () => {
    const {
      address: { savedAddresses = [] }
    } = this.props;
    return !!savedAddresses.length;
  };

  // Renders the address head based on the current step
  makeAddressSectionHeader = () => {
    const {
      location: { pathname }
    } = this.props;

    const titlePrepend = this.isEditAddressStep(pathname)
      ? `Edit `
      : this.isNewAddressStep(pathname) && this.hasSavedAddresses()
      ? `Add New `
      : this.isListAddressStep(pathname) && this.hasSavedAddresses()
      ? `Change `
      : '';

    return (
      <div className="flex flex-col gap-y-4">
        <SectionTitle id="shipping-address-section" title={`${titlePrepend}Shipping Address`} />
      </div>
    );
  };

  render() {
    const {
      allowAddressAutocomplete,
      address: { isLoaded: addressDataIsLoaded, isLoading: addressDataIsLoading, savedAddresses = [], tmpFailureMsg, tmpSuccessMsg },
      onCloseSelectShippingAddressListClick,
      onChangeShippingAddressClick,
      checkoutData: {
        canCancelAddress,
        canChangeAddress,
        cartType,
        constraintViolations,
        isConfirmingPayPal,
        isLoading: purchaseDataIsLoading,
        formattedPurchaseAddress,
        links,
        purchase: { amazonPay, shippingAddress },
        selectedAddressId,
        purchaseType
      },
      enableDesktopAddressAutoComplete,
      onFetchLatLong,
      onScrollToShipment,
      saveShippingRef
    } = this.props;

    const {
      location: { pathname }
    } = this.props;
    const isAddressStep = this.isAddressStep(pathname);
    const isEditAddressStep = this.isEditAddressStep(pathname);
    const { isMobileDevice, showAddressModal } = this.state;
    const isDigitalDeliveryOnly = DIGITAL_GC_ONLY_CART === cartType;
    const { testId } = this.context;

    const errorMessageText = `Your order cannot be shipped to the current address.  Please ${
      savedAddresses?.length ? 'select or add' : 'add'
    } a new one.`;
    const isMissingDestination = isMissingShippingDestination(constraintViolations);
    const hasGeneralAddressConstraint = isGeneralAddressContstraintPresent(constraintViolations);
    const needsValidShipping = needsValidShippingAddress(constraintViolations);
    const isAddressShippable = shippingAddress && shippingAddress.countryCode && isShippableAddress(shippingAddress.countryCode);
    const hasAddressAndItIsNotShippable = shippingAddress && !isAddressShippable;
    const errorMessage =
      hasAddressAndItIsNotShippable || (savedAddresses?.length && !hasGeneralAddressConstraint && !isMissingDestination && needsValidShipping) ? (
        <p className={css.cautionBox} data-test-id={testId('cantUseAddressError')}>
          {errorMessageText}
        </p>
      ) : null;

    const hideCountry = shippingAddress?.countryCode === 'US';
    const editAddressBaseLink = links[EDIT_ADDRESS_STEP];

    const AddressSectionHeader = this.makeAddressSectionHeader();

    const sectionCancel = (
      <SectionCancelInline
        describedby="shipping-address-section"
        to={links[REVIEW_STEP]}
        onClickEvent={onCloseSelectShippingAddressListClick}
        showLink={!purchaseDataIsLoading && canCancelAddress}
      />
    );

    if (isConfirmingPayPal) {
      return (
        <>
          {AddressSectionHeader}
          <SmallLoader />
        </>
      );
    }

    if (purchaseType === PAYPAL) {
      return (
        <>
          {AddressSectionHeader}
          <PayPalAddressReview
            amazonPay={amazonPay}
            formattedPurchaseAddress={formattedPurchaseAddress}
            hideCountry={hideCountry}
            isDigitalDeliveryOnly={isDigitalDeliveryOnly}
            onChangePayPalAddressClick={f => f} // todo: this needs to be sorted out (may not be possible as non paypal button)
          />
        </>
      );
    }

    if (purchaseType === AMAZON_PAY) {
      return (
        <>
          {AddressSectionHeader}
          <AmazonPayAddressReview
            amazonPay={amazonPay}
            onChangeAmazonAddressClick={this.onChangeAmazonAddressClick}
            isDigitalDeliveryOnly={isDigitalDeliveryOnly}
            formattedPurchaseAddress={formattedPurchaseAddress}
            hideCountry={hideCountry}
          />
        </>
      );
    }

    if (isAddressStep) {
      const isClt = inIframe();
      const isAddressAutoComplete = !isClt && allowAddressAutocomplete && (isMobileDevice || enableDesktopAddressAutoComplete);
      let addressForm;

      if (isAddressAutoComplete) {
        addressForm = (
          <AddressFormWithAutoComplete
            isOpen
            isInline
            showHeader={false}
            showDeleteBtn={false}
            sectionCancel={this.hasSavedAddresses() ? sectionCancel : undefined}
            onFetchLatLong={onFetchLatLong}
            isBilling={false}
            isEdit={isEditAddressStep}
            isLoading={purchaseDataIsLoading || addressDataIsLoading}
            onVerifyAddressPageView={this.onVerifyAddressPageViewEvent}
            onCancelAddressForm={this.hideAddressModal}
            onCancelSelectSuggested={this.onCancelSelectSuggested}
            onCloseSuggestedAddressModal={onCloseSuggestedAddressModal}
            onSubmitAddress={this.onSubmitAddress}
            onSaveSuggestedAddress={this.onSaveSuggestedAddress}
            onUseSuggestedAddressClick={this.onUseSuggestedAddress}
            onSuggestedAddressSelected={this.onSuggestedAddressSelected}
            onLoadAddressAutocompleteSuggestions={this.onLoadAddressAutocompleteSuggestions}
            onScrollCtaIntoView={this.onScrollCtaIntoView}
            onScrollToShipment={onScrollToShipment}
            saveShippingRef={saveShippingRef}
          />
        );
      } else {
        addressForm = (
          <AddressForm
            isOpen
            isInline
            showHeader={false}
            showDeleteBtn={false}
            sectionCancel={this.hasSavedAddresses() ? sectionCancel : undefined}
            isBilling={false}
            isEdit={isEditAddressStep}
            isLoading={purchaseDataIsLoading || addressDataIsLoading}
            onVerifyAddressPageView={this.onVerifyAddressPageViewEvent}
            onCancelAddressForm={this.hideAddressModal}
            onCancelSelectSuggested={this.onCancelSelectSuggested}
            onCloseSuggestedAddressModal={onCloseSuggestedAddressModal}
            onSubmitAddress={this.onSubmitAddress}
            onSaveSuggestedAddress={this.onSaveSuggestedAddress}
            onUseSuggestedAddressClick={this.onUseSuggestedAddress}
            onSuggestedAddressSelected={this.onSuggestedAddressSelected}
            onScrollCtaIntoView={this.onScrollCtaIntoView}
            onScrollToShipment={onScrollToShipment}
            saveShippingRef={saveShippingRef}
          />
        );
      }

      return (
        <ul className="flex flex-col gap-y-8" data-test-id={testId('shippingSection')}>
          <li>{AddressSectionHeader}</li>
          <li>
            {!addressDataIsLoaded && this.isListAddressStep(pathname) ? (
              <SmallLoader />
            ) : (
              <div>
                {errorMessage}
                {tmpFailureMsg && <p className={css.failureBox}>{tmpFailureMsg}</p>}
                {tmpSuccessMsg && <p className={css.successBox}>{tmpSuccessMsg}</p>}

                {showAddressModal || !this.hasSavedAddresses() ? (
                  addressForm
                ) : (
                  <AddressList
                    editAddressBaseLink={editAddressBaseLink}
                    isLoading={purchaseDataIsLoading || addressDataIsLoading}
                    showAddressModal={this.showAddNewAddressModal}
                    savedAddresses={savedAddresses}
                    onAddressSelected={this.onAddressSelected}
                    onUseAddressClick={this.onUseAddressClick}
                    onEditAddressClick={this.onEditAddressClick}
                    onDeleteAddressClick={this.onDeleteAddressClick}
                    selectedAddressId={selectedAddressId}
                    sectionCancel={sectionCancel}
                    saveShippingRef={saveShippingRef}
                  />
                )}
              </div>
            )}
          </li>
        </ul>
      );
    }

    const canUserInteractWithAddress = !purchaseDataIsLoading && canChangeAddress && purchaseType !== AFTER_PAY;

    return (
      <ul className={css.section} data-test-id={testId('shippingSection')}>
        <li>
          <div className="flex items-center justify-between">{AddressSectionHeader}</div>
        </li>
        <li></li>
        <li className={css.inSectionButton}>
          <div className={css.addressCard}>
            {isDigitalDeliveryOnly ? (
              DIGITAL_DELIVERY_ONLY_MESSAGE
            ) : (
              <MultiLineAddress address={formattedPurchaseAddress} hideCountry={hideCountry} hidePhone={true} />
            )}
          </div>
        </li>
        {!isDigitalDeliveryOnly && (
          <li>
            <SectionChangeInline
              label="Change Address"
              describedby="shipping-address-section"
              to={links[LIST_ADDRESS_STEP]}
              onClickEvent={onChangeShippingAddressClick}
              showLink={canUserInteractWithAddress}
              isInline={true}
            />
          </li>
        )}
      </ul>
    );
  }
}

function mapStateToProps(state) {
  const {
    address,
    checkoutData,
    killswitch: { enableDesktopAddressAutoComplete },
    router: { location }
  } = state;

  return {
    address,
    checkoutData,
    enableDesktopAddressAutoComplete,
    location
  };
}

ShippingAddress.contextTypes = {
  testId: PropTypes.func
};

export default connect(mapStateToProps, {
  clearAddressErrors,
  clearAddressFormItem,
  configurePurchase,
  onDeleteShipAddressClick,
  onChangeAmazonPayAddressClick,
  onChangeShippingAddressClick,
  onCloseSuggestedAddressModal,
  onFetchLatLong,
  onHideNewShippingAddressModalClick,
  onLoadAddressAutocompleteSuggestions,
  onSendToNewAddressWhenNoSavedAddresses,
  onShowAddNewShippingAddressModalClick,
  requestAddresses,
  saveShippingAddress,
  setAddressFormItem,
  onCloseSelectShippingAddressListClick,
  onSelectedShippingAddress,
  onSelectedSuggestedShippingAddress,
  onUseShippingAddressClick,
  onVerifyAddressPageView,
  onUseSuggestedAddressClick,
  onEditAddressClick,
  storeTempFailureMsg,
  storeTempSuccessMsg,
  triggerAssignment
})(ShippingAddress);
