import HasNoBalance from 'components/checkout/payment/paymentAsList/HasNoBalance';

import css from 'styles/components/checkout/payment/paymentAsList/amazonPayInit.scss';

export const AmazonPayInit = ({ hasNoBalanceFromSavedDiscounts }) => (
  <div className={css.amazonPayModeSection} id="amazon-pay-content" role="tabpanel" aria-labelledby="amazon-pay-tab">
    {hasNoBalanceFromSavedDiscounts ? (
      <HasNoBalance tabInfo />
    ) : (
      `You will be redirected to Amazon Pay to confirm your shipping and payment details.`
    )}
  </div>
);

export default AmazonPayInit;
