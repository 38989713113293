// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { MediumIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityDottedSwapMediumIcon = (props: MediumIconProps) => (
  <Icon {...props} viewBox="0 0 32 32">
    <path
      d="M3.80611 13.8405C3.80611 8.59224 6.05596 4 12.939 4H18.1569C25.0399 4 27.2898 8.59224 27.2898 13.8405M27.2898 13.8405L24.5803 11.2258M27.2898 13.8405L29.9997 11.2258M27.2898 18.4515C27.2898 23.6998 25.1176 28.2921 18.4719 28.2921H13.4339C6.78825 28.2921 4.61597 23.6998 4.61597 18.4515M4.61597 18.4515L7.23199 21.0663M4.61597 18.4515L1.99951 21.0663"
      stroke="currentcolor"
      strokeLinecap="round"
    />
  </Icon>
);

export default UtilityDottedSwapMediumIcon;
