// VIP enrollment at checkout only.
export const VIP_SELECTED_LABEL = "I want to join Zappos VIP (it's FREE)!";
export const VIP_UNSELECTED_LABEL = "Check this box to join Zappos VIP (it's FREE)!";
export const VIP_SELECTED_COPY = 'As a VIP, I get FREE Expedited Shipping on all orders, earn points redeemable for VIP Codes, and so much more.';
export const VIP_UNSELECTED_COPY = "You'll get FREE Expedited Shipping, earn points redeemable for VIP Codes, and so much more.";

// VIP enrollment at checkout with VIP email subscriptions.
export const VIP_WITH_EMAILS_SELECTED_LABEL = 'Join Zappos VIP (+ get our emails, too)!';
export const VIP_WITH_EMAILS_UNSELECTED_LABEL = 'Check this box to join Zappos VIP (+ get our emails, too)!';
export const VIP_WITH_EMAILS_SELECTED_COPY =
  "VIPs get FREE Expedited Shipping, VIP Codes, and other awesome PERKS! Plus, you'll be the first to know about sales, new arrivals, and more through our emails. (Hooray!)";
export const VIP_WITH_EMAILS_UNSELECTED_COPY =
  "VIPs get FREE Expedited Shipping, VIP Codes, and other awesome PERKS! Plus, you'll be the first to know about sales, new arrivals, and more through our emails. (Hooray!)";
