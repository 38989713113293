import { useEffect } from 'react';

import SectionChangeInline from 'components/checkout/SectionChangeInline';
import MultiLineAddress from 'components/checkout/address/MultiLineAddress';
import { DIGITAL_DELIVERY_ONLY_MESSAGE } from 'constants/siteMessages';
import { changeAmazonPayAddress } from 'helpers/AmazonPayUtils';
import useMartyContext from 'hooks/useMartyContext';
import AmazonPayLogo from 'images/amazonpay-badge.svg';

import css from 'styles/components/checkout/address/amazonPayAddressReview.scss';

export const AmazonPayAddressReview = params => {
  const { testId } = useMartyContext();

  const {
    amazonPay: { amazonCheckoutSessionId },
    changePaymentLinkTo,
    formattedPurchaseAddress,
    hideCountry,
    isDigitalDeliveryOnly,
    onChangeAmazonAddressClick,
    purchaseDataIsLoading
  } = params;

  useEffect(() => {
    if (!isDigitalDeliveryOnly) {
      changeAmazonPayAddress('changeAmazonPayAddressSelection', amazonCheckoutSessionId);
    }
  }, [amazonCheckoutSessionId, isDigitalDeliveryOnly]);

  if (isDigitalDeliveryOnly) {
    return <p className={css.addressSection}>{DIGITAL_DELIVERY_ONLY_MESSAGE}</p>;
  }

  return (
    <>
      <div className={css.addressSection} data-test-id={testId('amazonPayAddressReview')}>
        <img className={css.logo} src={AmazonPayLogo} role="presentation" alt="This is an Amazon Pay order" aria-label="Amazon Pay" />
        <MultiLineAddress address={formattedPurchaseAddress} hideCountry={hideCountry} hidePhone={true} />
      </div>
      <div className={css.sectionButtons}>
        <SectionChangeInline
          describedby="payment-method"
          disabled={purchaseDataIsLoading}
          id="changeAmazonPayAddressSelection"
          label="Update"
          onClickEvent={onChangeAmazonAddressClick}
          showLink={true}
          to={changePaymentLinkTo}
        />
      </div>
    </>
  );
};

export default AmazonPayAddressReview;
