import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/checkout/sectionTitle.scss';

const SectionTitle = ({ id, title }) => {
  const { testId } = useMartyContext();

  return (
    <h2 className={cn(css.header, 'text-2xl', 'font-medium')} data-test-id={testId('sectionTitle')} id={id}>
      {title}
    </h2>
  );
};

export default SectionTitle;
