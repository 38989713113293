import { Link } from 'react-router-dom';

import { toUSD } from 'helpers/NumberFormats';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/checkout/splitReview/digitalItemDetails.scss';

export const DigitalItemDetails = props => {
  const {
    lineItem: {
      asin,
      brandName,
      egcDeliveryDate,
      gcCustomization: { message, recipientEmail, senderName },
      price,
      style
    }
  } = props;
  const { testId } = useMartyContext();

  return (
    <div className={css.container}>
      <span className="font-semibold" data-test-id={testId('productBrand')}>
        {brandName}
      </span>
      <Link
        to={`/p/asin/${asin}`}
        data-te="TE_CHECKOUT_REV_GO_TO_PDP"
        data-ted={asin}
        className={css.productName}
        data-test-id={testId('productName')}
      >
        {style}
      </Link>
      <dl className={css.dimensions}>
        <dl>
          {!!message && (
            <>
              <dt className="font-semibold">Message</dt>
              <dd data-test-id={testId('itemMessage')}>{message}</dd>
            </>
          )}
          {!!recipientEmail && (
            <>
              <dt className="font-semibold">Send To</dt>
              <dd data-test-id={testId('itemRecipient')}>{recipientEmail}</dd>
            </>
          )}
          {!!senderName && (
            <>
              <dt className="font-semibold">From</dt>
              <dd data-test-id={testId('itemSenderName')}>{senderName}</dd>
            </>
          )}
          {!!egcDeliveryDate && (
            <>
              <dt className="font-semibold">Deliver On</dt>
              <dd data-test-id={testId('itemEGCDeliveryDate')}>{egcDeliveryDate}</dd>
            </>
          )}
        </dl>
      </dl>

      <span className={css.price} data-test-id={testId('itemPrice')}>
        {toUSD(price)}
      </span>
    </div>
  );
};

export default DigitalItemDetails;
