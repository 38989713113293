import { useDispatch } from 'react-redux';

import useMartyContext from 'hooks/useMartyContext';
import { onViewSalesTaxClick } from 'store/ducks/checkout/actions';
import HtmlToReact from 'components/common/HtmlToReact';
import { htmlEscapedTemplate } from 'helpers/htmlEscapedTemplate';

import css from 'styles/components/checkout/note.scss';

export const Note = () => {
  const {
    testId,
    marketplace: {
      checkout: {
        footerText,
        helpUrl: { salesTax }
      }
    }
  } = useMartyContext();
  const dispatch = useDispatch();

  const onSalesTaxClick = () => {
    dispatch(onViewSalesTaxClick());
  };

  return (
    <HtmlToReact className={css.wrapper} onClick={onSalesTaxClick} data-test-id={testId('notesSection')}>
      {htmlEscapedTemplate(footerText, { salesTax: salesTax })}
    </HtmlToReact>
  );
};

export default Note;
