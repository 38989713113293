interface RenderAfterPayButtonParams {
  btnEl: any;
  isChargeSummary: boolean;
  onAfterpayButtonClick: (params: { isChargeSummary: boolean; isOrderTotal: boolean }) => void;
  onAfterpayError: () => void;
  onAfterpayPopupClosed: () => void;
  onSubmitAfterpayPayment: (params: { cc: any; expirationMonth: any; expirationYear: any; name: string; isPrimary: boolean }) => void;
  onAfterpaySuccess: () => void;
}

export const renderAfterPayButton = ({
  btnEl,
  isChargeSummary,
  onAfterpayButtonClick,
  onAfterpayError,
  onAfterpayPopupClosed,
  onSubmitAfterpayPayment,
  onAfterpaySuccess
}: RenderAfterPayButtonParams) => {
  btnEl.addEventListener(
    'click',
    () => {
      onAfterpayButtonClick({
        isChargeSummary,
        isOrderTotal: !isChargeSummary
      });
    },
    true
  );

  btnEl.onComplete = function (event: any) {
    const { status, virtualCard } = event.data;
    if (status === 'SUCCESS') {
      onAfterpaySuccess();
      const { cardNumber, expiryMonth, expiryYear } = virtualCard;
      onSubmitAfterpayPayment({
        cc: cardNumber,
        expirationMonth: expiryMonth,
        expirationYear: expiryYear,
        name: 'Afterpay',
        isPrimary: false
      });
    } else {
      // The consumer cancelled the payment or closed the popup window.
      onAfterpayPopupClosed();
    }
  };

  btnEl.onError = function () {
    onAfterpayError();
    alert('Something went wrong trying to use Afterpay. Please try again.  If the problem continues, try using a different payment method.');
  };
};
