import { createRef, useEffect, useState } from 'react';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import { renderAfterPayButton } from 'helpers/AfterPayUtils';
import { getGivenNameFromNameOnAccount, getSurnameFromNameOnAccount } from 'helpers/CheckoutUtils';

import css from 'styles/components/checkout/payment/paymentAsList/afterpayAsPlaceOrder.scss';

export const productListToAfterpayList = productList =>
  productList.reduce((allProducts, { productName, quantity, price }) => {
    allProducts.push({
      name: productName,
      quantity,
      price: { amount: price, currency: 'USD' }
    });
    return allProducts;
  }, []);

export const AfterpayAsPlaceOrder = params => {
  const {
    accountIsLoading,
    customerInfo,
    doesCartContainGiftCard,
    grandTotal,
    isAfterpayEligibleByPrice,
    isAfterpayLoading,
    isChargeSummary,
    isCheckoutLoading,
    nameOnAccount,
    onAfterpayButtonClick,
    onAfterpayError,
    onAfterpayPopupClosed,
    onAfterpaySuccess,
    onSubmitAfterpayPayment,
    productList,
    purchaseId,
    shippingAddress = {}
  } = params;

  const {
    testId,
    marketplace: {
      checkout: { afterPayMerchantPublicKey }
    }
  } = useMartyContext();
  const [btnRef] = useState(createRef());
  const [buttonWasRendered, setButtonWasRendered] = useState(false);
  const { email } = customerInfo || {};
  const items = JSON.stringify(productListToAfterpayList(productList));

  useEffect(() => {
    if (
      !doesCartContainGiftCard &&
      !!email &&
      !accountIsLoading &&
      isAfterpayEligibleByPrice &&
      !buttonWasRendered &&
      !isAfterpayLoading &&
      !isCheckoutLoading
    ) {
      setButtonWasRendered(true);
      renderAfterPayButton({
        ...params,
        isChargeSummary,
        btnEl: btnRef.current,
        onAfterpayButtonClick,
        onAfterpayError,
        onAfterpayPopupClosed,
        onSubmitAfterpayPayment,
        onAfterpaySuccess
      });

      // Apply cursor style to the afterpay-button
      const buttonElement = btnRef.current.shadowRoot;
      if (buttonElement) {
        buttonElement.querySelector('button')?.setAttribute('style', 'cursor:pointer');
      }
    }
  }, [
    accountIsLoading,
    btnRef,
    buttonWasRendered,
    doesCartContainGiftCard,
    email,
    isAfterpayEligibleByPrice,
    isAfterpayLoading,
    isChargeSummary,
    isCheckoutLoading,
    onAfterpayButtonClick,
    onAfterpayError,
    onAfterpayPopupClosed,
    onAfterpaySuccess,
    onSubmitAfterpayPayment,
    params
  ]);

  if (doesCartContainGiftCard || !isAfterpayEligibleByPrice || isCheckoutLoading || isAfterpayLoading) {
    return (
      <div className={css.wrapper} data-test-id={testId('placeOrderSection')}>
        <button type="button" disabled={true} className={cn(css.button, css.creditCard)} data-test-id={testId('placeOrderButton')}>
          {!isAfterpayEligibleByPrice || doesCartContainGiftCard ? 'Place Your Order' : 'loading...'}
        </button>
      </div>
    );
  }

  const { addressLine1, city, countryCode, phoneNumber, postalCode, stateOrRegion, fullName } = shippingAddress;

  return (
    <div className={css.afterpayBtnWrapper}>
      <afterpay-button
        amount={`${grandTotal}`}
        buyNow={false}
        consumerEmail={email}
        consumerGivenNames={getGivenNameFromNameOnAccount(nameOnAccount)}
        consumerSurname={getSurnameFromNameOnAccount(nameOnAccount)}
        currency="USD"
        items={items}
        merchantPublicKey={afterPayMerchantPublicKey}
        merchantReference={purchaseId}
        ref={btnRef}
        shippingArea1={city}
        shippingCountryCode={countryCode}
        shippingLine1={addressLine1}
        shippingName={fullName}
        shippingPhoneNumber={phoneNumber}
        shippingPostcode={postalCode}
        shippingRegion={stateOrRegion}
        text="pay-now"
      />
    </div>
  );
};

export default AfterpayAsPlaceOrder;
