import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import { EDIT_ADDRESS_STEP } from 'constants/checkoutFlow';
import type { AppState } from 'types/app';

import css from 'styles/components/checkout/address/addressActionsBar.scss';

interface AddressActionsBarProps {
  addressId: string;
  index?: number;
  isLoading?: boolean;
  isHidden?: boolean;
  onEdit?: React.MouseEventHandler<HTMLAnchorElement>;
  onDelete?: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * Actions bar for addresses
 */
const AddressActionsBar = ({
  addressId,
  onEdit,
  onDelete,
  index = 0,
  isLoading = false,
  isHidden = false
}: AddressActionsBarProps): React.ReactElement => {
  const { testId } = useMartyContext();

  // Builds the edit link
  const links = useSelector((state: AppState) => state.checkoutData?.links);
  const editLink = new URLSearchParams(links?.[EDIT_ADDRESS_STEP]);
  editLink.set('addressId', addressId);

  return (
    <div
      className={cn('flex', 'justify-end', 'space-x-6', 'pt-4', { invisible: isHidden, [css.loading]: isLoading })}
      data-test-id={testId('addressActionBar')}
    >
      {onDelete && (
        <button
          type="button"
          className={css.addressAction}
          aria-label="Delete Address"
          aria-disabled={isLoading}
          data-test-id={testId('deleteAddressLink')}
          data-delete-address-index={index}
          data-delete-address-id={addressId}
          onClick={onDelete}
        >
          Delete
        </button>
      )}
      {onEdit && (
        <Link
          aria-label="Edit Address"
          aria-disabled={isLoading}
          className={css.addressAction}
          data-test-id={testId('editLink')}
          data-edit-address-index={index}
          data-edit-address-id={addressId}
          onClick={onEdit}
          to={decodeURIComponent(editLink.toString())}
        >
          Edit
        </Link>
      )}
    </div>
  );
};

export default AddressActionsBar;
