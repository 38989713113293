import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { cn } from 'helpers/classnames';
import { AriaLiveTee } from 'components/common/AriaLive';
import usePrevious from 'hooks/usePrevious';
import useMartyContext from 'hooks/useMartyContext';
import { GC_AND_PROMO_CONSTRAINTS } from 'constants/constraintViolations';
import RedeemableRewardsRightCol from 'components/checkout/RedeemableRewardsRightCol';
import { onChangeRedemptionAmountEvent, onRedeemRewardsComponentView } from 'actions/account/rewards';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { AMAZON_PAY } from 'constants/paymentMethodTypes';
import UtilityStrokePlusMediumIcon from 'tailwind/components/Icons/UtilityStrokePlusMediumIcon';
import UtilityStrokeMinusMediumIcon from 'tailwind/components/Icons/UtilityStrokeMinusMediumIcon';
import { inIframe } from 'helpers/InIframe';

import css from 'styles/components/checkout/payment/paymentCouponCode.scss';

export const PaymentCouponCode = props => {
  const { testId } = useMartyContext();

  const {
    constraintViolations,
    isGcOnlyCart,
    openByDefault,
    onTogglePromoBox,
    showPromoSuccessMessage,
    couponMessages = [],
    purchaseDataIsLoading,
    purchaseHasShippingAddress,
    purchaseType,
    usePromoBalance
  } = props;

  const isAmazonPayPurchase = purchaseType === AMAZON_PAY;

  let errorMsg = false;
  Object.keys(constraintViolations).some(key => {
    errorMsg = GC_AND_PROMO_CONSTRAINTS.includes(key) && constraintViolations[key].message;
    return !!errorMsg;
  });

  const hasPromotionMessages = !!couponMessages.length;
  const showPromotionMessages = !isGcOnlyCart && usePromoBalance && !inIframe() && hasPromotionMessages;
  const [isOpen, setOpen] = useState(openByDefault);
  useEffect(() => {
    setOpen(openByDefault || showPromotionMessages);
  }, [openByDefault, showPromotionMessages]);

  const [couponCode, setCouponCode] = useState('');
  const { location } = props;
  const previous = usePrevious({ errorMsg, location }) || {};

  const totalClickCount = useRef(0);

  useEffect(() => {
    const { errorMsg: previousErrorMsg = false } = previous;
    if (previousErrorMsg !== errorMsg && !errorMsg) {
      setCouponCode('');
    }
  }, [previous, errorMsg]);

  const onTypeCouponCode = e => {
    const {
      target: { value }
    } = e;
    setCouponCode(value);
  };

  const toggleIsOpen = e => {
    e.preventDefault();
    onTogglePromoBox();
    setOpen(isOpen => !isOpen);
  };

  const onSubmitCoupon = e => {
    e.preventDefault();
    const { onAddCouponClick } = props;
    ++totalClickCount.current;
    onAddCouponClick(couponCode, totalClickCount.current);
  };

  return (
    <>
      <div className={css.rightCol}>
        <button type="button" aria-expanded={isOpen} className={css.toggleBtn} onClick={toggleIsOpen} data-test-id={testId('giftCardPromoArea')}>
          <span className={css.rightColText}>Add a Gift Card or a Promo Code</span>
          <span className={cn([isOpen ? css.arrowUp : css.arrowDown])}>
            {isOpen ? <UtilityStrokeMinusMediumIcon size={24} /> : <UtilityStrokePlusMediumIcon size={24} />}
          </span>
        </button>

        <div className={css.wrapper}>
          <form
            data-test-id={testId('addCouponForm')}
            onSubmit={onSubmitCoupon}
            method="POST"
            action="/marty/checkout/coupon"
            className={cn(css.promoBar, { [css.open]: isOpen })}
            data-search-container
          >
            {!isGcOnlyCart && !purchaseHasShippingAddress ? (
              <p id="addShippingAddressInfo" className={cn(css.addShippingAddressInfoText, css.addShippingAddressInfoTextAbovePromos)}>
                <span className={css.infoIcon}>Info:</span> Add or select a shipping address to apply promotion codes
              </p>
            ) : (
              <>
                <input
                  aria-label="Enter Code"
                  className={cn({ [css.error]: !!errorMsg })}
                  disabled={isGcOnlyCart}
                  onChange={onTypeCouponCode}
                  value={couponCode}
                  id="code"
                  name="code"
                  data-test-id={testId('giftCardCodeInput')}
                  required={true}
                  autoComplete="off"
                  placeholder="Enter Code"
                />
                {!!errorMsg && (
                  <p className={css.errorMsg} data-test-id={testId('giftCardCodeError')}>
                    <AriaLiveTee>{errorMsg}</AriaLiveTee>
                  </p>
                )}
                {showPromoSuccessMessage && (
                  <p className={css.successMsg} data-test-id={testId('giftCardCodeSuccess')}>
                    <AriaLiveTee>Code was successfully redeemed.</AriaLiveTee>
                  </p>
                )}
                {!isGcOnlyCart && !showPromotionMessages && !purchaseHasShippingAddress && (
                  <p id="addShippingAddressInfo" className={css.addShippingAddressInfoText}>
                    Add or select a shipping address before applying codes
                  </p>
                )}
                {!isAmazonPayPurchase && isGcOnlyCart && (
                  <p className={css.gcOnlyCartMessage}>Gift cards or promo codes cannot be used to buy gift cards</p>
                )}
                <div className={css.btnContainer}>
                  <button
                    aria-describedby={!purchaseHasShippingAddress ? 'addShippingAddressInfo' : null}
                    aria-label="Apply Code"
                    data-test-id={testId('giftCardCodeSubmit')}
                    disabled={isGcOnlyCart || purchaseDataIsLoading || !purchaseHasShippingAddress || !couponCode?.length}
                    type="submit"
                    className={css.button}
                  >
                    Apply
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
      </div>

      {makeRedeemableRewards(props)}
    </>
  );
};

const makeRedeemableRewards = props => {
  const {
    hideRewardsRedemption,
    isGcOnlyCart,
    isRedeemingRewards,
    onRedeemRewardsPoints,
    purchaseType,
    redeemableRewards,
    redeemingRewardsStatus,
    spendPointDollarValue,
    spendPoints,
    onRedeemRewardsComponentView,
    onChangeRedemptionAmountEvent
  } = props;

  const isAmazonPayPurchase = purchaseType === AMAZON_PAY;

  if (hideRewardsRedemption) {
    return null;
  }

  return (
    <div className={css.rightCol}>
      <RedeemableRewardsRightCol
        openByDefault={false}
        isGcOnlyCart={isGcOnlyCart}
        hideRewardsRedemption={hideRewardsRedemption}
        isAmazonPayPurchase={isAmazonPayPurchase}
        isRedeemingRewards={isRedeemingRewards}
        onRedeemRewardsPoints={onRedeemRewardsPoints}
        redeemableRewards={redeemableRewards}
        redeemingRewardsStatus={redeemingRewardsStatus}
        spendPointDollarValue={spendPointDollarValue}
        spendPoints={spendPoints}
        onChangeRedemptionAmount={onChangeRedemptionAmountEvent}
        onRedeemRewardsComponentView={onRedeemRewardsComponentView}
      />
    </div>
  );
};

const mapStateToProps = state => {
  const {
    router: { location: location },
    checkoutData: {
      usePromoBalance,
      purchase: { shippingAddressId }
    }
  } = state;

  const purchaseHasShippingAddress = !!shippingAddressId;

  return {
    location,
    purchaseHasShippingAddress,
    usePromoBalance
  };
};

const PaymentCouponCodeConnected = connect(mapStateToProps, {
  onChangeRedemptionAmountEvent,
  onRedeemRewardsComponentView
})(PaymentCouponCode);

const PaymentCouponCodeConnectedWithErrorBoundary = withErrorBoundary('PaymentCouponCode', PaymentCouponCodeConnected);

export default PaymentCouponCodeConnectedWithErrorBoundary;
