import { cn } from 'helpers/classnames';
import {
  DELIVERY_INFORMATION_MISSING,
  DELIVERY_OPTION,
  DROP_PRODUCT_DIRECT_FULFILLMENT,
  DROP_PRODUCT_NOT_AVAILABLE,
  DROP_PRODUCT_PURCHASE_AUTHORIZATION_MISSING,
  DROP_PRODUCT_QUANTITY_LIMIT,
  FULLFILMENT_NETWORK_MISSING,
  INVALID_SELECTED_DELIVERY_OPTION_FOR_LINE_ITEM,
  ITEM_QUANTITY_UNAVAILABLE,
  JURISDICTION,
  MISSING_DIGITAL_DELIVERY_INFORMATION,
  OFFER_LISTING_AND_OFFER_SKU_DIFFER,
  OFFER_LISTING_NOT_AVAILABLE_CONSTRAINT_VIOLATION,
  QUANTITY_LIMITS,
  SHIPPING_ENGINE_FILTER_BASED,
  SHIPPING_ENGINE_PROVIDER_BASED,
  SHIPPING_ENGINE_REMOVER_BASED,
  SHIPPING_ENGINE_SHIPPING_OFFERING_NOT_SET,
  UNDELIVERABLE
} from 'constants/constraintViolations';
import { makeOptions } from 'components/cart/CartQuantityDropdown';
import useMartyContext from 'hooks/useMartyContext';
import RetailItemDetails, { RetailItemPrice } from 'components/checkout/splitReview/RetailItemDetails';
import DigitalItemDetails from 'components/checkout/splitReview/DigitalItemDetails';
import { AFTER_PAY, PAYPAL } from 'constants/paymentMethodTypes';
import UtilityDottedHeartOutlineSmallIcon from 'tailwind/components/Icons/UtilityDottedHeartOutlineSmallIcon';

import css from 'styles/components/checkout/splitReview/item.scss';

const RETAIL_ITEM = 'retail';

export const Item = props => {
  const {
    groupDetails: { allowMoveToFavorites, asinErrors, onChangeQuantity, onDeleteItem, onMoveToFavoritesClick },
    lineItem: {
      asin,
      image: { url: imageUrl, alt: imageAlt },
      lineItemId,
      onHand,
      originalPrice,
      price,
      quantity
    },
    isReviewStep,
    purchaseType,
    showItemLevelErrors,
    type,
    isLoading = false
  } = props;
  const {
    testId,
    marketplace: { dataMaskPiiElements }
  } = useMartyContext();
  const mustBeRemovedFromPurchase = checkIfMustBeRemovedFromPurchase({
    asinErrors,
    asin
  });
  const hasQuantityError = hasQuantityFieldError({ asinErrors, asin });
  const showQuantitySelector =
    type === RETAIL_ITEM && (!mustBeRemovedFromPurchase || !isReviewStep) && purchaseType !== AFTER_PAY && purchaseType !== PAYPAL;

  return (
    <div className={css.wrapper}>
      {showItemLevelErrors && makeAsinErrors({ asinErrors, asin })}
      <div className={css.cols}>
        <div className={css.leftCol} data-test-id={testId('itemLeftCol')} data-cs-mask={dataMaskPiiElements}>
          <div className={css.imageWrapper}>
            <img src={imageUrl} alt={imageAlt} />
          </div>
        </div>
        <div className={css.rightCol} data-test-id={testId('itemRightCol')}>
          {type === RETAIL_ITEM ? <RetailItemDetails {...props} /> : <DigitalItemDetails {...props} />}
        </div>
        {type === RETAIL_ITEM && (
          <div className={css.priceCol} data-cs-mask={dataMaskPiiElements}>
            <RetailItemPrice price={price} originalPrice={originalPrice} quantity={quantity} />
          </div>
        )}
      </div>
      <div className={css.actionsWrapper}>
        {showQuantitySelector && (
          <form method="post" action="/tbd">
            <div className={cn({ [css.fieldError]: hasQuantityError })}>
              <select
                aria-label="Quantity"
                data-test-id={testId('quantitySelector')}
                id={`qty-${lineItemId}`}
                name={`qty-${lineItemId}`}
                onChange={e => onChangeQuantity(e, lineItemId)}
                disabled={isLoading}
                value={quantity}
                data-cs-mask={dataMaskPiiElements}
              >
                <option value="0">Remove</option>
                {makeOptions(onHand, quantity)}
              </select>
              {makeQuantityErrors({ asinErrors, asin })}
            </div>
          </form>
        )}
        {!showQuantitySelector && (
          <select
            aria-label="Quantity"
            data-test-id={testId('quantitySelector')}
            id={`qty-${lineItemId}`}
            name={`qty-${lineItemId}`}
            disabled={mustBeRemovedFromPurchase}
            onChange={e => onChangeQuantity(e, lineItemId)}
            value={quantity}
            data-cs-mask={dataMaskPiiElements}
          >
            <option value="0">Remove</option>
            <option value={quantity}>{quantity}</option>
          </select>
        )}
        <form className={css.ctaForm} method="post" action="/tbd">
          {allowMoveToFavorites && (
            <>
              <p>
                <button className={css.ctaLink} data-line-item-id={lineItemId} onClick={onDeleteItem} type="button">
                  Remove
                </button>
              </p>
              {type === RETAIL_ITEM && (
                <p>
                  <button
                    aria-label="Move to Favorites"
                    className={css.ctaLink}
                    data-asin={asin}
                    data-line-item-id={lineItemId}
                    onClick={onMoveToFavoritesClick}
                    type="button"
                  >
                    Move To <UtilityDottedHeartOutlineSmallIcon size={16} />
                  </button>
                </p>
              )}
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export function hasQuantityFieldError({ asinErrors, asin }) {
  if (!asinErrors?.hasOwnProperty(asin)) {
    return false;
  }

  const asinErrorList = [ITEM_QUANTITY_UNAVAILABLE, QUANTITY_LIMITS, DROP_PRODUCT_QUANTITY_LIMIT];

  const result = asinErrors[asin].filter(info => asinErrorList.some(name => name === info.name));

  return !!result.length;
}

export function makeQuantityErrors({ asinErrors, asin }) {
  if (!asinErrors?.hasOwnProperty(asin)) {
    return null;
  }

  const asinErrorList = [DROP_PRODUCT_QUANTITY_LIMIT, ITEM_QUANTITY_UNAVAILABLE, QUANTITY_LIMITS];

  const result = asinErrors[asin].reduce((acc, { name, quantityAvailable }) => {
    if (asinErrorList.some(errorListName => errorListName === name)) {
      switch (name) {
        case ITEM_QUANTITY_UNAVAILABLE: {
          const message = quantityAvailable ? `only ${quantityAvailable} left` : 'quantity not available';
          return acc.concat(<p key={name}>{message}</p>);
        }
        default: {
          return acc.concat(<p key={name}>quantity not available</p>);
        }
      }
    }
    return acc;
  }, []);

  return !!result.length && <div className={css.quantityErrorMsg}>{result}</div>;
}

export function makeAsinErrors({ asinErrors, asin }) {
  if (!asinErrors?.hasOwnProperty(asin)) {
    return null;
  }

  const asinErrorList = [
    DELIVERY_OPTION,
    DELIVERY_INFORMATION_MISSING,
    DROP_PRODUCT_DIRECT_FULFILLMENT,
    UNDELIVERABLE,
    DROP_PRODUCT_NOT_AVAILABLE,
    DROP_PRODUCT_PURCHASE_AUTHORIZATION_MISSING,
    FULLFILMENT_NETWORK_MISSING,
    JURISDICTION,
    MISSING_DIGITAL_DELIVERY_INFORMATION,
    OFFER_LISTING_AND_OFFER_SKU_DIFFER,
    OFFER_LISTING_NOT_AVAILABLE_CONSTRAINT_VIOLATION,
    SHIPPING_ENGINE_FILTER_BASED,
    SHIPPING_ENGINE_REMOVER_BASED,
    SHIPPING_ENGINE_PROVIDER_BASED,
    SHIPPING_ENGINE_SHIPPING_OFFERING_NOT_SET,
    INVALID_SELECTED_DELIVERY_OPTION_FOR_LINE_ITEM
  ];

  const result = asinErrors[asin].reduce((acc, { name, message }) => {
    if (asinErrorList.some(errorListName => errorListName === name)) {
      switch (name) {
        case INVALID_SELECTED_DELIVERY_OPTION_FOR_LINE_ITEM: {
          return acc.concat(
            <p key={name}>
              <strong>Please select a different shipping speed.</strong> Unfortunately, this item cannot be shipped with the chosen shipment speed.
            </p>
          );
        }
        case SHIPPING_ENGINE_FILTER_BASED:
        case DELIVERY_OPTION:
        case DELIVERY_INFORMATION_MISSING: {
          return acc.concat(
            <p key={name}>
              <strong>Please remove this item.</strong> Unfortunately, this item has no valid shipping option. We are working on fixing this issue on
              our end.
            </p>
          );
        }
        default: {
          return acc.concat(<p key={name}>{message}</p>);
        }
      }
    }
    return acc;
  }, []);

  return result.length ? <div className={css.errorMsg}>{result}</div> : '';
}

export function checkIfMustBeRemovedFromPurchase({ asinErrors, asin }) {
  if (!asinErrors?.hasOwnProperty(asin)) {
    return false;
  }

  const asinErrorList = [DELIVERY_OPTION, DELIVERY_INFORMATION_MISSING, DROP_PRODUCT_NOT_AVAILABLE, SHIPPING_ENGINE_FILTER_BASED];

  const result = asinErrors[asin].filter(info => asinErrorList.some(name => name === info.name));

  return !!result.length;
}

export default Item;
