import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';

import { cn } from 'helpers/classnames';
import { AFTER_PAY } from 'constants/paymentMethodTypes';
import { pluralize } from 'helpers';
import { isNonRetailOnlyCart } from 'helpers/CheckoutUtils';
import { toUSD } from 'helpers/NumberFormats';
import { selectCheckoutData, selectGiftCardBalance } from 'selectors/checkout';
import TermsAndConditions from 'components/checkout/OrderTotal/TermsAndConditions';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import VipEnrollment from 'components/checkout/VipEnrollment';
import UtilityStrokeInfoOutlineSmallIcon from 'tailwind/components/Icons/UtilityStrokeInfoOutlineSmallIcon';
import PaymentCouponMessage from 'components/checkout/payment/PaymentCouponMessage';
import { inIframe } from 'helpers/InIframe';

import css from 'styles/components/checkout/chargeSummary.scss';

export class ChargeSummary extends Component {
  state = {
    showSitckyPlaceOrder: false
  };

  // Renders the sticky version of the place order
  makeStickyPlaceOrder = () => {
    const {
      isAlternate,
      placeOrderBtnSticky,
      checkoutData: {
        purchase: { chargeSummary = {} },
        numItems
      }
    } = this.props;
    const { grandTotal } = chargeSummary;
    const { testId = f => f } = this.context;

    return (
      <div
        className={cn(css.wrapperSticky, { [css.alternate]: isAlternate, [css.shouldStick]: this.state.showSitckyPlaceOrder })}
        data-test-id={testId('stickyPlaceOrderContainer')}
      >
        <div className={cn(css.headerSticky, css.line)}>
          Order Total (
          <span data-test-id={testId('itemCount')}>
            {numItems} {pluralize('Item', numItems)}
          </span>
          )<div className={css.total}>{toUSD(grandTotal)}</div>
        </div>
        {placeOrderBtnSticky}
        <TermsAndConditions />
      </div>
    );
  };

  getShippingCharge = ({ shippingSubtotal, shippingCharge }) => {
    const {
      marketplace: {
        checkout: { showShippingDiscountLineItem }
      }
    } = this.context;
    if (showShippingDiscountLineItem) {
      return toUSD(shippingSubtotal);
    }

    return shippingCharge === 0 ? 'FREE' : toUSD(shippingCharge);
  };

  getShippingLineItemDiscount = ({ shippingDiscount }) => {
    const {
      marketplace: {
        checkout: { showShippingDiscountLineItem }
      }
    } = this.context;
    if (showShippingDiscountLineItem && shippingDiscount) {
      return toUSD(shippingDiscount);
    }
  };

  render() {
    const {
      asinErrors,
      giftMessage,
      isReviewStep,
      showVipEnrollmentCheckbox,
      isVipEnrollmentSelected,
      includeVipEmailSubscriptions,
      onVipEnrollmentCheckboxClick,
      onVipEnrollmentTermsClick,
      isVipCheckboxTogglingDisabled,
      checkoutData: {
        cartType,
        purchase: { chargeSummary = {}, eligibleBalances },
        numItems
      },
      placeOrderBtn,
      paymentMethodType,
      showAddAddressMessage,
      giftCardBalance,
      isInsufficientCoverage,
      isDiscountFullBalance,
      usePromoBalance,
      isAlternate,
      purchaseHasShippingAddress,
      couponMessages,
      isGcOnlyCart
    } = this.props;

    const {
      additionalCharges = [],
      couponTotal,
      estimatedTax,
      giftWrapCharge,
      grandTotal,
      shippingCharge,
      shippingDiscount,
      shippingSubtotal,
      subTotal,
      total,
      totalBeforeTax
    } = chargeSummary;

    const { testId = f => f } = this.context;
    const shippingParams = {
      shippingDiscount,
      shippingSubtotal,
      shippingCharge
    };
    const coRetailDeliveryFees = additionalCharges.find(charge => charge.description === 'CO Retail Delivery Fees');
    const displayedShippingCharge = this.getShippingCharge(shippingParams);
    const displayedShippingDiscount = this.getShippingLineItemDiscount(shippingParams);
    const doesCartHaveGiftCard = isNonRetailOnlyCart(cartType);
    const isGcNotAppliedMessage = doesCartHaveGiftCard && !!eligibleBalances?.gcBalance && !giftCardBalance;
    const isEmptySummary = isInsufficientCoverage && !isDiscountFullBalance && usePromoBalance;

    return (
      <>
        <div className={cn(css.wrapper, { [css.alternate]: isAlternate })}>
          <div className={cn(css.header, css.line)}>Order Summary</div>
          <div className={css.summary}>
            <div className={css.line}>
              <div>
                Subtotal: (
                <span data-test-id={testId('itemCount')}>
                  {numItems} {pluralize('Item', numItems)}
                </span>
                )
              </div>
              <div className={css.subTotal} data-test-id={testId('itemSubTotal')}>
                {toUSD(subTotal)}
              </div>
            </div>

            {isEmptySummary ? (
              <>
                <div className={css.line}>
                  Shipping
                  <div>--</div>
                </div>
                <div className={css.line}>
                  Discounts
                  <div>--</div>
                </div>
              </>
            ) : (
              <>
                {!!giftWrapCharge && (
                  <div className={css.line}>
                    Gift Wrap
                    <div data-test-id={testId('giftWrapTotal')}>{toUSD(giftWrapCharge)}</div>
                  </div>
                )}

                <div className={css.line}>
                  Shipping
                  <div className={cn({ [css.alternateAmount]: shippingCharge === 0 })} data-test-id={testId('shippingCharge')}>
                    {displayedShippingCharge}
                  </div>
                </div>

                {!!displayedShippingDiscount && (
                  <div className={css.line}>
                    Free Shipping
                    <div data-test-id={testId('shippingDiscount')}>-{displayedShippingDiscount}</div>
                  </div>
                )}

                {!!couponMessages.length && !isGcOnlyCart && !inIframe() && (
                  <PaymentCouponMessage purchaseHasShippingAddress={purchaseHasShippingAddress} couponMessages={couponMessages} />
                )}

                {!!couponTotal && (
                  <div className={css.line}>
                    Discounts
                    <div className={css.alternateAmount} data-test-id={testId('couponDiscount')}>
                      -{toUSD(couponTotal)}
                    </div>
                  </div>
                )}

                {!!showAddAddressMessage && (
                  <div className={css.line}>
                    Discounts
                    <div className={css.noAddressValue} data-test-id={testId('noAddressMessage')}>
                      Auto-applied discounts will appear here once a shipping address is entered
                    </div>
                  </div>
                )}

                <div className={css.line}>
                  Total before tax
                  <div data-test-id={testId('preTaxTotal')}>{toUSD(totalBeforeTax)}</div>
                </div>

                <div className={css.line}>
                  Estimated tax to be collected
                  <div data-test-id={testId('estimatedTax')}>{toUSD(estimatedTax)}</div>
                </div>

                {!!coRetailDeliveryFees && (
                  <div className={css.line}>
                    {coRetailDeliveryFees.description}
                    <div data-test-id={testId('coRetailDeliveryFees')}>{toUSD(coRetailDeliveryFees.amount)}</div>
                  </div>
                )}

                {!!total && !!giftCardBalance && (
                  <div className={css.line}>
                    Total
                    <div data-test-id={testId('totalAmountRemaining')}>{toUSD(total)}</div>
                  </div>
                )}

                {!!giftCardBalance && (
                  <div className={css.line}>
                    Gift Cards
                    <div data-test-id={testId('gcRemaining')}>-{toUSD(giftCardBalance)}</div>
                  </div>
                )}

                {!!isGcNotAppliedMessage && (
                  <div className={css.line}>
                    Gift Cards
                    <div className={css.noGcMsg} data-test-id={testId('gcRemaining')}>
                      Gift cards cannot be applied to orders with gift cards.
                    </div>
                  </div>
                )}
              </>
            )}

            <div className={cn(css.paymentDue, css.line)}>
              Order Total
              <div data-test-id={testId('grandTotal')}>{isEmptySummary ? '--' : toUSD(grandTotal)}</div>
            </div>

            {paymentMethodType === AFTER_PAY && (
              <div className={css.afterpaySection}>
                <afterpay-placement
                  data-size="sm"
                  data-cart-is-eligible="true"
                  data-intro-text="In"
                  data-locale="en_US"
                  data-currency="USD"
                  data-logo-type="lockup"
                  data-amount={grandTotal}
                />
              </div>
            )}

            {giftMessage && (
              <div className={css.giftMessageLine}>
                <span className={css.giftIcon}></span> Includes a FREE gift message and receipt
              </div>
            )}
          </div>

          <InView onChange={inView => this.setState({ showSitckyPlaceOrder: !inView })}>{placeOrderBtn}</InView>

          {isReviewStep && !!asinErrors && !!Object.keys(asinErrors).length && (
            <div className={css.errorBox}>
              <span className="flex-auto">
                <UtilityStrokeInfoOutlineSmallIcon size={16} />
              </span>
              There is a problem with an item on your purchase. Please view item details for more information.
            </div>
          )}

          <TermsAndConditions showSalesTaxNote showAdditionalFeesNote />

          {showVipEnrollmentCheckbox && (
            <VipEnrollment
              isVipEnrollmentSelected={isVipEnrollmentSelected}
              includeVipEmailSubscriptions={includeVipEmailSubscriptions}
              onVipEnrollmentCheckboxClick={onVipEnrollmentCheckboxClick}
              onVipEnrollmentTermsClick={onVipEnrollmentTermsClick}
              isVipCheckboxTogglingDisabled={isVipCheckboxTogglingDisabled}
              isMobileView={true}
            />
          )}
        </div>
        {this.makeStickyPlaceOrder()}
      </>
    );
  }
}

ChargeSummary.contextTypes = {
  testId: PropTypes.func,
  marketplace: PropTypes.object
};

const mapStateToProps = state => {
  const {
    checkoutData: {
      promotionMessages,
      purchase: { shippingAddressId, chargeSummary }
    }
  } = state;

  const purchaseHasShippingAddress = !!shippingAddressId;

  // Formats promotion messages to avoid coupon duplication (Zoupon sends the same promotions per item in the cart)
  const formattedPromotionMessages = {};
  for (const itemAvailablePromos of Object.values(promotionMessages || {})) {
    itemAvailablePromos.forEach(({ amountOff, components, promotionId }) => {
      formattedPromotionMessages[promotionId] = {
        amountUsed: amountOff,
        claimCode: promotionId,
        description: components?.CHECKOUT_MESSAGE
      };
    });
  }

  // If user has a saved address, uses the charge summary, if not, uses zoupon
  const couponMessages = purchaseHasShippingAddress ? chargeSummary?.coupons || [] : Object.values(formattedPromotionMessages);

  return {
    purchaseHasShippingAddress,
    couponMessages,
    checkoutData: selectCheckoutData(state),
    giftCardBalance: selectGiftCardBalance(state)
  };
};

const ChargeSummaryConnected = connect(mapStateToProps, {})(ChargeSummary);

const ChargeSummaryConnectedWithErrorBoundary = withErrorBoundary('ChargeSummary', ChargeSummaryConnected);
export default ChargeSummaryConnectedWithErrorBoundary;
