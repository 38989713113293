import { connect } from 'react-redux';

import { cn } from 'helpers/classnames';
import { isDigitalCart, isDigitalDeliveryOnlyCart } from 'helpers/CheckoutUtils';
import ShippingOptionGroupWithDates from 'components/checkout/splitReview/ShippingOptionGroupWithDates';
import ReviewGroup from 'components/checkout/splitReview/ReviewGroup';
import VipEnrollment from 'components/checkout/VipEnrollment';
import useMartyContext from 'hooks/useMartyContext';
import SectionTitle from 'components/checkout/SectionTitle';
import { isAssigned, triggerAssignment } from 'actions/ab';
import { HYDRA_SHIPPING_RANGE_DATES } from 'constants/hydraTests';
import useEffectOnce from 'hooks/useEffectOnce';

import css from 'styles/containers/checkout/splitReview.scss';

export const SplitReview = props => {
  const {
    checkoutData: {
      cartType,
      digitalItemLineItemId,
      productsByLineItem,
      selectedShipOptionId,
      asinErrors,
      constraintViolations,
      isLoading: isFetchingCheckoutData
    },
    isReviewStep,
    showVipEnrollmentCheckbox,
    isVipEnrollmentSelected,
    includeVipEmailSubscriptions,
    onVipEnrollmentCheckboxClick,
    onVipEnrollmentTermsClick,
    isVipCheckboxTogglingDisabled,
    onChangeQuantity,
    onDeleteItem,
    onMoveToFavoritesClick,
    onSelectAndUseShippingOptionClick,
    purchaseType,
    shipOption: { lineItemDeliveryOptions = [] } = {},
    isHydraShippingRangeDates,
    triggerAssignmentDispatch = () => {},
    giftMessageComponent = null
  } = props;

  const {
    marketplace: {
      checkout: { allowMoveToFavorites }
    }
  } = useMartyContext();
  const hasDigitalItem = isDigitalCart(cartType);
  const isOnlyDigitalDelivery = isDigitalDeliveryOnlyCart(cartType);
  const allLineItemIds = Object.keys(productsByLineItem);

  // for all items in cart, find that they exist in a delivery option. Negate the find to filter all those that weren't found.
  const nonDeliverableItemIds = allLineItemIds.filter(
    itemInCartId =>
      !lineItemDeliveryOptions.find(deliveryOption => deliveryOption.lineItemIds?.find(deliverableItemId => deliverableItemId === itemInCartId))
  );
  const hasNonDeliverableItems = nonDeliverableItemIds.length > 0;

  const groupDetails = {
    allowMoveToFavorites,
    asinErrors,
    constraintViolations,
    onChangeQuantity,
    onDeleteItem,
    onMoveToFavoritesClick
  };

  const egcLineItems = lineItemDeliveryOptions?.filter(lineItemDeliveryOption => {
    const { deliveryOptions, lineItemIds } = lineItemDeliveryOption;
    return deliveryOptions.length === 0 && lineItemIds[0] === digitalItemLineItemId;
  });

  const nonEgcLineItems = lineItemDeliveryOptions?.filter(lineItemDeliveryOption => {
    const { deliveryOptions, lineItemIds } = lineItemDeliveryOption;
    return !(deliveryOptions.length === 0 && lineItemIds[0] === digitalItemLineItemId);
  });

  useEffectOnce(() => {
    if (!isOnlyDigitalDelivery) {
      triggerAssignmentDispatch(HYDRA_SHIPPING_RANGE_DATES);
    }
  });

  if (!lineItemDeliveryOptions.length) {
    return (
      <>
        <SectionTitle id="split-review-section" title="Shipping Options" />
        <div className={css.groupsNoOptions}>
          {showVipEnrollmentCheckbox && (
            <VipEnrollment
              isVipEnrollmentSelected={isVipEnrollmentSelected}
              includeVipEmailSubscriptions={includeVipEmailSubscriptions}
              onVipEnrollmentCheckboxClick={onVipEnrollmentCheckboxClick}
              onVipEnrollmentTermsClick={onVipEnrollmentTermsClick}
              isVipCheckboxTogglingDisabled={isVipCheckboxTogglingDisabled}
            />
          )}
          <div
            className={cn(css.deliveryGroup, {
              [css.updating]: isFetchingCheckoutData
            })}
          >
            <div>
              <ReviewGroup
                groupDetails={groupDetails}
                isForEGC={isOnlyDigitalDelivery}
                isReviewStep={isReviewStep}
                lineItemIds={allLineItemIds}
                purchaseType={purchaseType}
                productsByLineItem={productsByLineItem}
                showItemLevelErrors={isOnlyDigitalDelivery || isReviewStep}
              />
            </div>
            {isOnlyDigitalDelivery ? (
              <>
                <ShippingOptionGroupWithDates
                  isForEGC={true}
                  numShipments={1}
                  onSelectAndUseShippingOptionClick={f => f}
                  selectedShipOptionId={selectedShipOptionId}
                  shipmentNumber={1}
                  isLoading={isFetchingCheckoutData}
                />
              </>
            ) : (
              <div>
                <div className={css.noOptions}>Please add or select a shipping address to view delivery options.</div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <SectionTitle id="split-review-section" title="Shipping Options" />
      <div className={css.groups}>
        {showVipEnrollmentCheckbox && (
          <VipEnrollment
            isVipEnrollmentSelected={isVipEnrollmentSelected}
            includeVipEmailSubscriptions={includeVipEmailSubscriptions}
            onVipEnrollmentCheckboxClick={onVipEnrollmentCheckboxClick}
            onVipEnrollmentTermsClick={onVipEnrollmentTermsClick}
            isVipCheckboxTogglingDisabled={isVipCheckboxTogglingDisabled}
          />
        )}
        {/* NON EGC */}
        {nonEgcLineItems.map((lineItemDeliveryOption, i) => {
          const { deliveryOptions, lineItemIds, purchaseDelivery } = lineItemDeliveryOption;
          return (
            <div
              className={cn(css.deliveryGroup, {
                [css.updating]: isFetchingCheckoutData,
                [css.withTopBorder]: i > 0
              })}
              key={lineItemIds.join('')}
            >
              <div>
                <ReviewGroup
                  groupDetails={groupDetails}
                  isForEGC={false}
                  isReviewStep={isReviewStep}
                  isLoading={isFetchingCheckoutData}
                  lineItemIds={lineItemIds}
                  productsByLineItem={productsByLineItem}
                  purchaseType={purchaseType}
                  showItemLevelErrors={isReviewStep}
                />
              </div>
              <div>
                <ShippingOptionGroupWithDates
                  deliveryOptions={deliveryOptions}
                  isForEGC={false}
                  numShipments={hasDigitalItem ? lineItemDeliveryOptions.length - 1 : lineItemDeliveryOptions.length}
                  onSelectAndUseShippingOptionClick={e => onSelectAndUseShippingOptionClick(e, lineItemIds)}
                  purchaseDelivery={purchaseDelivery}
                  selectedShipOptionId={selectedShipOptionId}
                  shipmentNumber={i + 1}
                  isHydraShippingRangeDates={isHydraShippingRangeDates}
                  isLoading={isFetchingCheckoutData}
                />
                {<div className={css.hideMobile}>{giftMessageComponent}</div>}
              </div>
            </div>
          );
        })}
        {hasNonDeliverableItems ? (
          <div
            className={cn(css.deliveryGroup, css.nonDeliverableGroup, {
              [css.updating]: isFetchingCheckoutData,
              [css.withTopBorder]: true
            })}
          >
            <ReviewGroup
              groupDetails={groupDetails}
              isForEGC={false}
              isReviewStep={isReviewStep}
              isLoading={isFetchingCheckoutData}
              lineItemIds={nonDeliverableItemIds}
              productsByLineItem={productsByLineItem}
              purchaseType={purchaseType}
              showItemLevelErrors={isReviewStep}
            />
          </div>
        ) : null}
        {<div className={css.giftMsgContainerMobile}>{giftMessageComponent}</div>}
      </div>
      {/* EGC */}
      {egcLineItems?.length > 0 && (
        <div className={css.groups}>
          {egcLineItems.map((lineItemDeliveryOption, i) => {
            const { deliveryOptions, lineItemIds, purchaseDelivery } = lineItemDeliveryOption;
            const { egcDeliveryDate } = productsByLineItem[lineItemIds[0]];
            return (
              <div
                className={cn(
                  css.deliveryGroup,
                  {
                    [css.updating]: isFetchingCheckoutData
                  },
                  css.electronicDelivery
                )}
                key={lineItemIds.join('')}
              >
                <div>
                  <ReviewGroup
                    groupDetails={groupDetails}
                    isForEGC={true}
                    isReviewStep={isReviewStep}
                    isLoading={isFetchingCheckoutData}
                    lineItemIds={lineItemIds}
                    productsByLineItem={productsByLineItem}
                    purchaseType={purchaseType}
                    showItemLevelErrors={isReviewStep}
                  />
                </div>
                <div>
                  <ShippingOptionGroupWithDates
                    deliveryOptions={deliveryOptions}
                    isForEGC={true}
                    egcDeliveryDate={egcDeliveryDate}
                    numShipments={hasDigitalItem ? lineItemDeliveryOptions.length - 1 : lineItemDeliveryOptions.length}
                    onSelectAndUseShippingOptionClick={e => onSelectAndUseShippingOptionClick(e, lineItemIds)}
                    purchaseDelivery={purchaseDelivery}
                    selectedShipOptionId={selectedShipOptionId}
                    shipmentNumber={i + 1}
                    isHydraShippingRangeDates={isHydraShippingRangeDates}
                    isLoading={isFetchingCheckoutData}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  const { checkoutData, shipOption } = state;

  return {
    checkoutData,
    isHydraShippingRangeDates: isAssigned(HYDRA_SHIPPING_RANGE_DATES, 1, state),
    shipOption
  };
};

const mapDispatchToProps = {
  triggerAssignmentDispatch: triggerAssignment
};

export default connect(mapStateToProps, mapDispatchToProps)(SplitReview);
