import { Link } from 'react-router-dom';

import useMartyContext from 'hooks/useMartyContext';
import UtilityStrokeEditMediumIcon from 'tailwind/components/Icons/UtilityStrokeEditMediumIcon';
import UtilityDottedSwapMediumIcon from 'tailwind/components/Icons/UtilityDottedSwapMediumIcon';

import css from 'styles/components/checkout/sectionChangeInline.scss';

const IconMap = {
  edit: <UtilityStrokeEditMediumIcon size={24} />,
  swap: <UtilityDottedSwapMediumIcon size={24} />
};

const SectionChangeInline = ({ id, label, describedby, testIdName = 'sectionChangeLink', onClickEvent, showLink, to, isInline, icon }) => {
  const { testId } = useMartyContext();

  if (!showLink) {
    return (
      <button aria-describedby={describedby} className={!isInline ? css.changeLink : css.inline} disabled={true} type="button">
        {icon && IconMap[icon]}
        {label}
      </button>
    );
  }

  return (
    <Link
      id={id}
      aria-describedby={describedby}
      className={!isInline ? css.changeLink : css.inline}
      to={to}
      onClick={onClickEvent}
      data-test-id={testId(testIdName)}
    >
      {icon ? IconMap[icon] : label}
    </Link>
  );
};

export default SectionChangeInline;
