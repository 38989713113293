import { toUSD } from 'helpers/NumberFormats';
import useMartyContext from 'hooks/useMartyContext';
import { THIRD_PARTY_PAYMENT_METHODS } from 'constants/paymentMethodTypes';

import css from 'styles/components/checkout/payment/paymentAsList/giftOrPromoRow.scss';

export const GiftOrPromoRow = params => {
  const {
    doesCartContainGiftCard,
    eligibleBalances,
    onUsePromoBalanceClick,
    purchaseDataIsLoading,
    useDiscountBalance,
    usePromoBalance,
    paymentMethodType,
    purchaseType
  } = params;

  const {
    testId,
    marketplace: { dataMaskPiiElements }
  } = useMartyContext();
  const { combinedDiscountBalance, coupons, gcBalance, couponBalance } = eligibleBalances;
  const savedBalances = gcBalance ? [...coupons, { balance: gcBalance }] : coupons;
  const disableToggle = doesCartContainGiftCard && !!gcBalance && savedBalances.length === 1;

  if (!combinedDiscountBalance) {
    return null;
  }

  /* if the selected tab/radio is a 3rd party payment method
   * and they have completed corresponding 3rd party dialog
   * then don't allow them to change gift/promo
   */
  if (THIRD_PARTY_PAYMENT_METHODS.includes(paymentMethodType) && paymentMethodType === purchaseType) {
    return null;
  }

  return (
    <div className={css.selectionContent}>
      {doesCartContainGiftCard && !!gcBalance && !coupons.length ? (
        <>
          Gift cards cannot be applied to order with gift cards. Your {toUSD(combinedDiscountBalance)} gift card balance will remain on your account
          for future purchases.
        </>
      ) : (
        <>
          <input
            checked={disableToggle ? false : useDiscountBalance ? true : usePromoBalance}
            data-test-id={testId('usePromoBalanceCheckbox')}
            disabled={disableToggle || purchaseDataIsLoading}
            id="payment-savedBalance"
            name="payment-savedBalance"
            onChange={onUsePromoBalanceClick}
            type="checkbox"
          />
          <label className={css.paymentOptionBalanceWrapper} htmlFor="payment-savedBalance">
            <ul className={css.balances} data-cs-mask={dataMaskPiiElements}>
              {doesCartContainGiftCard && !!gcBalance ? (
                <>
                  <li>Use your {toUSD(couponBalance)} promotional balance</li>
                  <li className={css.cannotUseGcMessage}>
                    Gift cards cannot be applied to order with gift cards. Your {toUSD(gcBalance)} gift card balance will remain on your account for
                    future purchases.
                  </li>
                </>
              ) : (
                <li>Use your {toUSD(combinedDiscountBalance)} gift card or promotional balance</li>
              )}
            </ul>
          </label>
        </>
      )}
    </div>
  );
};

export default GiftOrPromoRow;
