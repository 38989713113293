// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { SmallIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokePlusSmallIcon = (props: SmallIconProps) => (
  <Icon {...props} viewBox="0 0 16 16">
    <path
      d="M8.16697 13.7258V1.94067M14.0595 7.83325H2.27441"
      stroke="currentcolor"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default UtilityStrokePlusSmallIcon;
