import ModalAddressList from 'components/checkout/address/ModalAddressList';

import css from 'styles/components/checkout/payment/paymentAsList/afterPayPaymentReview.scss';

export const AfterPayPaymentReview = ({ grandTotal, needsBillingAddress, isBillingStep }) => (
  <div className={css.paymentSection} id="afterpay-content" role="tabpanel" aria-labelledby="afterpay-tab">
    <afterpay-placement
      data-cart-is-eligible="true"
      data-intro-text="Pay in"
      data-locale="en_US"
      data-currency="USD"
      data-logo-type="lockup"
      data-amount={grandTotal}
    />

    <afterpay-placement data-type="price-table" data-amount={grandTotal} data-currency="USD" />

    <ModalAddressList
      invalidAddressError={needsBillingAddress && 'Please select or add a new billing address.'}
      isBilling={true}
      isOpen={isBillingStep}
    />
  </div>
);

export default AfterPayPaymentReview;
