import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { cn } from 'helpers/classnames';
import { REVIEW_STEP } from 'constants/checkoutFlow';
import ReEnterCardText from 'components/checkout/payment/ReEnterCardText';
import ReEnterCardForm from 'components/checkout/payment/ReEnterCardForm';
import UpdateCardExpirationSection from 'components/checkout/payment/paymentAsList/UpdateCardExpirationSection';
import { PAYMENT_FIELDS } from 'constants/formFields';
import useMartyContext from 'hooks/useMartyContext';
import MultiLinePayment from 'components/checkout/payment/MultiLinePayment';
import NewPaymentForm from 'components/checkout/payment/NewPaymentForm';
import SectionCancelInline from 'components/checkout/SectionCancelInline';
import UtilityStrokePlusCircleMediumIcon from 'tailwind/components/Icons/UtilityStrokePlusCircleMediumIcon';
import SectionDivider from 'components/checkout/SectionDivider';
import { selectCheckoutLinks } from 'selectors/checkout';

import css from 'styles/components/checkout/payment/paymentAsList.scss';

export const PaymentAsList = props => {
  const { testId } = useMartyContext();
  const [expirationMonth, setExpirationMonth] = useState('');
  const [expirationYear, setExpirationYear] = useState('');
  const [ccivrOpen, setCcivrOpen] = useState(true);
  const [hasAutoUsedCard, setHasAutoUsedCard] = useState(false);
  const [seeAllCards, setSeeAllCards] = useState(false);
  const links = useSelector(selectCheckoutLinks);

  const {
    isLoading,
    hasSavedPayments,
    doesPurchaseRequireCC,
    hasInactiveInstrument,
    hasShippingAddress,
    hasVerifyCreditCardError,
    showNeedShipAddressMsg,
    onAddNewCardLinkTo,
    onAddNewPaymentClick,
    onAutoUseCard,
    onDeletePaymentInstrumentClick,
    onSubmitPayment,
    onUpdateExpirationSubmit,
    onUseCreditCardClick,
    onVerifyCardClick,
    purchaseCreditCard,
    purchaseDataIsLoading,
    showCCIVRPayment,
    showAddCreditCardForm,
    onChangeBillingAddressClick,
    savePaymentRef,
    sharedPayment: { formItem, isLoading: paymentDataIsLoading, savedPayments, selectedPaymentNeedsConfirmation }
  } = props;

  // Controls the credit card selection
  const [selectedPaymentInstrumentId, setSelectedPaymentInstrumentId] = useState(purchaseCreditCard?.paymentInstrumentId);

  const { formErrors = {} } = formItem;
  const NUM_SAVED_CREDIT_CARDS_PER_PAGE = 4;

  const showAllCardsButton = savedPayments.length > NUM_SAVED_CREDIT_CARDS_PER_PAGE && !seeAllCards;

  const isErrorField = field => !!formItem.formErrors?.[field];
  const isExpirationError = isErrorField(PAYMENT_FIELDS.CC_EXPIRATION_MELODY.fieldName);

  useEffect(() => {
    if (!purchaseCreditCard && savedPayments.length && hasShippingAddress && doesPurchaseRequireCC && !hasAutoUsedCard) {
      const { paymentInstrumentId } = savedPayments[0];
      onAutoUseCard(paymentInstrumentId);
      setHasAutoUsedCard(true);
    }
  }, [doesPurchaseRequireCC, hasAutoUsedCard, hasShippingAddress, onAutoUseCard, purchaseCreditCard, savedPayments]);

  if (showNeedShipAddressMsg) {
    return null;
  }

  const onDeletePayment = e => {
    e.preventDefault();
    const {
      currentTarget: {
        dataset: { paymentInstrumentId }
      }
    } = e;
    onDeletePaymentInstrumentClick(paymentInstrumentId);
  };

  const onExpirationFieldChange = e => {
    const {
      target: { name, value }
    } = e;
    if (name === 'expirationMonth') {
      setExpirationMonth(value);
    }

    if (name === 'expirationYear') {
      setExpirationYear(value);
    }
  };

  const onUpdateExpirationClick = e => {
    e.preventDefault();
    const { paymentInstrumentId } = purchaseCreditCard;
    onUpdateExpirationSubmit({
      paymentInstrumentId,
      expirationMonth,
      expirationYear
    });
  };

  const onCloseCCIVR = () => {
    setCcivrOpen(false);
  };

  const onOpenCCIVR = () => {
    setCcivrOpen(true);
  };

  const makeUpdateCardExpirationSection = ({ isInvisible }) => {
    isInvisible && setSeeAllCards(true);
    return (
      <div className={css.updateExpiration}>
        <SectionDivider />
        <UpdateCardExpirationSection
          expirationMonth={expirationMonth}
          expirationYear={expirationYear}
          formErrors={formErrors}
          isExpirationError={isExpirationError}
          onFieldChange={onExpirationFieldChange}
          onUpdateExpirationClick={onUpdateExpirationClick}
          purchaseDataIsLoading={purchaseDataIsLoading}
        />
      </div>
    );
  };

  const makeReValidateCardSection = ({ paymentInstrumentId, isInvisible }) => {
    isInvisible && setSeeAllCards(true);
    return (
      <div className={css.reValidateCardSection}>
        <SectionDivider />
        <ReEnterCardText isChecked={true} isExpired={false} selectedPaymentNeedsConfirmation={selectedPaymentNeedsConfirmation} />
        <ReEnterCardForm
          formItem={formItem}
          hasVerifyCreditCardError={hasVerifyCreditCardError}
          isChecked={true}
          isExpired={false}
          isLoading={purchaseDataIsLoading}
          onVerifyCardClick={onVerifyCardClick}
          paymentInstrumentId={paymentInstrumentId}
          selectedPaymentNeedsConfirmation={selectedPaymentNeedsConfirmation}
        />
      </div>
    );
  };

  const makeAddCreditCardForm = () => (
    <NewPaymentForm
      savePaymentRef={savePaymentRef}
      formItem={formItem}
      isOpen={ccivrOpen}
      onCloseCCIVR={onCloseCCIVR}
      onOpenCCIVR={onOpenCCIVR}
      onSubmitPayment={onSubmitPayment}
      paymentDataIsLoading={paymentDataIsLoading}
      purchaseDataIsLoading={purchaseDataIsLoading}
      showCCIVRPayment={showCCIVRPayment}
      hasSavedPayments={hasSavedPayments}
    />
  );

  const makeCreditCardListMode = () => (
    <form
      method="POST"
      className={css.creditCardList}
      onSubmit={onUseCreditCardClick}
      action="/marty/checkout/payment"
      data-test-id={testId('paymentListWrapper')}
    >
      {savedPayments.map((payment, rowIndex) => {
        const { fullName, isExpired, paymentInstrumentId, isSelected } = payment;

        const paymentId = `payment-${paymentInstrumentId}`;
        const isInvisible = seeAllCards ? false : rowIndex >= NUM_SAVED_CREDIT_CARDS_PER_PAGE;
        const isCreditCardSelected = selectedPaymentInstrumentId === paymentInstrumentId && !hasInactiveInstrument;

        if (fullName === 'Afterpay') {
          return null;
        }

        // If is selected, but not in the first page, make sure all items are showing
        if (isCreditCardSelected && isInvisible) setSeeAllCards(true);

        return (
          <div
            key={paymentId}
            data-test-id={testId('paymentRow')}
            className={cn(css.creditCardItem, {
              [css.invisible]: isInvisible
            })}
          >
            <input
              type="radio"
              name="paymentList"
              id={paymentId}
              disabled={isLoading}
              checked={isCreditCardSelected}
              data-payment-instrument-id={paymentInstrumentId}
              onChange={() => setSelectedPaymentInstrumentId(paymentInstrumentId)}
            />
            <label
              htmlFor={paymentId}
              data-test-id={testId('paymentOption')}
              className={cn(css.paymentOptionWrapper, { [css.selected]: isCreditCardSelected })}
            >
              <MultiLinePayment
                payment={payment}
                isLoading={isLoading}
                isSelected={isCreditCardSelected}
                onDelete={onDeletePayment}
                onEdit={onChangeBillingAddressClick}
              />
              {isSelected &&
                isCreditCardSelected &&
                !isExpired &&
                selectedPaymentNeedsConfirmation &&
                makeReValidateCardSection({ paymentInstrumentId, isInvisible })}
              {isSelected && isCreditCardSelected && isExpired && makeUpdateCardExpirationSection({ isInvisible })}
            </label>
          </div>
        );
      })}
      {showAllCardsButton && (
        <button type="button" onClick={setSeeAllCards} className={css.seeAllButton} data-test-id={testId('seeAllCards')}>
          See All Cards
        </button>
      )}
      {!showAddCreditCardForm && (!!savedPayments.length || !doesPurchaseRequireCC) && (
        <div className={css.btnsContainer}>
          <Link
            className={css.addNewButton}
            data-test-id={testId('addNewCard')}
            to={`${onAddNewCardLinkTo}&addCard=true`}
            onClick={onAddNewPaymentClick}
          >
            <UtilityStrokePlusCircleMediumIcon size={24} />
            Add New Card
          </Link>
        </div>
      )}
      <div ref={savePaymentRef} className={css.btnsContainer}>
        <SectionCancelInline showLink={!isLoading} to={links[REVIEW_STEP]} describedby="payment-section" />
        <button
          type="button"
          disabled={isLoading}
          className={css.saveButton}
          onClick={onUseCreditCardClick}
          data-payment-instrument-id={selectedPaymentInstrumentId}
          data-test-id={testId('saveAndContinue')}
        >
          Save & Continue
        </button>
      </div>
    </form>
  );

  return <div className={css.wrapper}>{showAddCreditCardForm ? makeAddCreditCardForm() : makeCreditCardListMode()}</div>;
};

export default PaymentAsList;
