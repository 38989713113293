import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { cn } from 'helpers/classnames';
import {
  CVVToolTip,
  getMaskedCreditCardNumber,
  getMelodyPaymentTypeIcon,
  getMelodyPaymentTypeIconForInput
} from 'components/checkout/CheckoutUtils';
import CCIVRPayment from 'components/checkout/payment/CCIVRPayment';
import { CreditCardMonthAndYearExpiration } from 'components/common/CreditCardExpirationField';
import { PAYMENT_FIELDS } from 'constants/formFields';
import { PAYMENT_STEP } from 'constants/checkoutFlow';
import { trackClientErrors } from 'helpers/CheckoutUtils';
import useMartyContext from 'hooks/useMartyContext';
import {
  createCVVErrorString,
  createExpirationErrorString,
  formatCVVBasedCCNumber,
  formatExpirationMMYY,
  getPaymentType
} from 'helpers/MyAccountUtils';
import { onToggleIsAlsoBilling } from 'store/ducks/address/actions';
import { onToggleIsPrimary } from 'store/ducks/checkout/actions';
import { CVVInfo } from 'components/checkout/CreditCards';
import { track } from 'apis/amethyst';
import { evCheckoutClientError } from 'events/checkout';
import { CHECKOUT_ERROR_FIELD } from 'constants/amethystEnums';
import SectionCancelInline from 'components/checkout/SectionCancelInline';
import SectionDivider from 'components/checkout/SectionDivider';
import { selectCheckoutLinks } from 'selectors/checkout';
import { inIframe } from 'helpers/InIframe';

import css from 'styles/components/checkout/payment/newPaymentForm.scss';

const AUTO_TAB_INPUT_NAMES = {
  expMonthYear: 'expirationMonthYear',
  cvv: 'cvv',
  button: 'addPayment'
};

const CC_AUTO_TAB_VALIDATION = {
  AmericanExpress: {
    type: 'AmericanExpress',
    length: 17
  },
  otherCards: {
    length: 19
  }
};

export const NewPaymentForm = params => {
  const {
    formItem,
    isOpen,
    onCloseCCIVR,
    onOpenCCIVR,
    onSubmitPayment,
    paymentDataIsLoading,
    purchaseDataIsLoading,
    showCCIVRPayment,
    ccType = '',
    hasSavedPayments,
    savePaymentRef
  } = params;

  const [creditCard, setCreditCard] = useState('');
  const [expirationMonth, setExpirationMonth] = useState('');
  const [expirationYear, setExpirationYear] = useState('');
  const [expirationMonthYear, setExpirationMonthYear] = useState('');
  const [expirationError, setExpirationError] = useState('');
  const [cardType, setCardType] = useState(ccType);
  const [cvv, setCvv] = useState('');
  const [cvvError, setCvvError] = useState('');
  const [wasVisited, setWasVisited] = useState({});
  const [name, setName] = useState('');
  const [ccSelectionStart, setCcSelectionStart] = useState('');
  const ccRef = useRef(null);
  const ccNameRef = useRef(null);
  const links = useSelector(selectCheckoutLinks);

  const dispatch = useDispatch();
  const [isPrimary, setIsPrimary] = useState(true);
  const isAlsoBilling = useSelector(state => state.address.isAlsoBilling);
  const isLoading = purchaseDataIsLoading || paymentDataIsLoading;

  const { testId } = useMartyContext();
  const { formErrors = {} } = formItem;
  const disableAutoComplete = inIframe();

  useEffect(() => {
    if (ccRef.current) {
      ccRef.current.setSelectionRange(ccSelectionStart, ccSelectionStart);

      if (creditCard.length > 15) {
        ccRef.current = null;
      }
    }
  }, [ccSelectionStart, creditCard]);

  useEffect(() => {
    if (isOpen && ccNameRef.current) {
      // Defaults focus to card name when form is opened
      ccNameRef.current.focus();
    }
  }, [isOpen, ccNameRef]);

  useEffect(() => {
    if (cvvError) {
      track(() => [evCheckoutClientError, { field: CHECKOUT_ERROR_FIELD.CVV, message: cvvError }]);
    }
  }, [cvvError]);

  useEffect(() => {
    if (expirationError) {
      track(() => [evCheckoutClientError, { field: CHECKOUT_ERROR_FIELD.EXPIRY_DATE, message: expirationError }]);
    }
  }, [expirationError]);

  useEffect(() => {
    trackClientErrors(formErrors);
  }, [formErrors]);

  const toggleIsAlsoBilling = () => {
    dispatch(onToggleIsAlsoBilling(!isAlsoBilling));
  };

  const toggleIsPrimary = () => {
    setIsPrimary(!isPrimary);
    dispatch(onToggleIsPrimary(!isPrimary));
  };

  const onSavingPayment = e => {
    e.preventDefault();
    const {
      currentTarget: { isAlsoBilling }
    } = e;

    // store `isAlsoBilling` in redux based on checked/unchecked box
    if (isAlsoBilling) {
      dispatch(onToggleIsAlsoBilling(!!isAlsoBilling.checked));
    }

    onSubmitPayment({
      isPrimary,
      expirationMonth,
      expirationYear,
      name,
      cc: creditCard,
      addCreditCardVerificationNumber: cvv
    });
  };

  const onChangeCreditCard = e => {
    const { name, value, form } = e.target;
    const inCC = value;
    const outCC = getMaskedCreditCardNumber(inCC);
    const type = getPaymentType(outCC) || '';
    setCreditCard(outCC);
    setCardType(type);
    setCcSelectionStart(e.target.selectionStart + outCC.length - inCC.length);

    if (wasVisited[name]) {
      // prevent auto tab if input was visited
      return;
    } else if (inCC.length === CC_AUTO_TAB_VALIDATION.otherCards.length) {
      form.elements[AUTO_TAB_INPUT_NAMES.expMonthYear].focus();
    } else if (type === CC_AUTO_TAB_VALIDATION.AmericanExpress.type && inCC.length === CC_AUTO_TAB_VALIDATION.AmericanExpress.length) {
      form.elements[AUTO_TAB_INPUT_NAMES.expMonthYear].focus();
    }
  };

  const onBlurCreditCard = e => {
    const { name, value } = e.target;

    // keep track of input being visited only if there's a value
    if (value.length > 0) {
      setWasVisited({ ...wasVisited, [name]: true });
    }
  };

  const onChangeExpirationMonthYear = e => {
    const { name, value, form } = e.target;
    const { builtString, month, year } = formatExpirationMMYY(value);
    setExpirationMonth(month);
    setExpirationYear(year);
    setExpirationMonthYear(builtString);

    if (expirationError) {
      setExpirationError('');
    }

    if (wasVisited[name]) {
      // prevent auto tab if input was visited
      return;
    } else if (year.length === 4) {
      form.elements[AUTO_TAB_INPUT_NAMES.cvv].focus();
    }
  };

  const onBlurExpirationMonthYear = e => {
    const { name, value } = e.target;
    const { month, year } = formatExpirationMMYY(value);
    setExpirationError(createExpirationErrorString(month, year));

    // keep track of input being visited only if there's a value
    if (value.length > 0) {
      setWasVisited({ ...wasVisited, [name]: true });
    }
  };

  const onChangeCVV = e => {
    const { form, name, value } = e.target;
    setCvv(formatCVVBasedCCNumber(value, cardType));

    if (cvvError) {
      // clearing out the error set by onBlurCVV when user types
      setCvvError('');
    }

    const shouldAutoTab =
      (cardType === 'AmericanExpress' && value.length === 4) ||
      (cardType !== '' && cardType !== 'AmericanExpress' && value.length === 3) ||
      value.length === 4;

    if (wasVisited[name] === true) {
      // prevent auto tab if input was visited
      return;
    } else if (shouldAutoTab) {
      form.elements[AUTO_TAB_INPUT_NAMES.button].focus();
    }
  };

  const onBlurCVV = e => {
    const { name, value } = e.target;
    const cvv = formatCVVBasedCCNumber(value, cardType);
    setCvvError(createCVVErrorString(cvv));

    // keep track of input being visited only if there's a value
    if (value.length > 0) {
      setWasVisited({ ...wasVisited, [name]: true });
    }
  };

  const isErrorField = field => !!formItem.formErrors?.[field];

  if (showCCIVRPayment && isOpen) {
    return <CCIVRPayment onCloseModalOverlayClick={onCloseCCIVR} />;
  }

  if (showCCIVRPayment) {
    return (
      <div className={css.footer}>
        <button className={css.addPaymentBtn} onClick={onOpenCCIVR} type="button">
          Add Credit Card
        </button>
      </div>
    );
  }

  return (
    <div className={cn(css.wrapper, { [css.loading]: isLoading })} data-test-id={testId('paymentForm')}>
      <input checked type="radio" id="newCreditCard" name="newCreditCard" onChange={() => {}} />
      <label htmlFor="newCreditCard" className={css.header}>
        {getMelodyPaymentTypeIcon(null, true)}
        <span className={css.headerTitle}>Credit Card</span>
      </label>
      <span className="text-xs">* All fields are required unless noted</span>
      <form method="POST" className={css.formWrapper} action="/checkout/payment/add" data-test-id={testId('paymentForm')} onSubmit={onSavingPayment}>
        <div className={css.fieldWrapper}>
          <div
            className={cn(css.formField, {
              [css.fieldError]: isErrorField(PAYMENT_FIELDS.NAME_ON_CARD.fieldName)
            })}
          >
            <label htmlFor={PAYMENT_FIELDS.NAME_ON_CARD.fieldName}>Name on Card</label>
            <input
              ref={ccNameRef}
              aria-label="Name on card"
              placeholder="First and Last"
              autoComplete={disableAutoComplete ? 'new-password' : PAYMENT_FIELDS.NAME_ON_CARD.autoComplete}
              autoCorrect="off"
              maxLength={PAYMENT_FIELDS.NAME_ON_CARD.maxLength}
              onChange={e => setName(e.target.value)}
              value={name || ''}
              id={PAYMENT_FIELDS.NAME_ON_CARD.fieldName}
              name={PAYMENT_FIELDS.NAME_ON_CARD.fieldName}
              data-test-id={testId('nameOnCard')}
              required={true}
            />
            {isErrorField(PAYMENT_FIELDS.NAME_ON_CARD.fieldName) && <div>{formErrors[PAYMENT_FIELDS.NAME_ON_CARD.fieldName]}</div>}
          </div>
        </div>

        <div className={css.fieldWrapper}>
          <div
            className={cn(css.formField, {
              [css.fieldError]: isErrorField(PAYMENT_FIELDS.CC.fieldName)
            })}
          >
            <label htmlFor={PAYMENT_FIELDS.CC.fieldName}>Card Number</label>
            <div className={css.cardField}>
              <input
                aria-label="Card number"
                placeholder="Enter card number"
                autoComplete={disableAutoComplete ? 'new-password' : PAYMENT_FIELDS.CC.autoComplete}
                type="tel"
                inputMode="numeric"
                pattern="[0-9 ]*"
                autoCorrect="off"
                maxLength={PAYMENT_FIELDS.CC.maxLength}
                onChange={onChangeCreditCard}
                onBlur={onBlurCreditCard}
                value={creditCard || ''}
                id={PAYMENT_FIELDS.CC.fieldName}
                name={PAYMENT_FIELDS.CC.fieldName}
                data-test-id={testId('cardNumber')}
                required={true}
                ref={ccRef}
                spellCheck="false"
              />
              {getMelodyPaymentTypeIconForInput({ cc: creditCard })}
            </div>
            {isErrorField(PAYMENT_FIELDS.CC.fieldName) && <div data-test-id={testId('cardError')}>{formErrors[PAYMENT_FIELDS.CC.fieldName]}</div>}
          </div>
        </div>

        <div className={css.expRow}>
          <div className={css.fieldWrapper}>
            <div
              className={cn(css.formField, {
                [css.fieldError]: isErrorField(PAYMENT_FIELDS.CC_EXPIRATION_MELODY.fieldName) || expirationError
              })}
            >
              <label htmlFor={PAYMENT_FIELDS.CC_EXPIRATION_MONTH_YEAR.fieldName}>Expiry Date</label>
              <CreditCardMonthAndYearExpiration
                aria-label="Expiration Month and Year"
                maxLength={PAYMENT_FIELDS.CC_EXPIRATION_MONTH_YEAR.maxLength}
                autoCorrect="off"
                autoComplete="on"
                onChange={onChangeExpirationMonthYear}
                value={expirationMonthYear || ''}
                name={PAYMENT_FIELDS.CC_EXPIRATION_MONTH_YEAR.fieldName}
                id={PAYMENT_FIELDS.CC_EXPIRATION_MONTH_YEAR.fieldName}
                onBlur={onBlurExpirationMonthYear}
              />
              {isErrorField(PAYMENT_FIELDS.CC_EXPIRATION_MELODY.fieldName) ? (
                <div>{formErrors[PAYMENT_FIELDS.CC_EXPIRATION_MELODY.fieldName]}</div>
              ) : expirationError ? (
                <div>{expirationError}</div>
              ) : null}
            </div>
          </div>

          <div className={css.fieldWrapper}>
            <div
              className={cn(css.formField, {
                [css.fieldError]: isErrorField(PAYMENT_FIELDS.CC_CVV.fieldName) || cvvError
              })}
            >
              <div className={css.cvvField}>
                <label htmlFor={PAYMENT_FIELDS.CC_CVV.fieldName}>CVV</label>
                <input
                  aria-label="CVV"
                  placeholder="CVV"
                  type="text"
                  inputMode="numeric"
                  maxLength={PAYMENT_FIELDS.CC_CVV.maxLength}
                  pattern="[0-9]*"
                  autoCorrect="off"
                  autoComplete="on"
                  onChange={onChangeCVV}
                  onInput={onChangeCVV}
                  value={cvv || ''}
                  id={testId(PAYMENT_FIELDS.CC_CVV.fieldName)}
                  name={PAYMENT_FIELDS.CC_CVV.fieldName}
                  data-test-id={testId('cvv')}
                  onBlur={onBlurCVV}
                />
                <div className={css.infoIcon}>
                  <CVVInfo />
                </div>
                <div className={css.toolTip}>
                  <CVVToolTip paymentType={cardType} />
                </div>
              </div>
              {isErrorField(PAYMENT_FIELDS.CC_CVV.fieldName) ? (
                <div>{formErrors[PAYMENT_FIELDS.CC_CVV.fieldName]}</div>
              ) : cvvError ? (
                <div>{cvvError}</div>
              ) : null}
            </div>
          </div>
        </div>

        {hasSavedPayments && (
          <div className={css.fieldWrapper}>
            <div className={cn(css.formField, { [css.fieldError]: false })}>
              <input
                type="checkbox"
                name="isPrimary"
                data-test-id={testId('makeNewPaymentPrimary')}
                id="isPrimary"
                defaultChecked={isPrimary}
                onChange={toggleIsPrimary}
              />
              <label htmlFor="isPrimary">Use as my default payment</label>
            </div>
          </div>
        )}

        <SectionDivider />
        <h3 className={css.billingTitle}>Billing Address</h3>

        <div className={css.fieldWrapper}>
          <div className={cn(css.formField, { [css.fieldError]: false })}>
            <input
              type="checkbox"
              name="isAlsoBilling"
              data-test-id={testId('isAlsoBilling')}
              id="isAlsoBilling"
              defaultChecked={true}
              onChange={toggleIsAlsoBilling}
            />
            <label htmlFor="isAlsoBilling" data-test-id={testId('isAlsoBillingLabel')}>
              Same as shipping
            </label>
          </div>
        </div>

        <div ref={savePaymentRef} className={css.footer}>
          {hasSavedPayments && <SectionCancelInline showLink={!isLoading} to={links[PAYMENT_STEP]} />}
          <button name="addPayment" type="submit" disabled={isLoading} className={css.saveButton} data-test-id={testId('savePaymentBtn')}>
            Save & Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewPaymentForm;
