// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { MediumIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeEditMediumIcon = (props: MediumIconProps) => (
  <Icon {...props} viewBox="0 0 32 32">
    <path
      d="M29.0879 3.96249C30.304 5.17868 30.304 7.11352 29.0879 8.32971L27.4294 9.98815L22.0118 4.57058L23.6703 2.91214C24.8865 1.69595 26.8213 1.69595 28.0375 2.91214L29.0879 3.96249ZM11.5084 15.074L20.7404 5.84205L26.1579 11.2596L16.926 20.4916C16.5943 20.8233 16.152 21.0997 15.7098 21.2655L10.7897 22.8687C10.2922 23.0345 9.79467 22.924 9.46298 22.537C9.07601 22.2053 8.96545 21.6525 9.13129 21.2103L10.7345 16.2902C10.9003 15.848 11.1767 15.4057 11.5084 15.074ZM12.614 5.23396C13.5538 5.23396 14.383 6.06318 14.383 7.00296C14.383 7.99803 13.5538 8.77196 12.614 8.77196H7.30701C6.31194 8.77196 5.53801 9.60118 5.53801 10.541V24.693C5.53801 25.6881 6.31194 26.462 7.30701 26.462H21.459C22.3988 26.462 23.228 25.6881 23.228 24.693V19.386C23.228 18.4462 24.002 17.617 24.997 17.617C25.9368 17.617 26.766 18.4462 26.766 19.386V24.693C26.766 27.6229 24.3889 30 21.459 30H7.30701C4.32182 30 2 27.6229 2 24.693V10.541C2 7.61106 4.32182 5.23396 7.30701 5.23396H12.614Z"
      fill="currentcolor"
    />
  </Icon>
);

export default UtilityStrokeEditMediumIcon;
