import { useEffect, useRef, useState } from 'react';

import { AFTER_PAY, AMAZON_PAY, CREDIT_CARD, PAYPAL } from 'constants/paymentMethodTypes';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/checkout/payment/paymentAsList/paymentMethodChooser.scss';

export const PaymentMethodChooser = params => {
  const [tabs, setTabs] = useState([]);
  const tabFocusRef = useRef(0);

  const { activePaymentMethodType, isAfterPayLoaded, isAmazonPayLoaded, isHydraHidePayPal, isClt, onChangePaymentMethodType } = params;

  const {
    testId,
    marketplace: {
      checkout: { allowAfterPay, allowAmazonPay, allowPayPal }
    }
  } = useMartyContext();

  useEffect(() => {
    setTabs(document.querySelectorAll('[role="tab"]'));
  }, []);

  const tabEventHandler = ({ key }) => {
    switch (key) {
      case 'ArrowRight':
        tabs[tabFocusRef.current].tabIndex = -1;
        tabFocusRef.current++;
        if (tabFocusRef.current >= tabs.length) {
          tabFocusRef.current = 0;
        }
        break;
      case 'ArrowLeft':
        tabs[tabFocusRef.current].tabIndex = -1;
        tabFocusRef.current--;
        if (tabFocusRef.current < 0) {
          tabFocusRef.current = tabs.length - 1;
        }
        break;
      case 'End':
        tabs[tabFocusRef.current].tabIndex = -1;
        tabFocusRef.current = tabs.length - 1;
        break;
      case 'Home':
        tabs[tabFocusRef.current].tabIndex = -1;
        tabFocusRef.current = 0;
        break;
      default:
        break;
    }

    tabs[tabFocusRef.current].tabIndex = 0;
    tabs[tabFocusRef.current].focus();
  };

  const isCreditCardSelected = activePaymentMethodType === CREDIT_CARD;
  const isAmazonPaySelected = activePaymentMethodType === AMAZON_PAY;
  const isAfterPaySelected = activePaymentMethodType === AFTER_PAY;
  const isPayPalSelected = activePaymentMethodType === PAYPAL;

  if (!allowAfterPay && !allowAmazonPay && !allowPayPal) {
    return null;
  }

  return (
    <div className={css.tabs}>
      <div role="tablist" className={css.tablist} aria-label="Payment Methods" data-test-id={testId('paymentMethods')}>
        <button
          aria-controls="credit-card-content"
          aria-selected={isCreditCardSelected}
          className={css.tablistItem}
          data-payment-method-type={CREDIT_CARD}
          data-test-id={testId('creditCard')}
          id="credit-card-tab"
          onClick={onChangePaymentMethodType}
          onKeyDown={tabEventHandler}
          role="tab"
          tabIndex={isCreditCardSelected ? '0' : '-1'}
          type="button"
        >
          Credit/Debit Card
        </button>

        {isAmazonPayLoaded && !isClt && (
          <button
            aria-controls="amazon-pay-content"
            aria-selected={isAmazonPaySelected}
            className={css.tablistItem}
            data-payment-method-type={AMAZON_PAY}
            data-test-id={testId('amazonPay')}
            id="amazon-pay-tab"
            onClick={onChangePaymentMethodType}
            onKeyDown={tabEventHandler}
            role="tab"
            tabIndex={isAmazonPaySelected ? '0' : '-1'}
            type="button"
          >
            Amazon Pay
          </button>
        )}

        {!isClt && !isHydraHidePayPal && (
          <button
            aria-controls="pay-pal-content"
            aria-selected={isPayPalSelected}
            className={css.tablistItem}
            data-payment-method-type={PAYPAL}
            data-test-id={testId('payPal')}
            id="pay-pal-tab"
            onClick={onChangePaymentMethodType}
            onKeyDown={tabEventHandler}
            role="tab"
            tabIndex={isPayPalSelected ? '0' : '-1'}
            type="button"
          >
            PayPal
          </button>
        )}

        {isAfterPayLoaded && !isClt && (
          <button
            aria-controls="afterpay-content"
            aria-selected={isAfterPaySelected}
            className={css.tablistItem}
            data-payment-method-type={AFTER_PAY}
            data-test-id={testId('afterPay')}
            id="afterpay-tab"
            onClick={onChangePaymentMethodType}
            onKeyDown={tabEventHandler}
            role="tab"
            tabIndex={isAfterPaySelected ? '0' : '-1'}
            type="button"
          >
            Afterpay
          </button>
        )}
      </div>
    </div>
  );
};

export default PaymentMethodChooser;
