import React from 'react';
import PropTypes from 'prop-types';

import MartyLink from 'components/common/MartyLink';
import {
  VIP_SELECTED_COPY,
  VIP_SELECTED_LABEL,
  VIP_UNSELECTED_COPY,
  VIP_UNSELECTED_LABEL,
  VIP_WITH_EMAILS_SELECTED_COPY,
  VIP_WITH_EMAILS_SELECTED_LABEL,
  VIP_WITH_EMAILS_UNSELECTED_COPY,
  VIP_WITH_EMAILS_UNSELECTED_LABEL
} from 'constants/vipEnrollment';

import css from 'styles/components/checkout/vipEnrollment.scss';

export const VipEnrollment = (
  {
    isVipEnrollmentSelected,
    includeVipEmailSubscriptions,
    onVipEnrollmentCheckboxClick,
    onVipEnrollmentTermsClick,
    isVipCheckboxTogglingDisabled = false,
    isMobileView = false
  },
  { testId }
) => {
  const onChange = e => {
    e.preventDefault();
    const {
      target: { checked: isSelected }
    } = e;
    onVipEnrollmentCheckboxClick(isSelected);
  };

  return (
    <div className={isMobileView ? css.vipEnrollmentBoxMobileWrapper : css.vipEnrollmentBoxWrapper}>
      <div className={css.selectionBlock} data-test-id={'vipEnrollment'}>
        <input
          type="checkbox"
          id="vipEnroll"
          name="vipEnroll"
          checked={isVipEnrollmentSelected}
          disabled={isVipCheckboxTogglingDisabled}
          onChange={onChange}
          data-test-id={testId('vipCheckbox')}
        />
        <label htmlFor="vipEnroll" data-test-id={testId('vipCheckboxLabel')}>
          {isVipEnrollmentSelected
            ? includeVipEmailSubscriptions
              ? VIP_WITH_EMAILS_SELECTED_LABEL
              : VIP_SELECTED_LABEL
            : includeVipEmailSubscriptions
            ? VIP_WITH_EMAILS_UNSELECTED_LABEL
            : VIP_UNSELECTED_LABEL}
        </label>
        <div id="vipDetails" className={css.contentCopy}>
          <p>
            {isVipEnrollmentSelected
              ? includeVipEmailSubscriptions
                ? VIP_WITH_EMAILS_SELECTED_COPY
                : VIP_SELECTED_COPY
              : includeVipEmailSubscriptions
              ? VIP_WITH_EMAILS_UNSELECTED_COPY
              : VIP_UNSELECTED_COPY}
          </p>
          <p>
            I agree to the{' '}
            <MartyLink to="/c/vip-terms-and-conditions" target="_blank" onClick={onVipEnrollmentTermsClick}>
              Terms & Conditions
            </MartyLink>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

VipEnrollment.contextTypes = {
  testId: PropTypes.func
};

export default VipEnrollment;
