// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { SmallIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeInfoFilledSmallIcon = (props: SmallIconProps) => (
  <Icon {...props} viewBox="0 0 16 16">
    <path
      d="M14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8ZM8.00016 6.49992C8.6445 6.49992 9.16683 5.97758 9.16683 5.33325C9.16683 4.68892 8.6445 4.16659 8.00016 4.16659C7.35583 4.16659 6.8335 4.68892 6.8335 5.33325C6.8335 5.97758 7.35583 6.49992 8.00016 6.49992ZM9.00016 7.99992C9.00016 7.44763 8.55245 6.99992 8.00016 6.99992C7.44788 6.99992 7.00016 7.44763 7.00016 7.99992V10.9999C7.00016 11.5522 7.44788 11.9999 8.00016 11.9999C8.55245 11.9999 9.00016 11.5522 9.00016 10.9999V7.99992Z"
      fill="currentcolor"
      stroke="currentcolor"
    />
  </Icon>
);

export default UtilityStrokeInfoFilledSmallIcon;
