import { generatePayPalRedirect } from 'apis/checkout';
import { setSessionCookies } from 'actions/session';
import { processHeadersMiddleware } from 'middleware/processHeadersMiddlewareFactory';
import { fetchApiNotAuthenticatedMiddleware, fetchErrorMiddleware } from 'middleware/fetchErrorMiddleware';
import { onPaypalGenerateRedirect } from 'store/ducks/checkout/actions';
import { selectMafiaConfig } from 'selectors/environment';

export function performPayPalRedirect() {
  return (dispatch, getState) => {
    dispatch(onPaypalGenerateRedirect());

    const state = getState();
    const { cookies, checkoutData, sharedPayment } = state;
    const mafiaConfig = selectMafiaConfig(state);

    const {
      purchase: { purchaseId },
      usePromoBalance
    } = checkoutData;
    const { payPalPaymentMethod } = sharedPayment;
    const { paymentInstrumentId: payPalPaymentInstrumentId } = payPalPaymentMethod || {};

    const paymentMethods = [
      {
        paymentInstrumentId: payPalPaymentInstrumentId,
        paymentMethodCode: 'PayStation'
      }
    ];

    const reqData = {
      authenticationSourceId: null,
      confirmRedirect: false,
      confirmRedirectParameters: null,
      generateRedirect: true,
      paymentMethods,
      purchaseId,
      useDiscount: usePromoBalance,
      useGCBalance: usePromoBalance
    };

    return generatePayPalRedirect(mafiaConfig, { reqData, includeAllPaymentTypes: true, includeAssociated: false }, cookies)
      .then(processHeadersMiddleware(setSessionCookies(dispatch, getState)))
      .then(fetchApiNotAuthenticatedMiddleware)
      .then(fetchErrorMiddleware);
  };
}
