import { cn } from 'helpers/classnames';
import { getEGCDeliveryText, getShipOptionPromise } from 'helpers/CheckoutUtils';
import { parseTextToReact } from 'helpers/parseToReact';
import { toUSD } from 'helpers/NumberFormats';
import useMartyContext from 'hooks/useMartyContext';
import { BUSINESS_UNKNOWN_NON_PERK_TEXT, BUSINESS_UNKNOWN_NON_PERK_TEXT_WHEN_SELECTED, EGC_PRICE_LINE_TEXT } from 'constants/shipOptionMessaging';

import css from 'styles/components/checkout/splitReview/shippingOptionGroupWithDates.scss';

export const ShippingOptionGroupWithDates = params => {
  const {
    deliveryOptions = [],
    isForEGC,
    onSelectAndUseShippingOptionClick,
    selectedShipOptionId,
    shipmentNumber,
    numShipments,
    isHydraShippingRangeDates,
    egcDeliveryDate,
    isLoading
  } = params;
  const { testId } = useMartyContext();

  if (isForEGC) {
    return (
      <fieldset className={css.egcWrapper}>
        <legend className="p-0 text-base font-semibold">Electronic Delivery</legend>
        <div>{getEGCDeliveryText(egcDeliveryDate)}</div>
        <div className={cn(css.item, css.egc)} key={'egc'}>
          <input
            checked={true}
            data-shipping-promise={'promise'}
            data-shipment-speed={'shipmentSpeed'}
            data-shipment-option-id={'shippingOptionId'}
            onChange={onSelectAndUseShippingOptionClick}
            type="radio"
            id={'shipOption-egc'}
            name="shippingOptionListEgc"
            data-test-id={testId('shipOptionInput')}
            disabled={isLoading}
          />
          <label className={cn(css.optionLabel, css.selected)} htmlFor={`shipOption-${'egc'}`} data-test-id={testId('innerShipOptionLabel')}>
            <span className={css.optionLabelText}>
              <span data-test-id={testId('shipOptionPromise')}>Email</span>
              <span className={css.labelSubText} data-test-id={testId('shipOptionDescription')}>
                {EGC_PRICE_LINE_TEXT}
              </span>
            </span>
          </label>
        </div>
      </fieldset>
    );
  }

  if (!deliveryOptions.length) {
    return (
      <div className={css.noOptions}>Sorry, no shipping options are available at this time. Please select or add a different shipping address.</div>
    );
  }

  return (
    <form method="post" action="/selectShipOption">
      <fieldset className={css.wrapper} data-test-id="shippingOptionListSection">
        {numShipments > 1 && (
          <legend className={css.deliveryLine}>
            <span>
              Shipment {shipmentNumber} of {numShipments}
            </span>
          </legend>
        )}
        {deliveryOptions
          .filter(({ price, isFiltered }) => (price === 0 || price) && !isFiltered)
          .map(
            ({
              deliveryDuration: { displayString: deliveryDurationDisplayString },
              deliveryPromise: { displayString, isBusinessUnknown },
              description,
              name,
              id,
              promise,
              price
            }) => {
              const isSelected = id === selectedShipOptionId || selectedShipOptionId === name;
              const inputId = `shipOption-${id}`;
              return (
                <div className={css.item} key={id}>
                  <input
                    checked={isSelected}
                    data-shipping-promise={promise || deliveryDurationDisplayString}
                    data-shipment-speed={name}
                    data-shipment-option-id={id}
                    onChange={onSelectAndUseShippingOptionClick}
                    type="radio"
                    id={inputId}
                    name="shippingOptionList"
                    data-test-id={testId('shipOptionInput')}
                    disabled={isLoading}
                  />
                  <label
                    className={cn(css.optionLabel, { [css.selected]: isSelected })}
                    htmlFor={inputId}
                    data-test-id={testId('innerShipOptionLabel')}
                  >
                    <span className={css.optionLabelText}>
                      {!!displayString && (
                        <span className={css.optionPromise} data-test-id={testId('shipOptionPromise')}>
                          {getShipOptionPromise({ isHydraShippingRangeDates, displayString, isBusinessUnknown })}
                        </span>
                      )}
                      {!displayString && isSelected && BUSINESS_UNKNOWN_NON_PERK_TEXT_WHEN_SELECTED}
                      {!displayString && !isSelected && BUSINESS_UNKNOWN_NON_PERK_TEXT}
                      {!!description && (
                        <span className={css.labelSubText} data-test-id={testId('shipOptionDescription')}>
                          {parseTextToReact(description)}
                        </span>
                      )}
                    </span>
                    {!price ? (
                      <span className={css.shippingPrice} data-test-id={testId('shipOptionPrice')}>
                        FREE
                      </span>
                    ) : (
                      <span className={css.shippingPrice} data-test-id={testId('shipOptionPrice')}>
                        {toUSD(price)}
                      </span>
                    )}
                  </label>
                </div>
              );
            }
          )}
      </fieldset>
    </form>
  );
};

export default ShippingOptionGroupWithDates;
