import { ADDRESS_FIELDS } from 'constants/formFields';
import { ADDRESS_MESSAGES } from 'constants/errorMessages';

interface AddressFields {
  addressLine1: string;
  addressLine2: string;
  city: string;
  countryCode: string;
  fullName: string;
  postalCode: string;
  primaryVoiceNumber: string;
  stateOrRegion: string;
}

type FormErrors = Record<string, string>;

export const validateRequiredAddressFields = (
  formErrors: FormErrors,
  { addressLine1, city, countryCode, fullName, postalCode, primaryVoiceNumber, stateOrRegion }: AddressFields
) => {
  const requiredFields = [
    [ADDRESS_FIELDS.ADDRESS_LINE_1.fieldName, addressLine1],
    [ADDRESS_FIELDS.CITY.fieldName, city],
    [ADDRESS_FIELDS.COUNTRY_CODE.fieldName, countryCode],
    [ADDRESS_FIELDS.FULL_NAME.fieldName, fullName],
    [ADDRESS_FIELDS.PHONE_NUMBER.fieldName, primaryVoiceNumber],
    [ADDRESS_FIELDS.POSTAL_CODE.fieldName, postalCode],
    [ADDRESS_FIELDS.STATE_OR_REGION.fieldName, stateOrRegion]
  ] as const;

  // Return exising errors overridden with errors for missing form fields
  return {
    ...formErrors,
    ...Object.fromEntries(
      requiredFields.filter(field => String(field[1]).trim() === '').map(([fieldName]) => [fieldName, ADDRESS_MESSAGES.missing_value[fieldName]])
    )
  };
};
