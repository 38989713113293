import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { onUseAsDefaultsLoad, onUseAsDefaultsToggle } from 'store/ducks/checkout/actions';
import SectionDivider from 'components/checkout/SectionDivider';

import css from 'styles/containers/checkout/useAsDefaults.scss';

export class UseAsDefaults extends Component {
  componentDidMount = () => {
    const { onUseAsDefaultsLoad } = this.props;
    if (this.shouldShowSelection()) {
      onUseAsDefaultsLoad(false);
    }
  };

  onToggleUseAsDefaults = () => {
    const {
      checkoutData: { useAsDefaults },
      onUseAsDefaultsToggle
    } = this.props;
    onUseAsDefaultsToggle(!useAsDefaults);
  };

  shouldShowSelection = () => {
    const {
      checkoutData: {
        purchaseCreditCard,
        purchase: { shippingAddress }
      }
    } = this.props;
    const isPrimaryAddress = !!shippingAddress?.defaultShippingAddress;
    const isPrimaryPayment = !!purchaseCreditCard?.primary;
    return purchaseCreditCard && !(isPrimaryAddress && isPrimaryPayment);
  };

  render() {
    const { checkoutData } = this.props;
    const { isLoading, useAsDefaults } = checkoutData;
    const { testId = f => f } = this.context;

    return (
      this.shouldShowSelection() && (
        <>
          <SectionDivider />
          <dl className={css.section}>
            <dt className={css.sectionTitle}>Default Settings</dt>
            <dd className={css.fieldWrapper}>
              <input
                disabled={isLoading}
                type="checkbox"
                name="useAsDefault"
                data-test-id={testId('useAsDefault')}
                id="useAsDefault"
                checked={useAsDefaults}
                onChange={this.onToggleUseAsDefaults}
                className={css.formField}
              />
              <label htmlFor="useAsDefault" className={css.formLabel}>
                Check this box to default to these delivery and payment options in the future.
              </label>
            </dd>
          </dl>
        </>
      )
    );
  }
}

const mapStateToProps = ({ checkoutData }) => ({ checkoutData });

UseAsDefaults.contextTypes = {
  testId: PropTypes.func
};

export default connect(mapStateToProps, {
  onUseAsDefaultsLoad,
  onUseAsDefaultsToggle
})(UseAsDefaults);
