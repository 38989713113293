import MultiLinePayment from 'components/checkout/payment/MultiLinePayment';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/checkout/payment/payment.scss';

const Payment = props => {
  const {
    testId,
    marketplace: { dataMaskPiiElements }
  } = useMartyContext();
  const { hasPurchaseGiftCard, hasPurchasePromoCode, isDiscountFullBalance, isPaymentAsListCheckout, chargeSummary, payment } = props;

  const hasPayment = !!payment;

  if (isPaymentAsListCheckout && isDiscountFullBalance && chargeSummary.grandTotal === 0) {
    return <p>Your gift card or promotional balance covers the entire purchase!</p>;
  }

  if (!hasPayment && !hasPurchaseGiftCard && !hasPurchasePromoCode) {
    return <p>Please add or select a shipping address to proceed.</p>;
  }

  return (
    <div className={css.wrapper} data-test-id={testId('paymentSection')} data-cs-mask={dataMaskPiiElements}>
      {hasPayment && <MultiLinePayment hideDefault payment={payment} />}
      {!isPaymentAsListCheckout && !!hasPurchasePromoCode && <div>Promotional Code</div>}
      {!isPaymentAsListCheckout && !!hasPurchaseGiftCard && <div>Gift Card</div>}
    </div>
  );
};

export default Payment;
