import { CHECKOUT_STEP_MAP, CHECKOUT_URL_MAP, UNKNOWN_STEP } from 'constants/checkoutFlow';
import { NON_MARTY_URL } from 'common/regex';
import marketplace from 'cfg/marketplace.json';

const {
  checkout: { checkoutUrl }
} = marketplace;

export const getCleanPath = (path: string): string => path.replace(NON_MARTY_URL, '$1');

export function getStepFromPath(path: string) {
  const cleanPath = getCleanPath(path);
  return CHECKOUT_URL_MAP[cleanPath] || UNKNOWN_STEP;
}

export function getPathFromStep(step: keyof typeof CHECKOUT_STEP_MAP) {
  return CHECKOUT_STEP_MAP[step] || checkoutUrl;
}
