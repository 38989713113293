import { useEffect } from 'react';

import Payment from 'components/checkout/payment/Payment';
import SectionChangeInline from 'components/checkout/SectionChangeInline';
import { changeAmazonPayPayment } from 'helpers/AmazonPayUtils';
import ReEnterCardText from 'components/checkout/payment/ReEnterCardText';
import ReEnterCardForm from 'components/checkout/payment/ReEnterCardForm';

import css from 'styles/components/checkout/payment/paymentAsList/amazonPayPaymentReview.scss';

export const AmazonPayPaymentReview = params => {
  const {
    amazonPay = {},
    changePaymentLinkTo,
    formItem,
    hasVerifyCreditCardError,
    onChangeAmazonPaymentClick,
    onVerifyCardClick,
    paymentInstrumentId,
    purchaseCreditCard,
    purchaseDataIsLoading,
    selectedPaymentNeedsConfirmation
  } = params;
  const { amazonCheckoutSessionId } = amazonPay;

  useEffect(() => {
    changeAmazonPayPayment('changeAmazonPayPaymentSelection', amazonCheckoutSessionId);
  }, [amazonCheckoutSessionId]);

  return (
    <>
      <div className={css.paymentSection} id="amazon-pay-content" role="tabpanel" aria-labelledby="amazon-pay-tab">
        <div>
          <h3 className={css.usingAmazonPayMsg}>Using your selected Amazon payment method:</h3>
          <Payment hasPurchaseGiftCard={false} hasPurchasePromoCode={false} payment={purchaseCreditCard} />
        </div>

        <div className={css.sectionButtons}>
          <SectionChangeInline
            describedby="payment-method"
            disabled={purchaseDataIsLoading}
            id="changeAmazonPayPaymentSelection"
            label="Update"
            onClickEvent={onChangeAmazonPaymentClick}
            showLink={true}
            to={changePaymentLinkTo}
          />
        </div>
      </div>

      {selectedPaymentNeedsConfirmation && (
        <div className={css.reValidateCardSection}>
          <ReEnterCardText isChecked={true} isExpired={false} selectedPaymentNeedsConfirmation={selectedPaymentNeedsConfirmation} />
          <ReEnterCardForm
            formItem={formItem}
            hasVerifyCreditCardError={hasVerifyCreditCardError}
            isChecked={true}
            isExpired={false}
            isLoading={purchaseDataIsLoading}
            onVerifyCardClick={onVerifyCardClick}
            paymentInstrumentId={paymentInstrumentId}
            selectedPaymentNeedsConfirmation={selectedPaymentNeedsConfirmation}
          />
        </div>
      )}
    </>
  );
};

export default AmazonPayPaymentReview;
