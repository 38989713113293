import React from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';

import { performPayPalRedirect } from 'actions/checkout/paypal';
import { onPaypalButtonClick, onPaypalError, onReceivePayPalAuthenticationSource } from 'store/ducks/checkout/actions';
import { buildCheckoutErrorQueryString } from 'helpers/CheckoutUtils';
import { recordToSplunk } from 'apis/recordToSplunk';

import css from 'styles/components/checkout/payment/paymentAsList/thirdPartyAsPlaceOrder.scss';

export const PayPalAsPlaceOrder = props => {
  const { isChargeSummary, isOrderTotal, isExpressCheckout, onConfirmPayPal } = props;

  const PayPalButton = window?.paypal?.Buttons.driver('react', {
    React,
    ReactDOM
  });
  const dispatch = useDispatch();

  if (!PayPalButton) {
    return null;
  }

  const onGeneratePayPalRedirect = () => dispatch(performPayPalRedirect());

  const createOrder = () =>
    onGeneratePayPalRedirect()
      .then(response => {
        const {
          purchaseStatus: { payPal }
        } = response;
        const {
          authenticationSource: { authenticationSourceId },
          token
        } = payPal;
        dispatch(onReceivePayPalAuthenticationSource(authenticationSourceId));
        return Promise.resolve(token);
      })
      .catch(e => {
        const isObject = typeof e === 'object';
        const toStringError = isObject && e.toString();
        const stringifyError = isObject && JSON.stringify(e);
        recordToSplunk(
          buildCheckoutErrorQueryString({
            category: 'paypal',
            toStringError,
            stringifyError
          })
        );
        alert('An error has occurred.  Please try again or try using a different payment method.');
      });

  const onApprove = data => {
    onConfirmPayPal(data);
  };

  const onClick = () => {
    dispatch(onPaypalButtonClick({ isExpressCheckout, isChargeSummary, isOrderTotal }));
  };

  const onError = () => {
    dispatch(onPaypalError());
  };

  return (
    <div className={css.thirdPartyBtnWrapper}>
      <PayPalButton
        createOrder={(data, actions) => createOrder(data, actions)}
        onApprove={(data, actions) => onApprove(data, actions)}
        onClick={onClick}
        onError={onError}
      />
    </div>
  );
};

export default PayPalAsPlaceOrder;
