import { createRef, useEffect, useState } from 'react';

import { onAmazonPaySectionButtonClick } from 'helpers/AmazonPayUtils';

import css from 'styles/components/checkout/payment/paymentAsList/thirdPartyAsPlaceOrder.scss';

export const AmazonPayAsPlaceOrder = params => {
  const {
    amazonPay: { buttonContext },
    isChargeSummary,
    onInitAmazonPay
  } = params;

  const [btnRef] = useState(createRef());
  const [buttonWasRendered, setButtonWasRendered] = useState(false);

  useEffect(() => {
    if (!buttonWasRendered) {
      setButtonWasRendered(true);
      onAmazonPaySectionButtonClick({
        buttonContext,
        isChargeSummary,
        onInitAmazonPay,
        btnEl: btnRef.current
      });
    }
  }, [btnRef, buttonContext, buttonWasRendered, isChargeSummary, onInitAmazonPay]);

  return (
    <div className={css.thirdPartyBtnWrapper}>
      <div id={isChargeSummary ? 'hiddenAmazonPayChargeSummaryBtn' : 'hiddenAmazonPayBtn'} ref={btnRef} />
    </div>
  );
};

export default AmazonPayAsPlaceOrder;
