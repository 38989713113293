import { useSelector } from 'react-redux';

import HasNoBalance from 'components/checkout/payment/paymentAsList/HasNoBalance';

import css from 'styles/components/checkout/payment/paymentAsList/paypalInit.scss';

export const PayPalInit = ({ hasNoBalanceFromSavedDiscounts }) => {
  const payPalAuthNotApproved = useSelector(state => state.checkoutData?.payPalAuthNotApproved);

  return (
    <div className={css.payPalModeSection} id="pay-pal-content" role="tabpanel" aria-labelledby="pay-pal-tab">
      {hasNoBalanceFromSavedDiscounts ? (
        <HasNoBalance tabInfo />
      ) : payPalAuthNotApproved ? (
        'Your PayPal payment could not be authorized. Please try again, or choose a different payment method.'
      ) : (
        'You will be redirected to PayPal to confirm your shipping and payment details.'
      )}
    </div>
  );
};

export default PayPalInit;
