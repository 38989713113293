import { useEffect, useState } from 'react';

import { renderAmazonPayExpressButton } from 'helpers/AmazonPayUtils';
import useMartyContext from 'hooks/useMartyContext';
import PayPalAsPlaceOrder from 'components/checkout/payment/paymentAsList/PayPalAsPlaceOrder';
import SectionTitle from 'components/checkout/SectionTitle';

import css from 'styles/components/checkout/payment/expressCheckout.scss';

const ExpressCheckout = params => {
  const {
    testId,
    marketplace: {
      checkout: { allowAmazonPay, allowPayPal }
    }
  } = useMartyContext();

  const { amazonPay, isAmazonPayLoaded, isPayPalLoaded, onInitAmazonPay, onConfirmPayPal } = params;

  const { buttonContext } = amazonPay || {};
  const [amazonPayButtonWasRendered, setAmazonPayButtonWasRendered] = useState(false);

  const showAmazonPayButton = allowAmazonPay && !!amazonPay && isAmazonPayLoaded;
  const showPayPalButton = allowPayPal && isPayPalLoaded;

  useEffect(() => {
    if (showAmazonPayButton && !amazonPayButtonWasRendered) {
      setAmazonPayButtonWasRendered(true);
      renderAmazonPayExpressButton(buttonContext, onInitAmazonPay);
    }
  }, [amazonPayButtonWasRendered, buttonContext, onInitAmazonPay, showAmazonPayButton]);

  if (!showAmazonPayButton && !showPayPalButton) {
    return null;
  }

  return (
    <div className={css.expressCheckoutSection} data-test-id={testId('expressCheckoutBar')}>
      <SectionTitle title="Express Checkout" />
      <div className={css.expressButtonContainerWithPayPal} data-test-id={testId('expressPaymentSection')}>
        {showAmazonPayButton && <div className={css.expressButton} id="AmazonPayExpressSectionButton"></div>}
        {showPayPalButton && (
          <div className={css.expressButton} id="PaypalExpressSectionButton">
            <PayPalAsPlaceOrder onConfirmPayPal={onConfirmPayPal} isChargeSummary={false} isExpressCheckout={true} isOrderTotal={false} />
          </div>
        )}
      </div>
      <div className={css.or}>or</div>
    </div>
  );
};

export default ExpressCheckout;
