import { cn } from 'helpers/classnames';
import { CreditCardExpirationMonth, CreditCardExpirationYear } from 'components/common/CreditCardExpirationField';
import { PAYMENT_FIELDS } from 'constants/formFields';
import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/checkout/payment/paymentAsList/updateCardExpirationSection.scss';

export const UpdateCardExpirationSection = params => {
  const { expirationMonth, expirationYear, formErrors, isExpirationError, onFieldChange, onUpdateExpirationClick, purchaseDataIsLoading } = params;

  const { testId } = useMartyContext();

  return (
    <>
      <div className={css.expRow} data-test-id={testId('updateCardExpirationSection')}>
        <div className={css.fieldWrapper}>
          <div
            className={cn(css.formField, {
              [css.fieldError]: isExpirationError
            })}
          >
            <label className={css.label} htmlFor="expirationMonth">
              Month
            </label>
            <CreditCardExpirationMonth id="expirationMonth" name="expirationMonth" onChange={onFieldChange} value={expirationMonth || ''} />
            {isExpirationError && <div>{formErrors[PAYMENT_FIELDS.CC_EXPIRATION_MELODY.fieldName]}</div>}
          </div>
        </div>
        <div className={css.fieldWrapper}>
          <div
            className={cn(css.formField, {
              [css.fieldError]: isExpirationError
            })}
          >
            <label className={css.label} htmlFor="expirationYear">
              Year
            </label>
            <CreditCardExpirationYear id="expirationYear" name="expirationYear" onChange={onFieldChange} value={expirationYear || ''} />
          </div>
        </div>
      </div>
      <button
        className={css.usePaymentBtn}
        data-test-id={testId('updateExpirationBtn')}
        disabled={purchaseDataIsLoading || !(expirationMonth && expirationYear)}
        onClick={onUpdateExpirationClick}
        type="button"
      >
        Update
      </button>
    </>
  );
};

export default UpdateCardExpirationSection;
