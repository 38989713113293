// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { MediumIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokePlusCircleMediumIcon = (props: MediumIconProps) => (
  <Icon {...props} viewBox="0 0 32 32">
    <path
      d="M2 16C2 8.28906 8.23438 2 16 2C23.7109 2 30 8.28906 30 16C30 23.7656 23.7109 30 16 30C8.23438 30 2 23.7656 2 16ZM16 22.125C16.7109 22.125 17.3125 21.5781 17.3125 20.8125V17.3125H20.8125C21.5234 17.3125 22.125 16.7656 22.125 16C22.125 15.2891 21.5234 14.6875 20.8125 14.6875H17.3125V11.1875C17.3125 10.4766 16.7109 9.875 16 9.875C15.2344 9.875 14.6875 10.4766 14.6875 11.1875V14.6875H11.1875C10.4219 14.6875 9.875 15.2891 9.875 16C9.875 16.7656 10.4219 17.3125 11.1875 17.3125H14.6875V20.8125C14.6875 21.5781 15.2344 22.125 16 22.125Z"
      fill="currentcolor"
    />
  </Icon>
);

export default UtilityStrokePlusCircleMediumIcon;
