import { Link } from 'react-router-dom';

import useMartyContext from 'hooks/useMartyContext';

import css from 'styles/components/checkout/sectionCancelInline.scss';

const SectionCancelInline = ({ describedby, onClickEvent, showLink, to }) => {
  const { testId } = useMartyContext();

  if (!showLink) {
    return (
      <button data-test-id={testId('cancelLink')} aria-describedby={describedby} className={css.cancelLink} disabled={true} type="button">
        Cancel
      </button>
    );
  }

  return (
    <Link aria-describedby={describedby} className={css.cancelLink} to={to} onClick={onClickEvent} data-test-id={testId('cancelLink')}>
      Cancel
    </Link>
  );
};

export default SectionCancelInline;
