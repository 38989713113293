// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { SmallIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityDottedHeartOutlineSmallIcon = (props: SmallIconProps) => (
  <Icon {...props} viewBox="0 0 16 16">
    <path
      d="M11.7573 10.7515C12.568 10.0587 13.2696 9.38397 13.5632 8.91024C14.0093 8.19006 14.3244 7.40175 14.2719 6.36167C14.179 4.5206 12.7603 3 11.0228 3C9.31354 3 7.97244 5.02249 7.97244 5.02249C7.97244 5.02249 7.97236 5.02236 7.97217 5.02209C7.97201 5.02236 7.9719 5.02249 7.9719 5.02249C7.9719 5.02249 6.6308 3 4.9215 3C3.18406 3 1.76531 4.5206 1.67247 6.36167C1.61994 7.40175 1.93502 8.19006 2.38113 8.91024C3.27661 10.3552 7.96822 13.6698 7.97217 13.6667C7.97375 13.6679 8.71835 13.1433 9.67057 12.4201"
      stroke="currentcolor"
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default UtilityDottedHeartOutlineSmallIcon;
