/* eslint-disable sort-vars */
import React, { Component } from 'react';

import { cn } from 'helpers/classnames';
import Tag from 'tailwind/components/Tag/Tag';
import MultiLineAddress from 'components/checkout/address/MultiLineAddress';
import AddressActionsBar from 'components/checkout/address/AddressActionsBar';
import { MartyContext } from 'utils/context';
import UtilityStrokePlusCircleMediumIcon from 'tailwind/components/Icons/UtilityStrokePlusCircleMediumIcon';
import UtilityStrokeInfoOutlineSmallIcon from 'tailwind/components/Icons/UtilityStrokeInfoOutlineSmallIcon';

import css from 'styles/components/checkout/address/addressList.scss';

const NUM_PER_PAGE = 4;

export class AddressList extends Component {
  state = {
    seeAllAddresses: false
  };

  toggleSeeAllAddresses = () => this.setState(state => ({ seeAllAddresses: !state.seeAllAddresses }));

  render() {
    return (
      <MartyContext.Consumer>
        {context => {
          const {
            testId,
            marketplace: {
              checkout: { shippingCountriesWhitelist }
            }
          } = context;

          const {
            invalidAddressError,
            savedAddresses,
            onAddressSelected,
            onUseAddressClick,
            onEditAddressClick,
            onDeleteAddressClick,
            isLoading,
            selectedAddressId,
            isBilling = false,
            showAddressModal,
            sectionCancel,
            saveShippingRef
          } = this.props;

          const filteredAddresses = savedAddresses.filter(address => filterCountries(isBilling, address, shippingCountriesWhitelist));
          const showSeeAll = filteredAddresses.length > NUM_PER_PAGE && !this.state.seeAllAddresses;
          const AddNewButton = (
            <button
              className={css.addNewButton}
              disabled={isLoading}
              onClick={() => {
                showAddressModal(false);
              }}
              type="button"
              data-test-id={testId('addNewBillingAddress')}
            >
              <UtilityStrokePlusCircleMediumIcon size={24} />
              Add new address
            </button>
          );

          if (!savedAddresses?.length) {
            if (!isBilling) {
              return null;
            }

            return (
              <>
                {AddNewButton}
                <div className={css.btnsContainer}>{sectionCancel}</div>
              </>
            );
          }

          return (
            <div className={css.wrapper} data-test-id={testId('addressListWrapper')}>
              {!!invalidAddressError && (
                <div className={css.boxError}>
                  <UtilityStrokeInfoOutlineSmallIcon size={16} />
                  {invalidAddressError}
                </div>
              )}
              <form className={css.form} onSubmit={onUseAddressClick} method="POST" action="/marty/checkout/address/use">
                {filteredAddresses.map((address, rowIndex) => {
                  const {
                    addressId,
                    isDefaultShippingAddress,
                    mailingAddress: { countryCode }
                  } = address;
                  const isSelected = selectedAddressId === addressId;
                  const isInvisible = this.state.seeAllAddresses ? false : rowIndex >= NUM_PER_PAGE;
                  const itemStyles = cn(css.item, {
                    [css.invisible]: isInvisible
                  });

                  // If is selected, but not in the first page, make sure all items are showing
                  if (isSelected && isInvisible) this.toggleSeeAllAddresses();

                  return (
                    <div key={addressId} className={itemStyles}>
                      <div className={css.row} data-test-id={testId('addressSelectionRow')}>
                        <div className={css.selectionBlock} data-test-id={testId('addressSelectionBlock')}>
                          <input
                            checked={isSelected}
                            value={addressId}
                            data-address-id={addressId}
                            disabled={isLoading}
                            onChange={onAddressSelected}
                            type="radio"
                            id={`address-${addressId}`}
                            name="addressList"
                            data-test-id={testId('addressSelector')}
                          />
                          <label
                            htmlFor={`address-${addressId}`}
                            className={cn('!mr-0', css.addressOptionsWrapper, { [css.selected]: isSelected })}
                            data-test-id={testId('addressSelectorLabel')}
                          >
                            <div className={css.line1}>
                              <MultiLineAddress
                                hidePhone={true}
                                address={address}
                                hideCountry={countryCode === 'US'}
                                Tag={
                                  isDefaultShippingAddress ? (
                                    <Tag size="small" variant="blue 100">
                                      Default
                                    </Tag>
                                  ) : null
                                }
                              />
                              {!isBilling && (
                                <AddressActionsBar
                                  index={rowIndex}
                                  isHidden={!isSelected}
                                  addressId={addressId}
                                  isLoading={isLoading}
                                  onEdit={onEditAddressClick}
                                  onDelete={onDeleteAddressClick}
                                />
                              )}
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </form>
              <div className={css.newItemLine}>
                {showSeeAll && (
                  <button type="button" onClick={this.toggleSeeAllAddresses} className={css.seeAllButton} data-test-id={testId('seeAllAddressess')}>
                    See All Addresses
                  </button>
                )}
                {AddNewButton}
                <div ref={saveShippingRef} className={css.btnsContainer}>
                  {sectionCancel}
                  <button
                    type="button"
                    disabled={isLoading}
                    className={css.saveButton}
                    onClick={onUseAddressClick}
                    data-address-id={selectedAddressId}
                    data-test-id={testId('saveAndContinue')}
                  >
                    Save & Continue
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      </MartyContext.Consumer>
    );
  }
}

const filterCountries = (isBilling, address, shippingCountriesWhitelist) => {
  // TODO: support edit of billing address: https://github01.zappos.net/mweb/marty/issues/7384
  if (isBilling || !isBilling) {
    return true;
  }

  const {
    mailingAddress: { countryCode }
  } = address;
  return shippingCountriesWhitelist.includes(countryCode);
};

export default AddressList;
