import { cn } from 'helpers/classnames';
import { SmallLoader } from 'components/Loader';
import { AFTERPAY_MAXIMUM, AFTERPAY_MINIMUM } from 'constants/appConstants';
import { toUSD } from 'helpers/NumberFormats';
import HasNoBalance from 'components/checkout/payment/paymentAsList/HasNoBalance';

import css from 'styles/components/checkout/payment/paymentAsList/afterPayInit.scss';

export const AfterPayInit = params => {
  const { accountIsLoading, customerInfo, doesCartContainGiftCard, grandTotal, hasNoBalanceFromSavedDiscounts, isAfterpayLoading } = params;

  const { email } = customerInfo || {};
  const isAfterpayEligibleByPrice = grandTotal >= AFTERPAY_MINIMUM && grandTotal <= AFTERPAY_MAXIMUM;

  if (accountIsLoading || isAfterpayLoading) {
    return (
      <div className={css.afterPayModeSection}>
        <SmallLoader />
      </div>
    );
  }

  if (!email) {
    return (
      <p className={cn(css.afterPayModeSection, css.refreshPageWarning)}>Unable to use Afterpay at this time. Please try refreshing the page.</p>
    );
  }

  if (doesCartContainGiftCard || !isAfterpayEligibleByPrice) {
    return (
      <div className={css.afterPayModeSection}>
        {hasNoBalanceFromSavedDiscounts ? (
          <HasNoBalance tabInfo />
        ) : (
          <>
            <afterpay-placement
              data-modal-link-style="more-info-text"
              data-cart-is-eligible="false"
              data-intro-text="Pay in"
              data-locale="en_US"
              data-currency="USD"
              data-logo-type="compact-badge"
              data-amount={grandTotal}
            />
            {doesCartContainGiftCard
              ? 'Afterpay cannot be used to purchase gift cards. Try another payment method to complete this purchase.'
              : `To use Afterpay, the order total must be between ${toUSD(AFTERPAY_MINIMUM)} and ${toUSD(AFTERPAY_MAXIMUM)}.`}
          </>
        )}
      </div>
    );
  }

  return (
    <div className={css.afterPayModeSection} id="afterpay-content" role="tabpanel" aria-labelledby="afterpay-tab">
      <afterpay-placement
        data-cart-is-eligible="true"
        data-intro-text="Pay in"
        data-locale="en_US"
        data-currency="USD"
        data-logo-type="lockup"
        data-amount={grandTotal}
      />

      <afterpay-placement data-type="price-table" data-amount={grandTotal} data-currency="USD" />

      <p className={css.redirectMessageText}>You will be redirected to the Afterpay to confirm your payment details</p>
    </div>
  );
};

export default AfterPayInit;
