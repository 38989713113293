import { loadScript } from 'helpers/ScriptUtils';
import { AMAZON_PAY_JS } from 'constants/externalJavascriptFiles';
import { onEvent } from 'helpers/EventHelpers';

export interface ButtonContext {
  merchantId: string;
  ledgerCurrency: string;
  sandbox: boolean;
  checkoutLanguage: string;
  productType: string;
  placement: string;
  createCheckoutSessionConfig: {
    publicKeyId: string;
    signature: string;
    payloadJSON: string;
  };
}

type OnInitAmazonPay = (params: {
  isAutoInit: boolean;
  isExpressCheckout: boolean;
  isChargeSummary: boolean;
  isOrderTotal: boolean;
  isPlaceOrderButton?: boolean;
}) => void;

export const loadAmazonPayJavaScript = ({
  onLoadCallback,
  onLoadErrorCallback
}: {
  onLoadCallback: () => void;
  onLoadErrorCallback: () => void;
}): void => {
  loadScript({ src: AMAZON_PAY_JS, onLoadCallback, onLoadErrorCallback });
};

const renderAmazonPayButton = ({
  target,
  buttonContext,
  autoInitAmazonPay = false,
  onInitAmazonPay
}: {
  target: string;
  buttonContext: ButtonContext;
  autoInitAmazonPay?: boolean;
  onInitAmazonPay: OnInitAmazonPay;
}): void => {
  if (document.getElementById(target)) {
    const {
      createCheckoutSessionConfig,
      createCheckoutSessionConfig: { publicKeyId },
      checkoutLanguage,
      ledgerCurrency,
      merchantId,
      placement,
      productType
    } = buttonContext;

    const amazonPayButton = window.amazon?.Pay.renderButton(`#${target}`, {
      checkoutLanguage,
      ledgerCurrency,
      merchantId,
      placement,
      productType,
      publicKeyId
    });

    amazonPayButton.onClick(() => {
      onInitAmazonPay({
        isAutoInit: false,
        isExpressCheckout: true,
        isChargeSummary: false,
        isOrderTotal: false
      });
      amazonPayButton.initCheckout({
        createCheckoutSessionConfig
      });
    });

    if (autoInitAmazonPay) {
      onInitAmazonPay({
        isAutoInit: true,
        isExpressCheckout: true,
        isChargeSummary: false,
        isOrderTotal: false
      });
      amazonPayButton.initCheckout({
        createCheckoutSessionConfig
      });
    }
  }
};

const initAmazonPay = ({
  buttonContext,
  onInitAmazonPay,
  btnEl,
  isChargeSummary,
  isOrderTotal
}: {
  buttonContext: ButtonContext;
  onInitAmazonPay: OnInitAmazonPay;
  btnEl: HTMLElement;
  isChargeSummary: boolean;
  isOrderTotal: boolean;
}): void => {
  if (btnEl) {
    const {
      createCheckoutSessionConfig,
      createCheckoutSessionConfig: { publicKeyId },
      checkoutLanguage,
      ledgerCurrency,
      merchantId,
      placement,
      productType
    } = buttonContext;

    const amazonPayButton = window.amazon?.Pay.renderButton(`#${btnEl.id}`, {
      checkoutLanguage,
      ledgerCurrency,
      merchantId,
      placement,
      productType,
      publicKeyId
    });

    onEvent(btnEl, 'click', () => {
      onInitAmazonPay({
        isAutoInit: false,
        isExpressCheckout: false,
        isPlaceOrderButton: true,
        isChargeSummary,
        isOrderTotal
      });
      amazonPayButton.initCheckout({
        createCheckoutSessionConfig
      });
    });
  }
};

export const onAmazonPaySectionButtonClick = ({
  buttonContext,
  onInitAmazonPay,
  btnEl,
  isChargeSummary
}: {
  buttonContext: ButtonContext;
  onInitAmazonPay: OnInitAmazonPay;
  btnEl: HTMLElement;
  isChargeSummary: boolean;
}): void => {
  initAmazonPay({
    buttonContext,
    onInitAmazonPay,
    btnEl,
    isChargeSummary,
    isOrderTotal: !isChargeSummary
  });
};

export const renderAmazonPayExpressButton = (buttonContext: ButtonContext, onInitAmazonPay: OnInitAmazonPay): void => {
  renderAmazonPayButton({
    target: 'AmazonPayExpressSectionButton',
    buttonContext,
    onInitAmazonPay
  });
};

export const changeAmazonPayAddress = (target: string, amazonCheckoutSessionId: string): void => {
  window.amazon?.Pay.bindChangeAction(`#${target}`, {
    amazonCheckoutSessionId,
    changeAction: 'changeAddress'
  });
};

export const changeAmazonPayPayment = (target: string, amazonCheckoutSessionId: string): void => {
  window.amazon?.Pay.bindChangeAction(`#${target}`, {
    amazonCheckoutSessionId,
    changeAction: 'changePayment'
  });
};

export const signOutOfAmazonPay = () => {
  window.amazon?.Pay.signout();
};
