// This file was generated with `npm run icons:generate`. Don’t edit directly.
import Icon from 'tailwind/components/Icon/Icon';
import type { SmallIconProps } from 'tailwind/components/Icon/Icon.types';

const UtilityStrokeMinusSmallIcon = (props: SmallIconProps) => (
  <Icon {...props} viewBox="0 0 16 16">
    <path d="M2.27393 7.83325H14.059" stroke="currentcolor" strokeWidth="0.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);

export default UtilityStrokeMinusSmallIcon;
