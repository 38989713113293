import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import usePrevious from 'hooks/usePrevious';
import useMartyContext from 'hooks/useMartyContext';
import { fetchAccountInfo } from 'actions/account/account';
import {
  configurePurchase,
  onAbandonAmazonPay,
  onAddNewPaymentClickEvent,
  onChangeAmazonPayPaymentClick,
  onChangeBillingAddressClick,
  onCloseNewPaymentEvent,
  onClosePaymentEvent,
  onDeletePaymentInstrumentClick,
  onRedeemPoints,
  onSelectedBillingAddress,
  onSendToDesiredPage,
  onToggleIsPrimary,
  onTogglePromoBox,
  onUpdateExpiration,
  onUsePaymentMethodClickEvent,
  requestAddresses,
  requestPayments,
  savePaymentInstrument,
  setPaymenthMethodType,
  setSelectedPaymentInstrumentId,
  setUseDiscount,
  setUsePromoBalance,
  verifyCreditCard
} from 'store/ducks/checkout/actions';
import { clearPaymentFormItem } from 'store/ducks/payment/actions';
import {
  CHECKOUT_STEP_MAP,
  EDIT_BILLING_ADDRESS_STEP,
  NEW_BILLING_ADDRESS_STEP,
  PAYMENT_STEP,
  REVIEW_STEP,
  SELECT_BILLING_ADDRESS_STEP
} from 'constants/checkoutFlow';
import { AFTER_PAY, AMAZON_PAY, CREDIT_CARD, CREDIT_CARD_PAYMENT_METHODS, PAYPAL, THIRD_PARTY_PAYMENT_METHODS } from 'constants/paymentMethodTypes';
import AmazonPayPaymentReview from 'components/checkout/payment/paymentAsList/AmazonPayPaymentReview';
import AmazonPayInit from 'components/checkout/payment/paymentAsList/AmazonPayInit';
import AfterPayInit from 'components/checkout/payment/paymentAsList/AfterPayInit';
import AfterPayPaymentReview from 'components/checkout/payment/paymentAsList/AfterPayPaymentReview';
import PalPayInit from 'components/checkout/payment/paymentAsList/PayPalInit';
import PayPalPaymentReview from 'components/checkout/payment/paymentAsList/PayPalPaymentReview';
import PaymentMethodChooser from 'components/checkout/payment/paymentAsList/PaymentMethodChooser';
import SectionTitle from 'components/checkout/SectionTitle';
import CreditCardReview from 'components/checkout/payment/paymentAsList/CreditCardReview';
import CreditCardUpdate from 'components/checkout/payment/paymentAsList/CreditCardUpdate';
import GiftOrPromoRow from 'components/checkout/payment/paymentAsList/GiftOrPromoRow';
import SectionChangeInline from 'components/checkout/SectionChangeInline';
import { hasInactiveInstrument, isDigitalDeliveryOnlyCart, isNonRetailOnlyCart, needsValidBillingAddress } from 'helpers/CheckoutUtils';
import { getCleanPath } from 'helpers/CheckoutFlowControl';
import { HYDRA_HIDE_PAYPAL } from 'constants/hydraTests';
import { isAssigned } from 'actions/ab';
import { inIframe } from 'helpers/InIframe';

import css from 'styles/containers/checkout/multiPaymentMethod.scss';

export const MultiPaymentMethod = props => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const { testId } = useMartyContext();

  const {
    accountIsLoading,
    address: { isLoaded: isAddressesLoaded },
    checkoutData,
    clearPaymentFormItem,
    configurePurchase,
    customerInfo,
    fetchAccountInfo,
    isHydraHidePayPal,
    location: { pathname, search } = {},
    onAbandonAmazonPay,
    onAddNewPaymentClickEvent,
    onChangeAmazonPayPaymentClick,
    onChangeBillingAddressClick,
    onCloseNewPaymentEvent,
    onDeletePaymentInstrumentClick,
    onSendToDesiredPage,
    onToggleIsPrimary,
    onUpdateExpiration,
    payment = {},
    requestAddresses,
    requestPayments,
    savePaymentInstrument,
    setPaymenthMethodType,
    setUseDiscount,
    setUsePromoBalance,
    verifyCreditCard,
    paymentCouponCode,
    savePaymentRef
  } = props;

  const {
    billingAddressId,
    formItem,
    hasSavedPayments,
    hasVerifyCreditCardError,
    isLoaded: paymentDataIsLoaded,
    isLoading: paymentDataIsLoading,
    savedPayments = []
  } = payment;

  const {
    canChangePayment,
    cartType,
    constraintViolations,
    doesPurchaseRequireCC,
    isAfterPayLoaded,
    isAfterPayUnableToLoad,
    isAfterPayButtonLoaded,
    isAfterPayButtonUnableToLoad,
    isAmazonPayLoaded,
    isDiscountFullBalance,
    isFullBalanceSelected,
    isLoading: purchaseDataIsLoading,
    links,
    purchase: {
      amazonPay,
      chargeSummary: { grandTotal },
      eligibleBalances,
      eligibleBalances: { couponBalance },
      productList,
      purchaseId,
      useDiscount,
      shippingAddressId,
      shippingAddress
    },
    purchaseCreditCard,
    purchaseGiftCard: hasPurchaseGiftCard,
    purchaseType,
    selectedPaymentInstrumentId,
    selectedPaymentNeedsConfirmation,
    useDiscountBalance,
    usePromoBalance
  } = checkoutData;

  const { addCard } = queryString.parse(search);
  const isReviewStep = getCleanPath(pathname) === CHECKOUT_STEP_MAP[REVIEW_STEP];
  const isPaymentStep = getCleanPath(pathname) === CHECKOUT_STEP_MAP[PAYMENT_STEP];
  const isBillingStep =
    getCleanPath(pathname) === CHECKOUT_STEP_MAP[SELECT_BILLING_ADDRESS_STEP] ||
    getCleanPath(pathname) === CHECKOUT_STEP_MAP[NEW_BILLING_ADDRESS_STEP] ||
    getCleanPath(pathname) === CHECKOUT_STEP_MAP[EDIT_BILLING_ADDRESS_STEP];
  const isDigitalOnlyCart = isDigitalDeliveryOnlyCart(cartType);
  const doesCartContainGiftCard = isNonRetailOnlyCart(cartType);
  const { paymentMethodType = purchaseType } = checkoutData;
  const dataIsLoading = paymentDataIsLoading || purchaseDataIsLoading;
  const errorMessageFromCv =
    hasSavedPayments && hasInactiveInstrument(constraintViolations) ? 'Inactive payment method. Select or add a new payment.' : null;
  const needsBillingAddress = needsValidBillingAddress(constraintViolations) && !!billingAddressId;
  const needsPaymentLookup = selectedPaymentInstrumentId && selectedPaymentInstrumentId !== 'savedBalance';
  const isCreditCardPaymentMethodType = paymentMethodType === CREDIT_CARD;
  const isAmazonPayPaymentMethodType = paymentMethodType === AMAZON_PAY;
  const isAfterPayPaymentMethodType = paymentMethodType === AFTER_PAY;
  const isPayPalPaymentMethodType = paymentMethodType === PAYPAL;
  const showNeedShipAddressMsg =
    (!isDigitalOnlyCart && !shippingAddressId && !purchaseCreditCard) ||
    (!isDigitalOnlyCart && !isReviewStep && !isPaymentStep && !isBillingStep && purchaseType !== AMAZON_PAY);
  const showCreditCardReview = isCreditCardPaymentMethodType && purchaseType === CREDIT_CARD && isReviewStep;
  const showCreditCardUpdate = isCreditCardPaymentMethodType && (isPaymentStep || isBillingStep);
  const showAmazonPayReview = isAmazonPayPaymentMethodType && purchaseType === AMAZON_PAY;
  const showAmazonPayInit = isAmazonPayPaymentMethodType && purchaseType !== AMAZON_PAY;
  const showAddCreditCardForm =
    isCreditCardPaymentMethodType &&
    ((isDiscountFullBalance && !usePromoBalance && !savedPayments.length) ||
      (!savedPayments.length && doesPurchaseRequireCC && !showPaymentModal) ||
      (!savedPayments.length && isPaymentStep && !showPaymentModal));

  const showAfterPayReview = isAfterPayPaymentMethodType && purchaseType === AFTER_PAY && (isPaymentStep || isBillingStep || isReviewStep);
  const showAfterPayInit = isAfterPayPaymentMethodType && purchaseType !== AFTER_PAY;
  const isAfterpayLoading = (!isAfterPayLoaded && !isAfterPayUnableToLoad) || (!isAfterPayButtonLoaded && !isAfterPayButtonUnableToLoad);

  const showPayPalPaymentReview = isPayPalPaymentMethodType && purchaseType === PAYPAL;
  const showPayPalInit = isPayPalPaymentMethodType && purchaseType !== PAYPAL;

  const hasNoBalanceFromSavedDiscounts = isDiscountFullBalance && isFullBalanceSelected;
  const showPaymentMethodChooser = isDigitalOnlyCart || shippingAddressId || purchaseCreditCard;

  const previous = usePrevious({
    addCard,
    checkoutData,
    isPaymentStep,
    payment
  }) || { addCard: false, isPaymentStep: false, payment: {}, checkoutData: {} };

  useEffect(() => {
    if (isBillingStep && !isAddressesLoaded) {
      requestAddresses();
    }
  }, [isAddressesLoaded, isBillingStep, requestAddresses]);

  const { chargeSummary } = checkoutData.purchase;

  useEffect(() => {
    if (!isPaymentStep && formItem.formErrors) {
      clearPaymentFormItem();
    }
  }, [clearPaymentFormItem, formItem.formErrors, isPaymentStep]);

  useEffect(() => {
    if (!isPaymentStep && showPaymentModal) {
      setShowPaymentModal(false);
    }
  }, [isPaymentStep, showPaymentModal]);

  useEffect(() => {
    const { addCard: previousAddCard } = previous;
    if (!previousAddCard && !!addCard && !showPaymentModal) {
      setShowPaymentModal(true);
    }

    if (!!previousAddCard && !addCard && showPaymentModal) {
      setShowPaymentModal(false);
    }
  }, [addCard, previous, showPaymentModal]);

  useEffect(() => {
    if ((isPaymentStep || needsPaymentLookup) && !paymentDataIsLoaded && !paymentDataIsLoading) {
      requestPayments();
    }
  }, [isPaymentStep, needsPaymentLookup, paymentDataIsLoaded, paymentDataIsLoading, requestPayments]);

  useEffect(() => {
    const {
      checkoutData: { paymentMethodType: prevPaymentMethodTypeNonDefaulted, purchaseType: prevPurchaseType }
    } = previous;
    const prevPaymentMethodType = prevPaymentMethodTypeNonDefaulted || prevPurchaseType;
    if (paymentMethodType !== prevPaymentMethodType && purchaseType !== 'CREDIT_CARD' && paymentMethodType === 'CREDIT_CARD') {
      onSendToDesiredPage(PAYMENT_STEP);
    }
  }, [onSendToDesiredPage, paymentMethodType, previous, purchaseType]);

  useEffect(() => {
    if (paymentMethodType === AFTER_PAY && !accountIsLoading && !customerInfo) {
      fetchAccountInfo();
    }
  }, [fetchAccountInfo, accountIsLoading, customerInfo, paymentMethodType]);

  const showPaymentModalForList = () => {
    onAddNewPaymentClickEvent();
  };

  const hidePaymentModalForList = () => {
    const params = new URLSearchParams(search);
    params.delete('addCard');
    onCloseNewPaymentEvent();
    clearPaymentFormItem();
    onSendToDesiredPage(PAYMENT_STEP, params);
  };

  const onSubmitPayment = instrument => {
    onCloseNewPaymentEvent();

    if (purchaseType === AMAZON_PAY) {
      onAbandonAmazonPay();
      savePaymentInstrument({ instrument, clearPurchaseDoc: true });
    } else {
      savePaymentInstrument({ instrument });
    }
  };

  const onUseCard = paymentInstrumentId => {
    if (paymentDataIsLoading || purchaseDataIsLoading) {
      return;
    }

    if (!doesPurchaseRequireCC) {
      setUsePromoBalance(false);
    }

    const paymentMethods = [
      {
        paymentInstrumentId,
        paymentMethodCode: 'CC'
      }
    ];

    if (purchaseType === AMAZON_PAY || purchaseType === PAYPAL) {
      if (purchaseType === AMAZON_PAY) {
        onAbandonAmazonPay();
      }
      configurePurchase({
        paymentMethods,
        advanceOnSuccess: true,
        clearPurchaseDoc: true,
        includePaymentsAndAddresses: true
      });
    } else {
      configurePurchase({ paymentMethods, advanceOnSuccess: true });
    }
  };

  const onAutoUseCard = paymentInstrumentId => {
    onUseCard(paymentInstrumentId);
  };

  const onUseCreditCardClick = e => {
    e.preventDefault();
    const {
      currentTarget: {
        dataset: { paymentInstrumentId }
      }
    } = e;
    onUseCard(paymentInstrumentId);
  };

  const onUsePromoBalanceClick = () => {
    const primaryPayment = savedPayments.find(payment => payment.primary);
    const previousUsePromoBalance = usePromoBalance;

    if (doesCartContainGiftCard) {
      setUsePromoBalance(false);
      setUseDiscount(!useDiscountBalance);
    } else {
      setUsePromoBalance(!usePromoBalance);
    }

    if (!isDiscountFullBalance) {
      // if balance is increased for 3rd party payment customers, they must re-verify via their respective dialogs.
      if (previousUsePromoBalance && THIRD_PARTY_PAYMENT_METHODS.includes(purchaseType)) {
        configurePurchase({
          clearAmazonPaySession: true,
          includePaymentsAndAddresses: true
        });
      } else {
        configurePurchase({});
      }
    } else if (savedPayments.length === 1) {
      const paymentMethods = [
        {
          paymentInstrumentId: savedPayments[0].paymentInstrumentId,
          paymentMethodCode: 'CC'
        }
      ];
      configurePurchase({ paymentMethods, advanceOnSuccess: true });
    } else if (primaryPayment) {
      const paymentMethods = [
        {
          paymentInstrumentId: primaryPayment.paymentInstrumentId,
          paymentMethodCode: 'CC'
        }
      ];
      configurePurchase({ paymentMethods, advanceOnSuccess: true });
    } else {
      configurePurchase({ advanceOnSuccess: true });
    }
  };

  const onRemovePaymentInstrumentClick = paymentInstrumentId => {
    const doDelete = window.confirm('Are you sure you wish to delete this payment method?');
    if (doDelete) {
      const paymentDetails = savedPayments.find(item => item.paymentInstrumentId === paymentInstrumentId) || {};
      onDeletePaymentInstrumentClick(paymentDetails);
    }
  };

  const onUpdateExpirationSubmit = ({ paymentInstrumentId, expirationMonth, expirationYear }) => {
    const paymentDetails = savedPayments.find(item => item.paymentInstrumentId === paymentInstrumentId) || {};
    const instrument = { ...paymentDetails, expirationMonth, expirationYear };
    const {
      billingAddress: { addressId }
    } = paymentDetails;
    onUpdateExpiration();
    savePaymentInstrument({ instrument, addressId });
  };

  const onVerifyCardClick = ({ number, paymentInstrumentId }) => {
    verifyCreditCard({ number, paymentInstrumentId });
  };

  const onChangePaymentMethodType = e => {
    e.preventDefault();
    const {
      currentTarget: {
        dataset: { paymentMethodType }
      }
    } = e;
    setPaymenthMethodType(paymentMethodType);

    const isAbandoningAmazonPay = purchaseType === AMAZON_PAY && paymentMethodType !== AMAZON_PAY;
    const isAbandoningPaypal = purchaseType === PAYPAL && CREDIT_CARD_PAYMENT_METHODS.includes(paymentMethodType);

    // leaving amazonpay or leaving paypal for another third party payment, reset purchase doc
    if (isAbandoningAmazonPay || isAbandoningPaypal) {
      configurePurchase({
        advanceOnSuccess: true,
        clearPurchaseDoc: true,
        includePaymentsAndAddresses: true
      });
      onSendToDesiredPage(PAYMENT_STEP);
    }
  };

  const onChangeAmazonPaymentClick = e => {
    e.preventDefault();
    onChangeAmazonPayPaymentClick();
  };

  const paymentContainerTestId = showCreditCardUpdate ? 'paymentSectionOpen' : 'paymentSectionClosed';

  return (
    <div data-test-id={testId(paymentContainerTestId)}>
      <SectionTitle id="payment-section" title="Payment Method" />

      <div className={css.giftOrPromoRowWrapper}>
        {paymentCouponCode}

        <form method="POST" action="/marty/checkout/payment" className={css.giftOrPromoSelector}>
          <GiftOrPromoRow
            doesCartContainGiftCard={doesCartContainGiftCard}
            eligibleBalances={eligibleBalances}
            isFullBalanceSelected={isFullBalanceSelected}
            paymentMethodType={paymentMethodType}
            purchaseType={purchaseType}
            onUsePromoBalanceClick={onUsePromoBalanceClick}
            purchaseDataIsLoading={purchaseDataIsLoading}
            usePromoBalance={usePromoBalance}
            useDiscountBalance={useDiscountBalance}
          />
        </form>
      </div>

      <h4 className={css.subTitle}>Payment</h4>

      {showPaymentMethodChooser && (
        <PaymentMethodChooser
          activePaymentMethodType={paymentMethodType}
          isAfterPayLoaded={isAfterPayLoaded && isAfterPayButtonLoaded}
          isAmazonPayLoaded={isAmazonPayLoaded}
          isHydraHidePayPal={isHydraHidePayPal}
          isClt={inIframe()}
          onChangePaymentMethodType={onChangePaymentMethodType}
        />
      )}

      {showNeedShipAddressMsg && <div className={css.selectShipAddressMessage}>Please add or select a shipping address to proceed.</div>}

      {showCreditCardReview && (
        <CreditCardReview
          canChangePayment={canChangePayment}
          changeBillingAddressLinkTo={links[SELECT_BILLING_ADDRESS_STEP]}
          hasPurchaseGiftCard={hasPurchaseGiftCard}
          hasPurchasePromoCode={useDiscount && couponBalance}
          isDiscountFullBalance={isDiscountFullBalance}
          isFullBalanceSelected={isFullBalanceSelected}
          onChangeBillingAddressClick={onChangeBillingAddressClick}
          purchaseCreditCard={purchaseCreditCard}
          purchaseDataIsLoading={purchaseDataIsLoading}
          chargeSummary={chargeSummary}
        />
      )}

      {showCreditCardUpdate && (
        <CreditCardUpdate
          dataIsLoading={dataIsLoading}
          doesPurchaseRequireCC={doesPurchaseRequireCC}
          errorMessageFromCv={errorMessageFromCv}
          formItem={formItem}
          hasSavedPayments={hasSavedPayments}
          hasVerifyCreditCardError={hasVerifyCreditCardError}
          isBillingStep={isBillingStep}
          isDiscountFullBalance={isDiscountFullBalance}
          needsBillingAddress={needsBillingAddress}
          onAddNewCardLinkTo={links[PAYMENT_STEP]}
          onAddNewPaymentClick={showPaymentModalForList}
          onAutoUseCard={onAutoUseCard}
          onCancelAddNewPayment={hidePaymentModalForList}
          onCloseCCIVR={hidePaymentModalForList}
          onDeletePaymentInstrumentClick={onRemovePaymentInstrumentClick}
          onSubmitPayment={onSubmitPayment}
          onToggleIsPrimary={onToggleIsPrimary}
          onUpdateExpirationSubmit={onUpdateExpirationSubmit}
          onUseCreditCardClick={onUseCreditCardClick}
          onVerifyCardClick={onVerifyCardClick}
          paymentDataIsLoaded={paymentDataIsLoaded}
          paymentDataIsLoading={paymentDataIsLoading}
          purchaseCreditCard={purchaseCreditCard}
          sharedPayment={payment}
          shippingAddressId={shippingAddressId}
          showAddCreditCardForm={showAddCreditCardForm}
          showNeedShipAddressMsg={showNeedShipAddressMsg}
          showPaymentModal={showPaymentModal}
          onChangeBillingAddressClick={onChangeBillingAddressClick}
          savePaymentRef={savePaymentRef}
        />
      )}

      {showCreditCardReview && !(isDiscountFullBalance && chargeSummary.grandTotal === 0) && (
        <div className={css.changePayment}>
          <SectionChangeInline
            isInline
            describedby="payment-method"
            testIdName="paymentChangeLink"
            disabled={purchaseDataIsLoading}
            label="Change Payment"
            showLink={!(isFullBalanceSelected && isDiscountFullBalance) && canChangePayment}
            to={links[PAYMENT_STEP]}
          />
        </div>
      )}

      {showAmazonPayReview && (
        <AmazonPayPaymentReview
          amazonPay={amazonPay}
          changePaymentLinkTo={links[PAYMENT_STEP]}
          formItem={formItem}
          hasVerifyCreditCardError={hasVerifyCreditCardError}
          onChangeAmazonPaymentClick={onChangeAmazonPaymentClick}
          onVerifyCardClick={onVerifyCardClick}
          paymentInstrumentId={purchaseCreditCard.paymentInstrumentId}
          purchaseCreditCard={purchaseCreditCard}
          purchaseDataIsLoading={purchaseDataIsLoading}
          selectedPaymentNeedsConfirmation={selectedPaymentNeedsConfirmation}
        />
      )}

      {showAmazonPayInit && <AmazonPayInit hasNoBalanceFromSavedDiscounts={hasNoBalanceFromSavedDiscounts} />}

      {showAfterPayReview && (
        <AfterPayPaymentReview grandTotal={grandTotal} needsBillingAddress={needsBillingAddress} isBillingStep={isBillingStep} />
      )}

      {showAfterPayInit && (
        <AfterPayInit
          accountIsLoading={accountIsLoading}
          customerInfo={customerInfo}
          doesCartContainGiftCard={doesCartContainGiftCard}
          grandTotal={grandTotal}
          isAfterpayLoading={isAfterpayLoading}
          purchaseId={purchaseId}
          productList={productList}
          shippingAddress={shippingAddress}
          hasNoBalanceFromSavedDiscounts={hasNoBalanceFromSavedDiscounts}
        />
      )}

      {showPayPalInit && <PalPayInit hasNoBalanceFromSavedDiscounts={hasNoBalanceFromSavedDiscounts} />}

      {showPayPalPaymentReview && <PayPalPaymentReview />}
    </div>
  );
};

function mapStateToProps(state) {
  const {
    account: { isLoading: accountIsLoading, customerInfo },
    address,
    checkoutData,
    killswitch,
    router,
    sharedPayment: payment,
    sharedRewards: rewards
  } = state;

  return {
    accountIsLoading,
    address,
    checkoutData,
    customerInfo,
    isHydraHidePayPal: isAssigned(HYDRA_HIDE_PAYPAL, 1, state),
    killswitch,
    location: router.location,
    payment,
    rewards
  };
}

const MultiPaymentMethodConnected = connect(mapStateToProps, {
  clearPaymentFormItem,
  configurePurchase,
  fetchAccountInfo,
  onAbandonAmazonPay,
  onAddNewPaymentClickEvent,
  onChangeAmazonPayPaymentClick,
  onChangeBillingAddressClick,
  onCloseNewPaymentEvent,
  onClosePaymentEvent,
  onDeletePaymentInstrumentClick,
  onRedeemPoints,
  onSelectedBillingAddress,
  onSendToDesiredPage,
  onToggleIsPrimary,
  onTogglePromoBox,
  onUpdateExpiration,
  onUsePaymentMethodClickEvent,
  requestAddresses,
  requestPayments,
  savePaymentInstrument,
  setPaymenthMethodType,
  setSelectedPaymentInstrumentId,
  setUseDiscount,
  setUsePromoBalance,
  verifyCreditCard
})(MultiPaymentMethod);

export default MultiPaymentMethodConnected;
