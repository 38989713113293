interface HasNoBalanceProps {
  tabInfo?: boolean;
}

const HasNoBalance = ({ tabInfo = false }: HasNoBalanceProps) => (
  <>
    Your gift card or promotional balance covers the entire purchase!
    {tabInfo && (
      <>
        {' '}
        Use the{' '}
        <label htmlFor="credit-card-tab" className="cursor-pointer font-medium underline">
          Credit/Debit Card
        </label>{' '}
        tab to place your order.
      </>
    )}
  </>
);

export default HasNoBalance;
