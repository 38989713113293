import { cn } from 'helpers/classnames';
import { toUSD } from 'helpers/NumberFormats';

import css from 'styles/components/checkout/payment/paymentCouponMessage.scss';

type CouponMessage = {
  amountUsed: number;
  claimCode: string;
  description: string;
};

interface PaymentCouponMessageProps {
  couponMessages: CouponMessage[];
  purchaseHasShippingAddress: boolean;
}

const PaymentCouponMessage = ({ couponMessages, purchaseHasShippingAddress }: PaymentCouponMessageProps) => (
  <div className={css.wrapper}>
    {!purchaseHasShippingAddress && <p className={css.availablePromoMessagesLabel}>Available Promotions</p>}
    <ul className={css.promoMessageList}>
      {couponMessages.map(({ description, claimCode, amountUsed }) => (
        <li key={claimCode} className={cn(css.promoMessage, { [css.appliedPromoMessage]: purchaseHasShippingAddress })}>
          <span>{description}</span>
          <span className={css.promoMessageAmount}>
            {purchaseHasShippingAddress && ' - '}
            {toUSD(amountUsed)}
          </span>
        </li>
      ))}
    </ul>
  </div>
);

export default PaymentCouponMessage;
