import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { deepEqual } from 'fast-equals';

import PlaceOrder from 'components/checkout/OrderTotal/PlaceOrder';
import GiftOptions from 'components/checkout/GiftOptions';
import PaymentCouponCode from 'components/checkout/payment/PaymentCouponCode';
import { isAssigned, triggerAssignment } from 'actions/ab';
import { setHeaderFooterVisibility, setMinifiedHeaderFooter } from 'actions/headerfooter';
import { onToggleGiftOptions } from 'store/ducks/giftoptions/actions';
import { titaniteView } from 'apis/amethyst';
import { CHECKOUT_PAGE } from 'constants/amethystPageTypes';
import { PageLoader } from 'components/Loader';
import SiteAwareMetadata from 'components/SiteAwareMetadata';
import {
  hasSingleCV,
  isDigitalDeliveryOnlyCart,
  isGiftCardOnlyCart,
  isNonRetailOnlyCart,
  isPlacingOrderBlockedByShipping,
  isReadyToSubmitCheckout
} from 'helpers/CheckoutUtils';
import ChargeSummary from 'components/checkout/ChargeSummary';
import SplitReview from 'containers/checkout/SplitReview';
import MultiPaymentMethod from 'containers/checkout/MultiPaymentMethod';
import ShippingAddress from 'containers/checkout/ShippingAddress';
import UseAsDefaults from 'containers/checkout/UseAsDefaults';
import SectionDivider from 'components/checkout/SectionDivider';
import FeatureFeedback from 'components/FeatureFeedback';
import {
  CART_STEP,
  CHECKOUT_STEP_MAP,
  EDIT_ADDRESS_STEP,
  EDIT_BILLING_ADDRESS_STEP,
  LIST_ADDRESS_STEP,
  NEW_ADDRESS_STEP,
  NEW_BILLING_ADDRESS_STEP,
  PAYMENT_STEP,
  REVIEW_STEP,
  SELECT_BILLING_ADDRESS_STEP,
  TBD_STEP
} from 'constants/checkoutFlow';
import { AFTERPAY_MAXIMUM, AFTERPAY_MINIMUM, USE_PROMO_BALANCE_SESSION_STORAGE_KEY } from 'constants/appConstants';
import { PAYMENT_PLAN_MISSING } from 'constants/constraintViolations';
import { AFTER_PAY_BUTTON_JS, AFTER_PAY_JS, PAYPAL_JS } from 'constants/externalJavascriptFiles';
import { HYDRA_HIDE_PAYPAL, HYDRA_VIP_EMAIL_SUBSCRIPTIONS } from 'constants/hydraTests';
import { loadAmazonPayJavaScript } from 'helpers/AmazonPayUtils';
import { getFromSessionStorage, isDesktop } from 'helpers/ClientUtils';
import {
  configurePurchase,
  fetchCheckoutContent,
  onAfterpayButtonClick,
  onAfterPayButtonIsLoaded,
  onAfterPayButtonIsUnableToLoad,
  onAfterpayError,
  onAfterpayImpression,
  onAfterPayIsLoaded,
  onAfterPayIsUnableToLoad,
  onAfterpayPopupClosed,
  onAfterpaySuccess,
  onAmazonPayIsLoaded,
  onAmazonPayIsRedirecting,
  onAmazonPayIsUnableToLoad,
  onBeginConfirmingPayPal,
  onChangeQuantityEvent,
  onCheckoutJustLoaded,
  onCheckoutPromiseDateHasChanged,
  onCheckoutShipOptionsLoaded,
  onFetchAkitaEstimate,
  onHadConstraintViolation,
  onHadShipOptionAutoSelected,
  onInitAmazonPay,
  onMaxStepIsCartStep,
  onMoveToFavoritesClick,
  onPayPalIsLoaded,
  onPayPalIsUnableToLoad,
  onRedeemPoints,
  onRemoveItem,
  onRequestRedeemableRewards,
  onSendToDesiredPage,
  onSendToMaxAvailableStep,
  onToggleItemsEvent,
  onTogglePromoBox,
  onToggleVipEnrollmentCheckbox,
  onUseShippingOptionClick,
  placeCheckoutOrder,
  resetCheckout,
  saveAfterpayPaymentInstrument,
  setUsePromoBalance,
  trackCheckoutDeliveryPromise,
  trackEventNotMaxAvailable,
  trackVipTermsClick
} from 'store/ducks/checkout/actions';
import { isPromiseSameOrFasterThan } from 'store/ducks/shipOption/reducer';
import { forceAssignMafiaInferiorSuppression, forceAssignSplitShipments, forceAssignVipEnrollmentAtCheckout } from 'actions/weblab';
import { getCleanPath, getStepFromPath } from 'helpers/CheckoutFlowControl';
import { CREDIT_CARD } from 'constants/paymentMethodTypes';
import ExpressCheckout from 'components/checkout/payment/ExpressCheckout';
import { loadScript } from 'helpers/ScriptUtils';
import { firePixelEvent } from 'actions/pixelServer';
import { setCartLoaded } from 'actions/cart';
import { inIframe } from 'helpers/InIframe';
import { fetchRewardsInfoV2 } from 'actions/account/rewards';

import css from 'styles/containers/checkout/checkout.scss';

export class Checkout extends Component {
  // prevent flickering of styles on server rendered checkout (previously `lilz`)
  static fetchDataOnServer(store) {
    return store.dispatch(setMinifiedHeaderFooter(true));
  }

  constructor(props) {
    super(props);
    this.shippingSectionRef = createRef();
    this.paymentSectionRef = createRef();
    this.reviewSectionRef = createRef();
    this.saveShippingRef = createRef();
    this.savePaymentRef = createRef();
  }

  state = {
    showPlaceOrderError: false
  };

  componentDidMount() {
    const {
      configurePurchase,
      resetCheckout,
      checkoutData: { content, isLoaded, usePromoBalance },
      location: { pathname, search },
      wasLastPageOrderConfirmation,
      triggerAssignment,
      forceAssignSplitShipments,
      fetchCheckoutContent,
      onAfterPayButtonIsLoaded,
      onAfterPayButtonIsUnableToLoad,
      onAfterPayIsLoaded,
      onAfterPayIsUnableToLoad,
      onAmazonPayIsLoaded,
      onAmazonPayIsUnableToLoad,
      onAmazonPayIsRedirecting,
      onPayPalIsLoaded,
      onPayPalIsUnableToLoad,
      setMinifiedHeaderFooter,
      setUsePromoBalance,
      forceAssignMafiaInferiorSuppression,
      dispatchSetCartLoaded,
      fetchRewardsInfoV2,
      rewardsInfo
    } = this.props;
    const { amazonCheckoutSessionId, pid, amazonPayConfirmed } = queryString.parse(search);
    const {
      marketplace: {
        hasMinifiedHF,
        checkout: { payPalClientId, allowForceAssignSplitShipments, allowAfterPay, allowAmazonPay, allowPayPal }
      }
    } = this.context;

    const autoPlaceOrder = amazonPayConfirmed === 'true' && pid && amazonCheckoutSessionId;

    if (!rewardsInfo) {
      // Fetching Reward Info to allow permit tracking of content square custom variables.
      // Please refer to ContentSquare.tsx
      fetchRewardsInfoV2();
    }

    titaniteView();
    triggerAssignment(HYDRA_HIDE_PAYPAL);

    forceAssignMafiaInferiorSuppression();

    // amazonpay dialog completion causes checkout reload. Prevent usePromoBalance decision from being overridden.
    const storedUsePromoBalance = getFromSessionStorage(USE_PROMO_BALANCE_SESSION_STORAGE_KEY, usePromoBalance);
    if (storedUsePromoBalance !== usePromoBalance) {
      setUsePromoBalance(storedUsePromoBalance);
    }

    if (allowPayPal) {
      loadScript({
        onLoadCallback: onPayPalIsLoaded,
        onLoadErrorCallback: onPayPalIsUnableToLoad,
        src: `${PAYPAL_JS}?client-id=${payPalClientId}&disable-funding=credit,card&intent=authorize&commit=false`
      });
    }

    if (allowAfterPay) {
      loadScript({
        onLoadCallback: onAfterPayIsLoaded,
        onLoadErrorCallback: onAfterPayIsUnableToLoad,
        src: AFTER_PAY_JS
      });
      loadScript({
        onLoadCallback: onAfterPayButtonIsLoaded,
        onLoadErrorCallback: onAfterPayButtonIsUnableToLoad,
        src: AFTER_PAY_BUTTON_JS
      });
    }

    if (allowAmazonPay) {
      loadAmazonPayJavaScript({
        onLoadCallback: onAmazonPayIsLoaded,
        onLoadErrorCallback: onAmazonPayIsUnableToLoad
      });
    }

    if (inIframe()) {
      const { setHeaderFooterVisibility } = this.props;
      setHeaderFooterVisibility(false);
    }

    if (hasMinifiedHF) {
      setMinifiedHeaderFooter(true);
    }

    if (wasLastPageOrderConfirmation) {
      return;
    }

    if (isLoaded) {
      resetCheckout();
    }

    if (allowForceAssignSplitShipments) {
      forceAssignSplitShipments();
    }

    if (!content) {
      fetchCheckoutContent();
    }

    if (autoPlaceOrder) {
      onAmazonPayIsRedirecting();
    }

    if (!isDesktop()) {
      this.scrollToCurrentStep(getCleanPath(pathname));
    }

    configurePurchase({
      purchaseId: pid,
      amazonCheckoutSessionId,
      autoPlaceOrder,
      includePaymentsAndAddresses: !autoPlaceOrder,
      includeAllPaymentTypes: !autoPlaceOrder
    });
    firePixelEvent('checkout');
    // cart does not get fetched on checkout, this sets it manually as loaded so our CS events fire
    dispatchSetCartLoaded();
  }

  componentDidUpdate(prevProps) {
    const {
      checkoutData: {
        isLoaded: prevIsLoaded,
        purchase: { purchaseId: prevPurchaseId, constraintViolations: prevConstraintViolations = [], shippingAddressId: prevShippingAddressId }
      },
      shipOption: {
        changedPromiseDates: prevChangedPromiseDates,
        isVipEnrollmentSelected: prevIsVipEnrollmentSelected,
        isLoaded: prevIsShipOptionsLoaded,
        isLoading: prevIsShipOptionsLoading
      },
      location: { pathname: prevPathname, search: prevSearch }
    } = prevProps;

    const {
      trackEventNotMaxAvailable,
      onCheckoutJustLoaded,
      onCheckoutPromiseDateHasChanged,
      onMaxStepIsCartStep,
      onSendToDesiredPage,
      onSendToMaxAvailableStep,
      checkoutData: {
        maxAvailableStep,
        isAfterPayLoaded,
        isAfterPayUnableToLoad,
        isAfterPayButtonLoaded,
        isAfterPayButtonUnableToLoad,
        isAmazonPayLoaded,
        isAmazonPayUnableToLoad,
        isLoaded: nextIsLoaded,
        purchase: { purchaseId, constraintViolations = [], chargeSummary, shippingAddressId: nextShippingAddressId, shipmentSpeed }
      },
      shipOption: {
        changedPromiseDates,
        lineItemDeliveryOptions,
        isVipEnrollmentSelected,
        isLoaded: isShipOptionsLoaded,
        isLoading: isShipOptionsLoading,
        hasAutoSelectedShipOption
      },
      location: { pathname: nextPathname, search },
      onCheckoutShipOptionsLoaded,
      onFetchAkitaEstimate,
      onRequestRedeemableRewards,
      triggerAssignment,
      trackCheckoutDeliveryPromise,
      onHadConstraintViolation,
      configurePurchase,
      onHadShipOptionAutoSelected,
      onAfterPayIsLoaded,
      onAfterPayIsUnableToLoad,
      onAfterPayButtonIsLoaded,
      onAfterPayButtonIsUnableToLoad,
      onAmazonPayIsLoaded,
      onAmazonPayIsUnableToLoad,
      forceAssignVipEnrollmentAtCheckout,
      rewardsInfo
    } = this.props;

    const {
      marketplace: {
        checkout: { allowRewardsRedemption, allowAfterPay, allowAmazonPay, allowForceSyncOfVipAtCheckoutHydraTest },
        hasShippingDowngrades
      }
    } = this.context;
    const isJustLoaded = nextIsLoaded && prevIsLoaded !== nextIsLoaded;
    const isJustGotShippingAddressId = nextShippingAddressId && prevShippingAddressId !== nextShippingAddressId;
    const isJustGotDeliveryOptions = isShipOptionsLoaded && !prevIsShipOptionsLoaded;

    if (allowAmazonPay && !isAmazonPayLoaded && !isAmazonPayUnableToLoad) {
      loadAmazonPayJavaScript({
        onLoadCallback: onAmazonPayIsLoaded,
        onLoadErrorCallback: onAmazonPayIsUnableToLoad
      });
    }

    if (allowAfterPay && !isAfterPayLoaded && !isAfterPayUnableToLoad) {
      loadScript({
        onLoadCallback: onAfterPayIsLoaded,
        onLoadErrorCallback: onAfterPayIsUnableToLoad,
        src: AFTER_PAY_JS
      });
    }

    if (allowAfterPay && !isAfterPayButtonLoaded && !isAfterPayButtonUnableToLoad) {
      loadScript({
        onLoadCallback: onAfterPayButtonIsLoaded,
        onLoadErrorCallback: onAfterPayButtonIsUnableToLoad,
        src: AFTER_PAY_BUTTON_JS
      });
    }

    const { amazonPayConfirmed } = queryString.parse(search);

    if (amazonPayConfirmed === 'true') {
      // we were redirected from amazon pay and after calling /configure, the order will auto be placed, no need to do more below
      return;
    }

    if (purchaseId && prevPurchaseId && purchaseId !== prevPurchaseId) {
      onSendToMaxAvailableStep(maxAvailableStep);
    }

    if (isShipOptionsLoaded && !prevIsShipOptionsLoaded) {
      lineItemDeliveryOptions.forEach(deliveryOption => {
        const { deliveryOptions, filteredShipSpeeds = [], isDigitalDelivery, lineItemIds } = deliveryOption;

        if (!hasAutoSelectedShipOption && !isDigitalDelivery && filteredShipSpeeds?.includes(shipmentSpeed)) {
          let optionToUse = {};
          for (let i = deliveryOptions.length - 1; i >= 0; i--) {
            if (deliveryOptions[i].isFiltered === false) {
              optionToUse = deliveryOptions[i];
              break;
            }
          }

          const { id: shipmentOptionId, name } = optionToUse;
          if (shipmentOptionId && name !== shipmentSpeed) {
            onHadShipOptionAutoSelected();
            configurePurchase({
              shipmentOptionId,
              shipmentOptionLineItemIds: lineItemIds,
              filterShipOptionsOnFirstLoad: true
            });
          }
        }
      });

      if (allowForceSyncOfVipAtCheckoutHydraTest && !inIframe() && rewardsInfo?.canEnroll) {
        forceAssignVipEnrollmentAtCheckout();
        triggerAssignment(HYDRA_VIP_EMAIL_SUBSCRIPTIONS);
      }
    }

    if (isShipOptionsLoaded && isVipEnrollmentSelected !== prevIsVipEnrollmentSelected) {
      lineItemDeliveryOptions.forEach(deliveryOption => {
        const { deliveryOptions, isDigitalDelivery } = deliveryOption;

        if (!isDigitalDelivery) {
          let optionToUse = deliveryOptions[0];
          for (let i = 1; i < deliveryOptions.length; i++) {
            const { isFiltered, price, displayString } = deliveryOptions[i];
            if (!isFiltered && price <= optionToUse.price && isPromiseSameOrFasterThan(displayString, optionToUse.displayString)) {
              optionToUse = deliveryOptions[i];
            }
          }

          const { id: shipmentOptionId, name } = optionToUse;
          if (shipmentOptionId && name !== shipmentSpeed) {
            configurePurchase({ shipmentOptionId });
          }
        }
      });
    }

    if (!deepEqual(prevConstraintViolations, constraintViolations)) {
      constraintViolations.forEach(({ name }) => {
        onHadConstraintViolation(name);
      });
    }

    if (!nextIsLoaded || !nextPathname.includes('checkout')) {
      return;
    }

    if (isJustGotShippingAddressId) {
      trackCheckoutDeliveryPromise();
    }

    if (isJustLoaded) {
      onCheckoutJustLoaded();

      const isInIframe = inIframe();

      if (hasShippingDowngrades && !isInIframe) {
        onFetchAkitaEstimate();
      }

      if (allowRewardsRedemption && !isInIframe) {
        onRequestRedeemableRewards();
      }
    }

    if (maxAvailableStep === CART_STEP) {
      onMaxStepIsCartStep();
      return;
    }

    if (isJustGotDeliveryOptions || (!isShipOptionsLoading && prevIsShipOptionsLoading)) {
      onCheckoutShipOptionsLoaded();
    }

    if (changedPromiseDates?.length && !deepEqual(changedPromiseDates, prevChangedPromiseDates)) {
      changedPromiseDates.forEach(data => onCheckoutPromiseDateHasChanged(data));
    }

    const stepForPrevPath = getStepFromPath(prevPathname);
    const stepForNextPath = getStepFromPath(nextPathname);

    if (stepForNextPath > maxAvailableStep) {
      onSendToMaxAvailableStep(maxAvailableStep);
      return;
    } else if (stepForNextPath !== stepForPrevPath) {
      trackEventNotMaxAvailable(stepForNextPath);
    }

    const urlSearchParams = new URLSearchParams(search);
    if (isJustLoaded) {
      const step = stepForNextPath === TBD_STEP ? REVIEW_STEP : stepForNextPath;
      onSendToDesiredPage(step, urlSearchParams);
    } else if (
      // Makes sure that if there are no constraints and no balance (bc of discounts, gc, zaw code)
      // and the user happens to be in the PAYMENT STEP the checkout step is set to REVIEW
      chargeSummary?.grandTotal === 0 &&
      this.isPaymentStep(nextPathname) &&
      Object.keys(constraintViolations).length === 0
    ) {
      onSendToDesiredPage(REVIEW_STEP, urlSearchParams);
    }

    // This delay is required here to fix a scrolling issue with Safari on iOS 14+
    // We can remove this if we stop shrinking/expanding checkout sections
    if ((prevPathname !== nextPathname && !this.state.showPlaceOrderError) || prevSearch !== search) {
      setTimeout(() => this.scrollToCurrentStep(getCleanPath(nextPathname), getCleanPath(prevPathname)), 100);
    }
  }

  componentWillUnmount() {
    const { resetCheckout, setHeaderFooterVisibility, setMinifiedHeaderFooter } = this.props;
    resetCheckout();
    setHeaderFooterVisibility(true);
    setMinifiedHeaderFooter(false);
    document.body.classList.remove('hideMobileHeader');
  }

  isListAddressStep = pathname => getCleanPath(pathname) === CHECKOUT_STEP_MAP[LIST_ADDRESS_STEP];

  isEditAddressStep = pathname => getCleanPath(pathname) === CHECKOUT_STEP_MAP[EDIT_ADDRESS_STEP];

  isNewAddressStep = pathname => getCleanPath(pathname) === CHECKOUT_STEP_MAP[NEW_ADDRESS_STEP];

  isAddressStep = pathname => this.isListAddressStep(pathname) || this.isEditAddressStep(pathname) || this.isNewAddressStep(pathname);

  isReviewStep = pathname => getCleanPath(pathname) === CHECKOUT_STEP_MAP[REVIEW_STEP];

  isPaymentStep = pathname =>
    getCleanPath(pathname) === CHECKOUT_STEP_MAP[PAYMENT_STEP] ||
    getCleanPath(pathname) === CHECKOUT_STEP_MAP[EDIT_BILLING_ADDRESS_STEP] ||
    getCleanPath(pathname) === CHECKOUT_STEP_MAP[NEW_BILLING_ADDRESS_STEP] ||
    getCleanPath(pathname) === CHECKOUT_STEP_MAP[SELECT_BILLING_ADDRESS_STEP];

  scrollToCurrentStep = (pathname, prevPathName = '') => {
    if (this.isPaymentStep(pathname) || (this.isReviewStep(pathname) && this.isPaymentStep(prevPathName))) {
      this.onScrollToPayment(false);
    } else if (this.isAddressStep(pathname) || (this.isReviewStep(pathname) && this.isAddressStep(prevPathName))) {
      this.onScrollToShipment(false);
    }
  };

  // Scroll save step into view when needs to be saved
  scrollToSaveStep = saveRef => {
    const {
      marketplace: { name: marketplaceName }
    } = this.context;

    // Scrolls step into view
    window.scrollTo({
      behavior: 'smooth',
      top: window.scrollY + saveRef.getBoundingClientRect().top - 100
    });

    // Animates (using basic tailwind classes)
    const animation = [`animate-[pulse_550ms_ease-out_4]`];
    if (marketplaceName === 'zappos.com') animation.push(`[&>button]:!bg-button-hover`);
    if (!saveRef.classList.contains(...animation)) {
      saveRef.classList.add(...animation);
      setTimeout(() => saveRef.classList.remove(...animation), 2200);
    }
  };

  onScrollToShipment = (showPlaceOrderError = false) => {
    showPlaceOrderError && this.setState({ showPlaceOrderError });
    if (showPlaceOrderError && this.saveShippingRef?.current) {
      this.scrollToSaveStep(this.saveShippingRef?.current);
    } else {
      this.shippingSectionRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  onScrollToPayment = (showPlaceOrderError = false) => {
    showPlaceOrderError && this.setState({ showPlaceOrderError });
    if (showPlaceOrderError && this.savePaymentRef?.current) {
      this.scrollToSaveStep(this.savePaymentRef?.current);
    } else {
      this.paymentSectionRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  onScrollToReview = () => this.reviewSectionRef?.current?.scrollIntoView({ behavior: 'smooth' });

  onDeleteItem = e => {
    e.preventDefault();
    const {
      target: {
        dataset: { lineItemId }
      }
    } = e;
    const quantity = '0';
    const {
      configurePurchase,
      onRemoveItem,
      onChangeQuantityEvent,
      checkoutData: { isFetchingCheckoutData, productsByLineItem },
      giftOptions: { giftMessage }
    } = this.props;

    if (!isFetchingCheckoutData) {
      onRemoveItem(productsByLineItem[lineItemId]);
      onChangeQuantityEvent(quantity, productsByLineItem[lineItemId].quantity, productsByLineItem[lineItemId]);
      const quantityUpdate = { lineItemId, quantity };
      const params = { quantityUpdate, advanceOnSuccess: false };
      if (giftMessage) {
        const options = buildGiftOptions(giftMessage, productsByLineItem, lineItemId); // we store gift message on only 1 item so we must move the message around as items are removed
        params.giftOptions = options;
      }
      configurePurchase(params);
    }
  };

  onChangeQuantity = (e, lineItemId) => {
    const {
      configurePurchase,
      onChangeQuantityEvent,
      checkoutData: { isFetchingCheckoutData, productsByLineItem },
      giftOptions: { giftMessage }
    } = this.props;
    const {
      target: { value: quantity }
    } = e;

    if (!isFetchingCheckoutData) {
      onChangeQuantityEvent(quantity, productsByLineItem[lineItemId].quantity, productsByLineItem[lineItemId]);
      const quantityUpdate = { lineItemId, quantity };
      const params = { quantityUpdate, advanceOnSuccess: false };
      if (quantity === '0' && giftMessage) {
        const options = buildGiftOptions(giftMessage, productsByLineItem, lineItemId); // we store gift message on only 1 item so we must move the message around as items are removed
        params.giftOptions = options;
      }
      configurePurchase(params);
    }
  };

  onAddCouponClick = (coupon, totalClickCount) => {
    const { configurePurchase } = this.props;
    configurePurchase({ coupon, totalClickCount, advanceOnSuccess: true });
  };

  onRedeemRewardsPoints = spendPoints => {
    this.props.onRedeemPoints(spendPoints);
  };

  onToggleItems = isShown => {
    this.props.onToggleItemsEvent(isShown);
  };

  onPlaceOrderClick = e => {
    e.preventDefault();
    const { placeCheckoutOrder } = this.props;
    placeCheckoutOrder();
  };

  onRestartCheckout = () => {
    const { resetCheckout, configurePurchase } = this.props;
    resetCheckout();
    configurePurchase({ includePaymentsAndAddresses: true });
  };

  moveToFavoritesClick = ({
    currentTarget: {
      dataset: { asin, lineItemId }
    }
  }) => {
    const {
      onMoveToFavoritesClick,
      checkoutData: { productsByLineItem },
      giftOptions: { giftMessage }
    } = this.props;
    const params = { addedFrom: CHECKOUT_PAGE, asin, lineItemId };
    if (giftMessage) {
      const options = buildGiftOptions(giftMessage, productsByLineItem, lineItemId); // we store gift message on only 1 item so we must move the message around as items are removed
      params.giftOptions = options;
    }
    onMoveToFavoritesClick(params);
  };

  onToggleGiftOptionsBox = () => {
    this.props.onToggleGiftOptions();
  };

  onSavingGiftOptions = giftMessage => {
    const {
      configurePurchase,
      checkoutData: { isFetchingCheckoutData, productsByLineItem }
    } = this.props;
    if (isFetchingCheckoutData) {
      return;
    }
    const options = buildGiftOptions(giftMessage, productsByLineItem);
    configurePurchase({
      giftOptions: [...options],
      advanceOnSuccess: false,
      isSavingGiftOptions: true
    });
  };

  onRemovingGiftOptions = () => {
    const {
      configurePurchase,
      checkoutData: { isFetchingCheckoutData, productsByLineItem }
    } = this.props;
    if (isFetchingCheckoutData) {
      return;
    }
    const options = [];
    Object.keys(productsByLineItem).forEach(lineItemId => {
      const { quantity } = productsByLineItem[lineItemId];
      const option = {
        quantity,
        lineItemId,
        removeGiftOptions: true,
        giftReceipt: false,
        giftMessage: ''
      };
      options.push(option);
    });
    configurePurchase({
      giftOptions: [...options],
      advanceOnSuccess: false,
      isRemovingGiftOptions: true,
      isSavingGiftOptions: true
    });
  };

  onSelectAndUseShippingOptionClick = (event, shipmentOptionLineItemIds) => {
    const {
      currentTarget: {
        dataset: { shippingPromise, shipmentOptionId }
      }
    } = event;

    const { configurePurchase, onUseShippingOptionClick } = this.props;
    onUseShippingOptionClick(shippingPromise);
    configurePurchase({ shipmentOptionId, shipmentOptionLineItemIds });
  };

  onVipEnrollmentCheckboxClick = isSelected => {
    this.props.onToggleVipEnrollmentCheckbox(isSelected);
  };

  onVipEnrollmentTermsClick = () => {
    this.props.trackVipTermsClick();
  };

  onSubmitAfterpayPayment = instrument => {
    this.props.saveAfterpayPaymentInstrument({ instrument });
  };

  onAfterpaySectionButtonClick = params => {
    this.props.onAfterpayButtonClick(params);
  };

  onConfirmPayPal = data => {
    const { configurePurchase, payment, checkoutData, onBeginConfirmingPayPal } = this.props;
    const { payerID, facilitatorAccessToken } = data;

    const { payPalPaymentMethod } = payment;
    const { authenticationSourceId, purchaseId } = checkoutData;
    const { paymentInstrumentId: payPalPaymentInstrumentId } = payPalPaymentMethod || {};

    const paymentMethods = [
      {
        paymentInstrumentId: payPalPaymentInstrumentId,
        paymentMethodCode: 'PayStation'
      }
    ];

    const confirmPayPalParams = {
      authenticationSourceId,
      confirmRedirect: true,
      confirmRedirectParameters: {
        token: facilitatorAccessToken,
        payerID
      },
      generateRedirect: false,
      paymentMethods,
      purchaseId
    };
    onBeginConfirmingPayPal();
    configurePurchase({
      paymentMethods,
      advanceOnSuccess: true,
      confirmPayPalParams
    });
  };

  render() {
    const {
      accountIsLoading,
      customerInfo,
      isAssignedVipEmailSubscriptions: includeVipEmailSubscriptions,
      isHydraHidePayPal,
      giftOptions: { giftMessage },
      checkoutData: {
        cartType,
        asinErrors,
        constraintViolations,
        isDiscountFullBalance,
        isFullBalanceSelected,
        isAfterPayLoaded,
        isAfterPayUnableToLoad,
        isAfterPayButtonLoaded,
        isAfterPayButtonUnableToLoad,
        isAmazonPayLoaded,
        isAmazonPayUnableToLoad,
        isAmazonPayRedirecting,
        isPayPalLoaded,
        isPayPalUnableToLoad,
        isEnrolledInRewards,
        isLoading,
        isPlacingOrder,
        showPromoSuccessMessage,
        paymentMethodType,
        purchaseType,
        purchase: { amazonPay, chargeSummary = {}, nameOnAccount, payPal, productList, purchaseId, shippingAddress, shippingAddressId },
        isLoaded,
        redeemableRewards,
        showExpressCheckout,
        spendPointDollarValue,
        spendPoints,
        usePromoBalance
      },
      shipOption: { isVipEnrollmentSelected, isVipEnrollmentFeatureEnabled },
      killswitch: { autoExpandCheckoutGiftOptions, autoExpandCheckoutPromoBox },
      location: { pathname },
      onAfterpayError,
      onAfterpayImpression,
      onAfterpayPopupClosed,
      onAfterpaySuccess,
      onInitAmazonPay,
      onTogglePromoBox,
      payment: { savedPayments },
      isPaymentPlanMissing,
      wasLastPageOrderConfirmation,
      rewards: { isRedeemingRewards, redeemingRewardsStatus },
      isShippingAddressMissing
    } = this.props;

    const hasNoBalanceFromSavedDiscounts = isDiscountFullBalance && isFullBalanceSelected;
    const currentStep = getStepFromPath(pathname);
    const isOrderReadyToSubmit =
      !isPlacingOrder &&
      !isLoading &&
      isReadyToSubmitCheckout({
        currentStep,
        constraintViolations,
        purchaseType,
        hasNoBalanceFromSavedDiscounts
      }) &&
      paymentMethodType !== 'NEW_CREDIT_CARD';
    const { grandTotal } = chargeSummary;
    const isReviewStep = this.isReviewStep(pathname);

    const {
      marketplace: {
        checkout: {
          afterPayMerchantPublicKey,
          allowAddressAutocomplete,
          allowAfterPay,
          allowAmazonPay,
          allowFeatureFeedback,
          allowGiftOptions,
          allowPayPal,
          allowRewardsRedemption,
          showDiscountMessageInChargeSummary
        }
      },
      testId
    } = this.context;

    const isInIframe = inIframe();
    const isGcOnlyCart = isGiftCardOnlyCart(cartType);
    const showAddAddressMessage = showDiscountMessageInChargeSummary && !shippingAddressId && !isDigitalDeliveryOnlyCart(cartType);
    const doesCartContainGiftCard = isNonRetailOnlyCart(cartType);
    const showVipEnrollmentCheckbox = shippingAddressId && isVipEnrollmentFeatureEnabled;
    const isMobileView = !isDesktop() && isOrderReadyToSubmit; // ToDo: add other mobile view conditions if missing any

    if (wasLastPageOrderConfirmation) {
      return (
        <div className={css.orderPlaced}>
          Your order has been placed. If you wish to modify or cancel it, please do so from your <Link to="/orders">order history</Link>.
        </div>
      );
    }

    const showPageLoader =
      !isLoaded ||
      (allowPayPal && !isPayPalLoaded && !isPayPalUnableToLoad) ||
      (allowAmazonPay && isAmazonPayRedirecting) ||
      (allowAmazonPay && !isAmazonPayLoaded && !isAmazonPayUnableToLoad) ||
      (allowAfterPay && !isAfterPayLoaded && !isAfterPayUnableToLoad) ||
      (allowAfterPay && !isAfterPayButtonLoaded && !isAfterPayButtonUnableToLoad);

    if (showPageLoader) {
      return (
        <>
          <PageLoader />
        </>
      );
    }

    const isAfterpayLoading =
      allowAfterPay && ((!isAfterPayLoaded && !isAfterPayUnableToLoad) || (!isAfterPayButtonLoaded && !isAfterPayButtonUnableToLoad));
    const isAfterpayEligibleByPrice = grandTotal >= AFTERPAY_MINIMUM && grandTotal <= AFTERPAY_MAXIMUM;
    const isInsufficientCoverage = constraintViolations?.hasOwnProperty('InsufficientCoverage') && Object.keys(constraintViolations).length === 1;

    const afterpayParams = {
      accountIsLoading,
      afterPayMerchantPublicKey,
      customerInfo,
      doesCartContainGiftCard,
      grandTotal,
      isAfterpayEligibleByPrice,
      isAfterpayLoading,
      isCheckoutLoading: isLoading,
      nameOnAccount,
      onAfterpayButtonClick: this.onAfterpaySectionButtonClick,
      onAfterpayError,
      onAfterpayImpression,
      onAfterpayPopupClosed,
      onAfterpaySuccess,
      onSubmitAfterpayPayment: this.onSubmitAfterpayPayment,
      productList,
      purchaseId,
      shippingAddress
    };

    const payPalParams = {
      ...payPal,
      onConfirmPayPal: this.onConfirmPayPal
    };

    // Whether or not the charge summary/place order buttons should display the alternate version
    const paymentMissing = isPaymentPlanMissing || this.isPaymentStep(pathname);
    const shippingMissing = isShippingAddressMissing || this.isAddressStep(pathname);
    const isAlternate = paymentMissing || shippingMissing;

    // Resets the place order error message if all constraints have passed
    if (!paymentMissing && !shippingMissing && this.state.showPlaceOrderError) {
      this.setState({ showPlaceOrderError: false });
    }

    const placeOrderBtnParams = {
      isAlternate,
      afterpayParams,
      amazonPay,
      hasNoBalanceFromSavedDiscounts: hasNoBalanceFromSavedDiscounts,
      hasSavedPayments: !!savedPayments.length,
      onScrollToPayment: this.onScrollToPayment,
      onScrollToShipment: this.onScrollToShipment,
      isLoading: isLoading,
      isOrderReadyToSubmit: isOrderReadyToSubmit,
      isPlacingOrder: isPlacingOrder,
      onPlaceOrderClick: this.onPlaceOrderClick,
      purchaseType: purchaseType,
      payPalParams,
      paymentMethodType: paymentMethodType,
      onInitAmazonPay: onInitAmazonPay,
      isInsufficientCoverage,
      isDiscountFullBalance,
      usePromoBalance,
      isPaymentPlanMissing: paymentMissing,
      isShippingAddressMissing: shippingMissing,
      showPlaceOrderError: this.state.showPlaceOrderError
    };

    const placeOrderBtnSticky = <PlaceOrder isChargeSummary={false} isOrderTotal={true} {...placeOrderBtnParams} />;
    const placeOrderBtnChargeSummary = <PlaceOrder isChargeSummary isOrderTotal={false} {...placeOrderBtnParams} />;

    const paymentCouponCode = (
      <PaymentCouponCode
        isGcOnlyCart={isGcOnlyCart}
        hideRewardsRedemption={!isEnrolledInRewards || !allowRewardsRedemption || isInIframe}
        constraintViolations={constraintViolations}
        isRedeemingRewards={isRedeemingRewards}
        openByDefault={!!autoExpandCheckoutPromoBox}
        onAddCouponClick={this.onAddCouponClick}
        showPromoSuccessMessage={showPromoSuccessMessage}
        onRedeemRewardsPoints={this.onRedeemRewardsPoints}
        onTogglePromoBox={onTogglePromoBox}
        purchaseDataIsLoading={isLoading}
        purchaseType={purchaseType}
        redeemableRewards={redeemableRewards}
        redeemingRewardsStatus={redeemingRewardsStatus}
        spendPointDollarValue={spendPointDollarValue}
        spendPoints={spendPoints}
      />
    );

    return (
      <SiteAwareMetadata>
        <div className={css.page}>
          <div className={css.pageTitleWrapper}>
            <h1 className={css.title}>Checkout</h1>
            <div className={css.container}>
              <div className={css.spcWrapper}>
                <SectionDivider ref={this.shippingSectionRef} />
                {!isInIframe && showExpressCheckout && (
                  <ExpressCheckout
                    amazonPay={amazonPay}
                    isAmazonPayLoaded={isAmazonPayLoaded}
                    isPayPalLoaded={isPayPalLoaded && !isHydraHidePayPal}
                    onInitAmazonPay={onInitAmazonPay}
                    onConfirmPayPal={this.onConfirmPayPal}
                  />
                )}
                <ShippingAddress
                  onScrollToShipment={this.onScrollToShipment}
                  saveShippingRef={this.saveShippingRef}
                  allowAddressAutocomplete={allowAddressAutocomplete}
                />
                <SectionDivider ref={this.reviewSectionRef} />
                <SplitReview
                  isReviewStep={isReviewStep}
                  showVipEnrollmentCheckbox={!isMobileView && showVipEnrollmentCheckbox}
                  isVipEnrollmentSelected={isVipEnrollmentSelected}
                  includeVipEmailSubscriptions={includeVipEmailSubscriptions}
                  onVipEnrollmentCheckboxClick={this.onVipEnrollmentCheckboxClick}
                  onVipEnrollmentTermsClick={this.onVipEnrollmentTermsClick}
                  isVipCheckboxTogglingDisabled={isLoading}
                  onChangeQuantity={this.onChangeQuantity}
                  onDeleteItem={this.onDeleteItem}
                  onMoveToFavoritesClick={this.moveToFavoritesClick}
                  onSelectAndUseShippingOptionClick={this.onSelectAndUseShippingOptionClick}
                  purchaseType={purchaseType}
                  giftMessageComponent={
                    allowGiftOptions && (
                      <GiftOptions
                        customerName={nameOnAccount}
                        openByDefault={!!autoExpandCheckoutGiftOptions}
                        onToggleBox={this.onToggleGiftOptionsBox}
                        purchaseDataIsLoading={isLoading}
                        onSavingGiftOptions={this.onSavingGiftOptions}
                        onRemovingGiftOptions={this.onRemovingGiftOptions}
                      />
                    )
                  }
                />
                <SectionDivider ref={this.paymentSectionRef} />
                <MultiPaymentMethod paymentCouponCode={paymentCouponCode} savePaymentRef={this.savePaymentRef} />
                {!isInIframe && allowFeatureFeedback && (
                  <div className={css.feedbackMobile}>
                    <FeatureFeedback
                      additionalFeedbackMessage="Provide Additional Feedback"
                      autoOpenOnYesNoClick={true}
                      feedbackQuestionId="feedbackQuestionMobile"
                      completionMessage="Thank you for the feedback!"
                      feedbackQuestion="Is your checkout experience easy?"
                      feedbackType="CHECKOUT_EXPERIENCE_FEEDBACK"
                      pageType={CHECKOUT_PAGE}
                      source="checkout"
                      responseClass={css.feedbackResponseWrapper}
                    />
                  </div>
                )}
                {paymentMethodType === CREDIT_CARD && isReviewStep && (
                  <>
                    <UseAsDefaults />
                  </>
                )}
              </div>
              <div className={css.summaryWrapper}>
                <div className={css.chargeSummaryContainer} data-test-id={testId('chargeSummaryContainer')}>
                  <div className={css.chargeSummaryWrapper}>
                    <ChargeSummary
                      giftMessage={giftMessage}
                      asinErrors={asinErrors}
                      isReviewStep={isReviewStep}
                      placeOrderBtn={placeOrderBtnChargeSummary}
                      placeOrderBtnSticky={placeOrderBtnSticky}
                      showVipEnrollmentCheckbox={isMobileView && showVipEnrollmentCheckbox}
                      isVipEnrollmentSelected={isVipEnrollmentSelected}
                      includeVipEmailSubscriptions={includeVipEmailSubscriptions}
                      onVipEnrollmentCheckboxClick={this.onVipEnrollmentCheckboxClick}
                      onVipEnrollmentTermsClick={this.onVipEnrollmentTermsClick}
                      isVipCheckboxTogglingDisabled={isLoading}
                      onPlaceOrderClick={this.onPlaceOrderClick}
                      isInsufficientCoverage={isInsufficientCoverage}
                      isDiscountFullBalance={isDiscountFullBalance}
                      usePromoBalance={usePromoBalance}
                      showAddAddressMessage={showAddAddressMessage}
                      paymentMethodType={paymentMethodType}
                      isAlternate={isAlternate}
                      isGcOnlyCart={isGcOnlyCart}
                    />
                  </div>
                  {!isInIframe && allowFeatureFeedback && (
                    <div className={css.feedback}>
                      <FeatureFeedback
                        additionalFeedbackMessage="Provide Additional Feedback"
                        autoOpenOnYesNoClick={true}
                        completionMessage="Thank you for the feedback!"
                        feedbackQuestion="Is your checkout experience easy?"
                        feedbackType="CHECKOUT_EXPERIENCE_FEEDBACK"
                        pageType={CHECKOUT_PAGE}
                        source="checkout"
                        responseClass={css.feedbackResponseWrapper}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </SiteAwareMetadata>
    );
  }
}

const buildGiftOptions = (giftMessage, productsByLineItem, excludeLineItemId) => {
  const options = [];
  let setGiftMessage = false;

  Object.keys(productsByLineItem).forEach(lineItemId => {
    const { quantity, giftMessagable } = productsByLineItem[lineItemId];

    if (lineItemId !== excludeLineItemId) {
      const option = {
        lineItemId,
        quantity,
        removeGiftOptions: false,
        giftReceipt: giftMessagable
      };

      if (giftMessagable && !setGiftMessage) {
        option.giftMessage = giftMessage;
        setGiftMessage = true;
      }

      options.push(option);
    }
  });
  return options;
};

function mapStateToProps(state) {
  const {
    account: { isLoading: accountIsLoading, customerInfo },
    address,
    checkoutData,
    checkoutData: { usePromoBalance },
    checkoutData: {
      purchase: { constraintViolations = [] }
    },
    giftOptions,
    shipOption,
    history: { wasLastPageOrderConfirmation },
    killswitch,
    sharedPayment: payment,
    rewards: { rewardsInfo },
    sharedRewards: rewards,
    router: { location }
  } = state;

  const isPaymentPlanMissing = hasSingleCV(constraintViolations, PAYMENT_PLAN_MISSING);
  const isShippingAddressMissing = isPlacingOrderBlockedByShipping(constraintViolations);
  const isAssignedVipEmailSubscriptions = isAssigned(HYDRA_VIP_EMAIL_SUBSCRIPTIONS, 1, state);
  const isHydraHidePayPal = isAssigned(HYDRA_HIDE_PAYPAL, 1, state);

  return {
    accountIsLoading,
    customerInfo,
    isAssignedVipEmailSubscriptions,
    isHydraHidePayPal,
    isShippingAddressMissing,
    isPaymentPlanMissing,
    address,
    killswitch,
    checkoutData,
    usePromoBalance,
    giftOptions,
    payment,
    rewardsInfo,
    rewards,
    shipOption,
    wasLastPageOrderConfirmation,
    location
  };
}

Checkout.contextTypes = {
  testId: PropTypes.func,
  marketplace: PropTypes.object
};

export default connect(mapStateToProps, {
  onInitAmazonPay,
  onAfterpayButtonClick,
  onAfterpayError,
  onAfterpayImpression,
  onAfterpayPopupClosed,
  onAfterpaySuccess,
  onAfterPayIsLoaded,
  onAfterPayIsUnableToLoad,
  onAfterPayButtonIsLoaded,
  onAfterPayButtonIsUnableToLoad,
  onAmazonPayIsLoaded,
  onAmazonPayIsUnableToLoad,
  onAmazonPayIsRedirecting,
  configurePurchase,
  onHadShipOptionAutoSelected,
  onChangeQuantityEvent,
  onCheckoutJustLoaded,
  onCheckoutPromiseDateHasChanged,
  onCheckoutShipOptionsLoaded,
  onFetchAkitaEstimate,
  onMaxStepIsCartStep,
  onMoveToFavoritesClick,
  onPayPalIsLoaded,
  onPayPalIsUnableToLoad,
  onRemoveItem,
  onRequestRedeemableRewards,
  saveAfterpayPaymentInstrument,
  onSendToDesiredPage,
  onSendToMaxAvailableStep,
  onToggleGiftOptions,
  onToggleItemsEvent,
  onToggleVipEnrollmentCheckbox,
  trackVipTermsClick,
  placeCheckoutOrder,
  resetCheckout,
  setHeaderFooterVisibility,
  setMinifiedHeaderFooter,
  onHadConstraintViolation,
  onTogglePromoBox,
  dispatchSetCartLoaded: setCartLoaded,
  trackCheckoutDeliveryPromise,
  trackEventNotMaxAvailable,
  forceAssignSplitShipments,
  forceAssignVipEnrollmentAtCheckout,
  onUseShippingOptionClick,
  onRedeemPoints,
  fetchCheckoutContent,
  fetchRewardsInfoV2,
  triggerAssignment,
  onBeginConfirmingPayPal,
  setUsePromoBalance,
  forceAssignMafiaInferiorSuppression
})(Checkout);
