import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Note from 'components/checkout/Note';

import css from 'styles/components/checkout/OrderTotal/termsAndConditions.scss';

const TermsAndConditions = (
  props,
  {
    testId = f => f,
    marketplace: {
      domain,
      checkout: {
        helpUrl: { conditionsOfUse, privacyNotice, privacyNoticeText, conditionsOfUseText = 'conditions of use' }
      }
    }
  }
) => {
  const { showSalesTaxNote, showAdditionalFeesNote } = props;
  const {
    purchase: {
      chargeSummary: { additionalCharges = [] }
    }
  } = useSelector(state => state?.checkoutData);
  const coRetailDeliveryFees = additionalCharges.find(charge => charge.description === 'CO Retail Delivery Fees');
  const { description: coFeeDescription, referenceUrl: coFeeReferenceUrl } = coRetailDeliveryFees || {};

  return (
    <div className={css.wrapper} data-test-id={testId('termsSection')}>
      By placing your order, you agree to {domain}.com&#8217;s{' '}
      <a href={privacyNotice} target="_blank" rel="noreferrer">
        {privacyNoticeText}
      </a>{' '}
      and{' '}
      <a href={conditionsOfUse} target="_blank" rel="noreferrer">
        {conditionsOfUseText}
      </a>
      . {showSalesTaxNote && <Note />}
      {showAdditionalFeesNote && !!coRetailDeliveryFees && (
        <>
          **
          <a href={coFeeReferenceUrl} target="_blank" rel="noreferrer">
            Information regarding {coFeeDescription}.
          </a>
        </>
      )}
    </div>
  );
};

TermsAndConditions.contextTypes = {
  testId: PropTypes.func,
  marketplace: PropTypes.object
};

export default TermsAndConditions;
