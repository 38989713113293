import { cn } from 'helpers/classnames';
import { AFTER_PAY, AMAZON_PAY, CREDIT_CARD, PAYPAL, THIRD_PARTY_PAYMENT_METHODS } from 'constants/paymentMethodTypes';
import AmazonPayAsPlaceOrder from 'components/checkout/payment/paymentAsList/AmazonPayAsPlaceOrder';
import AfterpayAsPlaceOrder from 'components/checkout/payment/paymentAsList/AfterpayAsPlaceOrder';
import PayPalAsPlaceOrder from 'components/checkout/payment/paymentAsList/PayPalAsPlaceOrder';
import useMartyContext from 'hooks/useMartyContext';
import UtilityStrokeInfoOutlineSmallIcon from 'tailwind/components/Icons/UtilityStrokeInfoOutlineSmallIcon';

import css from 'styles/components/checkout/OrderTotal/placeOrder.scss';

const PlaceOrder = params => {
  const { testId } = useMartyContext();
  const {
    isAlternate,
    afterpayParams,
    amazonPay,
    hasNoBalanceFromSavedDiscounts,
    isPaymentPlanMissing,
    onScrollToPayment,
    onScrollToShipment,
    isChargeSummary,
    isOrderTotal,
    isLoading,
    isOrderReadyToSubmit,
    isInsufficientCoverage,
    isDiscountFullBalance,
    isPlacingOrder,
    onInitAmazonPay,
    onPlaceOrderClick,
    payPalParams,
    paymentMethodType,
    purchaseType,
    usePromoBalance,
    isShippingAddressMissing,
    showPlaceOrderError
  } = params;

  // Controls the shipping/payment error messages (they only show when the user clicks the place order button)
  const showShippingError = isShippingAddressMissing && showPlaceOrderError;
  const showPaymentError = isPaymentPlanMissing && showPlaceOrderError;

  const disableDueToSavedBalanceCoversTotal = THIRD_PARTY_PAYMENT_METHODS.includes(paymentMethodType) && hasNoBalanceFromSavedDiscounts;

  if (purchaseType !== paymentMethodType) {
    if (isLoading) {
      return (
        <div className={css.wrapper} data-test-id={testId('placeOrderSection')}>
          <button type="button" disabled={true} className={css.button} data-test-id={testId('placeOrderButton')} onClick={onPlaceOrderClick}>
            loading...
          </button>
        </div>
      );
    }

    if (paymentMethodType === PAYPAL && !hasNoBalanceFromSavedDiscounts) {
      return <PayPalAsPlaceOrder isChargeSummary={isChargeSummary} isOrderTotal={isOrderTotal} isExpressCheckout={false} {...payPalParams} />;
    }

    if (paymentMethodType === AFTER_PAY && !hasNoBalanceFromSavedDiscounts && afterpayParams.shippingAddress) {
      return <AfterpayAsPlaceOrder isChargeSummary={isChargeSummary} {...afterpayParams} />;
    }

    if (paymentMethodType === AMAZON_PAY && !hasNoBalanceFromSavedDiscounts) {
      return <AmazonPayAsPlaceOrder amazonPay={amazonPay} isChargeSummary={isChargeSummary} onInitAmazonPay={onInitAmazonPay} />;
    }

    if (paymentMethodType === CREDIT_CARD) {
      return (
        <div className={css.wrapper} data-test-id={testId('placeOrderSection')}>
          <button
            type="button"
            className={cn(css.button, { [css.alternate]: isAlternate })}
            data-test-id={testId('placeOrderButton')}
            onClick={() => {
              if (isPaymentPlanMissing) {
                onScrollToPayment(true);
              } else {
                onPlaceOrderClick();
              }
            }}
          >
            Place Your Order
          </button>
          {showPaymentError && (
            <div className={css.paymentWarning}>
              <UtilityStrokeInfoOutlineSmallIcon size={16} />
              Please complete the Payment Method
            </div>
          )}
        </div>
      );
    }
  }

  if ((paymentMethodType === CREDIT_CARD && isPaymentPlanMissing) || (isInsufficientCoverage && !isDiscountFullBalance && usePromoBalance)) {
    return (
      <div className={css.wrapper} data-test-id={testId('placeOrderSection')}>
        <button
          type="button"
          className={cn(css.button, { [css.alternate]: isAlternate })}
          data-test-id={testId('selectPaymentButton')}
          onClick={() => {
            onScrollToPayment(true);
          }}
        >
          Place Your Order
        </button>
        {showPaymentError && (
          <div className={css.paymentWarning}>
            <UtilityStrokeInfoOutlineSmallIcon size={16} />
            Please complete the Payment Method
          </div>
        )}
      </div>
    );
  }

  if (isShippingAddressMissing) {
    return (
      <div className={css.wrapper} data-test-id={testId('placeOrderSection')}>
        <button
          type="button"
          className={cn(css.button, { [css.alternate]: isAlternate })}
          data-test-id={testId('shipToThisAddress')}
          onClick={() => {
            onScrollToShipment(true);
          }}
        >
          Place Your Order
        </button>
        {showShippingError && (
          <div className={css.paymentWarning}>
            <UtilityStrokeInfoOutlineSmallIcon size={16} />
            Please complete the Shipping Address
          </div>
        )}
      </div>
    );
  }

  return (
    <form
      method="post"
      onSubmit={onPlaceOrderClick}
      action="/checkout/place-order"
      className={css.wrapper}
      data-test-id={testId('placeOrderSection')}
    >
      <button
        type="submit"
        disabled={!isOrderReadyToSubmit || isPlacingOrder || disableDueToSavedBalanceCoversTotal}
        className={css.button}
        data-test-id={testId('placeOrderButton')}
      >
        {isPlacingOrder ? 'Submitting...' : 'Place Your Order'}
      </button>
    </form>
  );
};

export default PlaceOrder;
