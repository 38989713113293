import creditCardType, { types as CardType } from 'credit-card-type';
import { PaymentIcon } from '@mweb/zappos-ui/PaymentIcon';

import { cn } from 'helpers/classnames';
import { CVVBack, CVVFront } from 'components/checkout/CreditCards';

import css from 'styles/components/checkout/checkoutUtils.scss';

export const getMaskedCreditCardNumber = cc => {
  const ccPlain = cc.replace(/\D/g, '');
  const ccMaskArray = ccPlain.split('');
  const ccType = creditCardType(ccPlain);

  if (ccType.length !== 1) {
    return ccPlain;
  }

  const ccInfo = ccType[0];
  // apply mask
  ccInfo.gaps.forEach((el, idx) => {
    const pos = el + idx;
    if (pos < ccMaskArray.length) {
      ccMaskArray.splice(pos, 0, ' ');
    }
  });

  // limit length
  if (ccInfo.lengths[0]) {
    ccMaskArray.splice(ccInfo.lengths[0] + ccInfo.gaps.length);
  }

  return ccMaskArray.join('');
};

export const CVVToolTip = params => {
  const { paymentType } = params;
  const displayBackIcon = paymentType !== 'AmericanExpress' && paymentType !== '' ? true : false;
  const displayFrontIcon = paymentType === 'AmericanExpress' ? true : false;
  const message = () => {
    if (displayBackIcon) {
      return '3 digits on the back of your card';
    } else if (displayFrontIcon) {
      return '4 digits on the front of your card';
    } else {
      return 'For Visa, Mastercard and Discover use the 3 digits on the back of your card. For American express, use the 4 digits on the front of your card.';
    }
  };

  return (
    <div>
      <p>{message()}</p>
      {displayBackIcon && <CVVBack />}
      {displayFrontIcon && <CVVFront />}
    </div>
  );
};

export const getMelodyPaymentTypeIcon = (paymentType, displayGenericIcon = false, isMyAccRedesign = false) => {
  switch (paymentType) {
    case 'AmericanExpress':
      return <span className={css.amex} />;
    case 'Discover':
      return <span className={css.discover} />;
    case 'MasterCard':
      return <span className={css.mastercard} />;
    case 'Visa':
      return isMyAccRedesign ? <PaymentIcon method="visa" /> : <span className={css.visa} />;
    default:
      return displayGenericIcon ? <span className={css.genericCard} /> : null;
  }
};

export const getMelodyPaymentTypeIconForInput = ({ cc }) => {
  const ccPlain = cc.replace(/\D/g, '');
  const ccType = creditCardType(ccPlain);
  const isVisa = ccType.length === 1 && ccType.find(card => card.type === CardType.VISA);
  const isMasterCard = ccType.length === 1 && ccType.find(card => card.type === CardType.MASTERCARD);
  const isDiscover = ccType.length === 1 && ccType.find(card => card.type === CardType.DISCOVER);
  const isAmex = ccType.length === 1 && ccType.find(card => card.type === CardType.AMERICAN_EXPRESS);

  return [
    <span key="amex" className={cn(css.inline, css.amex, { [css.active]: isAmex })} />,
    <span key="discover" className={cn(css.inline, css.discover, { [css.active]: isDiscover })} />,
    <span key="mastercard" className={cn(css.inline, css.mastercard, { [css.active]: isMasterCard })} />,
    <span key="visa" className={cn(css.inline, css.visa, { [css.active]: isVisa })} />
  ];
};
