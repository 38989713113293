import React from 'react';

import { cn } from 'helpers/classnames';
import { IS_GIFT_CARD } from 'common/regex';
import { toUSD } from 'helpers/NumberFormats';
import useMartyContext from 'hooks/useMartyContext';
import FinalSale from 'components/common/FinalSale';

import css from 'styles/components/checkout/splitReview/retailItemDetails.scss';

export const RetailItemDetails = props => {
  const {
    lineItem: { brandName, color, displaySize, finalSale, itemLengthDescription, price, originalPrice, quantity, shoeWidth, style }
  } = props;
  const {
    testId,
    marketplace: { dataMaskPiiElements }
  } = useMartyContext();

  return (
    <div className={css.container} data-test-id={testId('retailShippableItemContainer')}>
      <span className={css.brandName} data-test-id={testId('productBrand')}>
        {brandName}
      </span>
      <span className={css.productName} data-test-id={testId('productName')}>
        {style}
      </span>
      <dl className={css.dimensions} data-cs-mask={dataMaskPiiElements}>
        {!IS_GIFT_CARD.test(brandName) && (
          <>
            <dt className="font-semibold">Color</dt>
            <dd data-test-id={testId('itemColor')}>{color}</dd>
          </>
        )}
        {!!displaySize && (
          <>
            <dt className="font-semibold">Size</dt>
            <dd data-test-id={testId('itemSize')}>{displaySize}</dd>
          </>
        )}
        {!!shoeWidth && (
          <>
            <dt className="font-semibold">Width</dt>
            <dd data-test-id={testId('itemWidth')}>{shoeWidth}</dd>
          </>
        )}
        {!!itemLengthDescription && (
          <>
            <dt className="font-semibold">Inseam</dt>
            <dd data-test-id={testId('itemLengthDescription')}>{itemLengthDescription}</dd>
          </>
        )}
      </dl>
      <div className={css.hideOnDesktop} data-cs-mask={dataMaskPiiElements}>
        <RetailItemPrice price={price} originalPrice={originalPrice} quantity={quantity} />
      </div>
      {finalSale && <FinalSale />}
    </div>
  );
};

export const RetailItemPrice = ({ price, originalPrice, quantity }) => {
  const isDiscounted = price < originalPrice;
  const totalPrice = price * quantity;
  const totalOriginalPrice = originalPrice * quantity;

  const { testId } = useMartyContext();

  return (
    <>
      <p>
        <span className={cn(css.price, { [css.sale]: isDiscounted })} data-test-id={testId('itemPrice')}>
          {toUSD(totalPrice)}
        </span>
      </p>
      {quantity > 1 && <span className={css.unitPrice}>({toUSD(price)} ea.)</span>}
      {isDiscounted && (
        <>
          <p
            className={cn(css.originalPrice, {
              [css.multiQuantity]: quantity > 1
            })}
          >
            {<span className="line-through" data-test-id={testId('msrpPrice')}>{toUSD(totalOriginalPrice)}</span>}
          </p>
          {quantity > 1 && <span className={cn('line-through', css.unitPrice)}>({toUSD(originalPrice)} ea.)</span>}
        </>
      )}
    </>
  );
};

export default RetailItemDetails;
